define('hoolio-www/components/chat-box/component', ['exports', 'ember'], function (exports, _ember) {
  /*global $ */

  exports['default'] = _ember['default'].Component.extend({
    pubnubService: _ember['default'].inject.service('pubnub'),
    session: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),

    classNames: ['chat-box', 'animated'],
    classNameBindings: ['isOpen:opened:closed'],
    new_message: '',
    chatPbId: 'chatPb',

    isOpen: false,
    unreadedMessages: 0,

    channel: '',
    chatChannel: _ember['default'].computed('channel', function () {
      return 'chat-' + this.get('channel') + '-' + this.get('config.environment');
    }),

    onInitialize: _ember['default'].on('init', function () {
      var _pubnubService = this.get('pubnubService'),
          channel = [this.get('chatChannel')];

      this.set('users', _ember['default'].A([]));
      this.set('messages', _ember['default'].A([]));

      var chatPb = _pubnubService.getInstance('chatPb');
      if (!chatPb.get('initialized')) {
        throw "[Pubnub] No chatPb instance found. Initialize first one.";
      }

      if (!chatPb.isConnectedToChannel(channel[0])) {
        this.registerGlobalPubnubEvents();

        // Subscribe to the chat channel
        chatPb.subscribe(channel, true);
      }
    }),

    onChatOpen: _ember['default'].on('init', _ember['default'].observer('isOpen', function () {
      if (this.get('isOpen')) {
        this.set('unreadedMessages', 0);
        if (this.attrs.onUnreadedMessage) {
          this.attrs.onUnreadedMessage(this.get('unreadedMessages'));
        }
      }

      if (this.$()) {
        this.set('new_message', '');
        //this.$('.fa.fa-comment').css('color', '#fff');
        //this.$().removeClass('bounce');
        this.$('.btn-input')[0].focus();
        _ember['default'].run.next(this, function () {
          //this.$('#chat-list').animate({ scrollTop: this.$('#chat-list')[0].scrollHeight}, 500);
          this.$('#chat-list').scrollTop(this.$('#chat-list')[0].scrollHeight);
        });

        this.get('isOpen') ? _ember['default'].$('body').addClass('chat-open') : _ember['default'].$('body').removeClass('chat-open');
      }
    })),

    timeouts: {},
    time: 2000,
    keyPress: function keyPress(e) {
      var _this = this;

      var id = this.elementId /*,
                              isEmpty = /^\s*$/.test(this.value) */;
      if (id in this.timeouts) {
        // if something is scheduled
        clearTimeout(this.timeouts[id]); // remove it
      } else {
          /* if (!isEmpty) */ // else this is the first stroke
          this.typingOn();
        }
      // schedule sending the off-message, which will be canceled when another
      // keystroke happens
      this.timeouts[id] = setTimeout(function () {
        _this.typingOff();
        delete _this.timeouts[id];
      }, /* isEmpty ? 0 : */this.time);
    },

    typingOn: function typingOn() {
      var chatPb = this.get('pubnubService').getInstance('chatPb');
      chatPb.set('userState.isTyping', true);
      chatPb.setState([this.get('chatChannel')]);
    },

    typingOff: function typingOff() {
      var chatPb = this.get('pubnubService').getInstance('chatPb');
      chatPb.set('userState.isTyping', false);
      chatPb.setState([this.get('chatChannel')]);
    },

    registerGlobalPubnubEvents: function registerGlobalPubnubEvents() {
      var chatPb = this.get('pubnubService').getInstance('chatPb');
      var channel = this.get('chatChannel');
      chatPb.on('status:' + channel, this.onStatusEvent.bind(this));
      chatPb.on('message:' + channel, this.onMessageEvent.bind(this));
      chatPb.on('presence:' + channel, this.onPresenceEvent.bind(this));
    },

    onStatusEvent: function onStatusEvent(e) {
      var _this2 = this;

      //console.log(e);
      if (e.operation === "PNSubscribeOperation" && e.category === "PNConnectedCategory") {
        var chatPb = this.get('pubnubService').getInstance('chatPb');
        chatPb.set('userState', {
          name: this.get('session.currentUser.firstName'),
          photo: this.get('session.currentUser.photoUrl'),
          isTyping: false
        });
        chatPb.setState([this.get('chatChannel')]);

        chatPb.history([this.get('chatChannel')][0]).then(function (data) {
          data.messages.forEach(function (message) {
            message.entry.time = message.timetoken;
            _this2.get('messages').pushObject(message.entry);
          });
        });
        this.getUsers();
      } else if (e.operation === "PNUnsubscribeOperation") {}
    },

    onMessageEvent: function onMessageEvent(e) {
      //console.log(e);
      this.get('messages').pushObject(e.message);
      _ember['default'].run.next(this, function () {
        if (this.get('isOpen')) {
          //this.$('.fa.fa-comment').css('color', '#fff');
          //this.$().removeClass('bounce');
        } else if (this.get('messages.length') > 0) {
            this.incrementProperty('unreadedMessages');
            //this.$('.fa.fa-comment').css('color', '#f1981f');
            //this.$().addClass('bounce');

            if (this.attrs.onUnreadedMessage) {
              this.attrs.onUnreadedMessage(this.get('unreadedMessages'));
            }
          }
        _ember['default'].run.next(this, function () {
          //this.$('#chat-list').animate({ scrollTop: this.$('#chat-list')[0].scrollHeight}, 500);
          this.$('#chat-list').scrollTop(this.$('#chat-list')[0].scrollHeight);
        });
      });
    },

    onPresenceEvent: function onPresenceEvent(e) {
      var userId = this.get('session.currentUser.id'),
          chatPb = this.get('pubnubService').getInstance('chatPb'),
          channel = [this.get('chatChannel')],
          users = this.get('users');

      if (e.uuid != userId) {
        if (e.action === 'join') {
          //console.log(e);
        } else if (e.action === 'leave') {
            //console.log(e);
            var user = users.findBy('uuid', e.uuid);
            if (user) {
              users.removeObject(user);
            }
          } else if (e.action === 'state-change') {
            var user = users.findBy('uuid', e.uuid);
            if (!user) {
              var newUser = _ember['default'].Object.create({
                uuid: e.uuid,
                state: e.state
              });
              users.pushObject(newUser);
            } else if (user) {
              user.set("state.isTyping", e.state.isTyping);
              _ember['default'].run.next(this, function () {
                this.$('#chat-list').scrollTop(this.$('#chat-list')[0].scrollHeight);
              });
            }
          } else if (e.action === 'timeout') {
            //console.log(e);
          }
      } else if (e.uuid === userId) {
          if (e.action === 'join') {
            //console.log(e);
          } else if (e.action === 'leave') {
              //console.log("akis");
            } else if (e.action === 'state-change') {
                //console.log(e);
              } else if (e.action === 'timeout') {
                  //console.log(e);
                }
        }
    },

    getUsers: function getUsers() {
      _ember['default'].run.later(this, function () {
        var _this3 = this;

        var chatPb = this.get('pubnubService').getInstance('chatPb');
        var channel = [this.get('chatChannel')];
        chatPb.hereNow(channel).then(function (data) {
          var users = data.channels[channel[0]].occupants.map(function (user) {
            return _ember['default'].Object.create(user);
          });
          _this3.get('users').pushObjects(users);
        });
      }, 2000);
    },

    _onDestroyElement: _ember['default'].on('willDestroyElement', function () {
      _ember['default'].$('body').removeClass('chat-open');
    }),

    actions: {
      // set up an Ember Action to publish a message
      publish: function publish() {
        var _pubnub = this.get('pubnubService').getInstance('chatPb');
        var publishConfig = {
          channel: this.get('chatChannel'),
          message: {
            "uuid": this.get('session.currentUser.id'),
            "name": this.get('session.currentUser.firstName'),
            "photo": this.get('session.currentUser.photoUrl'),
            "data": this.get('new_message')
          }
        };
        _pubnub.publish(publishConfig);

        this.set('new_message', ''); // resets the view to empty string (optional)
      },

      toggleView: function toggleView() {
        this.toggleProperty('isOpen');
      },

      closeChat: function closeChat() {
        var chatPb = this.get('pubnubService').getInstance('chatPb');
        chatPb.unsubscribe([this.get("chatChannel")]);

        if (this.attrs.onCloseChat) {
          this.attrs.onCloseChat(this.get("chatChannel"));
        }
      }
    }
  });
});

/*
 Example usage: {{chat-box channel='public-channel' currentUserState=currentUserState}}

 where 

  currentUserState: Ember.computed(function() {
    return {name:this.get("session.currentUser.firstName"), photo:this.get("session.currentUser.photoUrl")};
  }),
*/