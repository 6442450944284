define('hoolio-www/components/fitnessplan-main-primary/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    classNames: ['fitnessplan-main-primary'],
    attributeBindings: ['style'],

    style: _ember['default'].computed('session.currentUser.activeFitnessPlan.fitnessplan.images.firstObject', function () {
      var _style = '';
      if (this.get('session.currentUser.activeFitnessPlan.fitnessplan.images.firstObject')) {
        _style += 'background-image: url("' + this.get('session.currentUser.activeFitnessPlan.fitnessplan.images.firstObject') + '")';
      } else {
        _style += 'background-image: url("assets/img/fitnessplans/fitnessTopSmall.jpg")';
      }
      return new _ember['default'].String.htmlSafe(_style);
    })
  });
});