define('hoolio-www/services/emag', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    emagNet: _ember['default'].inject.service(),

    log: function log(str, data) {
      if (_hoolioWwwConfigEnvironment['default'].emaginal.debug) {
        data ? console.log(str, data) : console.log(str);
      }
    },

    error: function error(data) {
      if (_hoolioWwwConfigEnvironment['default'].emaginal.debug) {
        console.error(data);
      }
    },

    /*
      Get user metrics based on date range
    */
    getUserMetrics: function getUserMetrics(from, to, cb_success, cb_error) {
      if (!from || !to) {
        cb_error('[Emag-social] Invalid date.');
      }
      var url = '/dashboard/' + from + '/' + to;
      this.get('emagNet').getUrl(url, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error(response.responseJSON.message);
      });
    }
  });
});