define('hoolio-www/components/fit-slider-item-secondary/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showCloseBtn: true,
    fitSliderExpandedId: _ember['default'].computed('attrs.fitSliderId', function () {
      return "fit-slider-" + this.attrs.fitSliderId + "-expanded-data-container";
    }),

    actions: {
      setActiveItem: function setActiveItem() {
        this.attrs.setActiveItem('');
      }
    }
  });
});