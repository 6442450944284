define('hoolio-www/image/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    thumbnailUrl: _emberData['default'].attr('string'),

    /*
    *  Computed attribures
    * 
    */
    thumbnailUrlWide: _ember['default'].computed('thumbnailUrl', function () {
      var baseUrl = this.get('thumbnailUrl');
      if (baseUrl) {
        return this.get('thumbnailUrl') + '/width/720/height/540/';
      } else {
        return 'assets/img/general/default-image-2.png';
      }
    }),

    thumbnailUrlBig: _ember['default'].computed('thumbnailUrl', function () {
      var baseUrl = this.get('thumbnailUrl');
      if (baseUrl) {
        return this.get('thumbnailUrl') + '/width/1920';
      } else {
        return 'assets/img/general/default-image-2.png';
      }
    })
  });
});