define('hoolio-www/components/rotating-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    browser: _ember['default'].inject.service(),

    classNames: ['rotating-card-component'],
    front: { isFront: true },
    back: { isBack: true },
    flipped: false,

    focusOut: function focusOut() {
      this.set('flipped', false);
    },

    click: function click() {
      this.toggleProperty('flipped');
    }
  });
});