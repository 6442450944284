define('hoolio-www/services/video', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    emagSocial: _ember['default'].inject.service(),
    headTagsService: _ember['default'].inject.service('head-tags'),
    analytics: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),

    selectedRowVideo: null,
    onSelectedRowVideo: _ember['default'].observer('selectedRowVideo', function () {
      // Reset new metadat
      this.get('headTagsService').collectHeadTags();
    }),

    isFavoredFromUser: function isFavoredFromUser(videoId) {
      return this.get('session.currentUser.favoriteVideos').then(function (favoriteVideos) {
        //console.log(favoriteVideos.filterBy( 'id', videoId).length > 0);
        return favoriteVideos.filterBy('id', videoId).length > 0;
      });
    },

    addToUserFavorites: function addToUserFavorites(video, success_cb, error_cb) {
      var _this = this;

      if (this.get('session.currentUser')) {
        //const user = this.get('store').peekRecord('user', this.get('session.currentUser.id'));
        this.get('session.currentUser.favoriteVideos').then(function (favoriteVideos) {
          if (favoriteVideos.filterBy('id', video.get('id')).length > 0) {
            favoriteVideos.removeObject(video);
            success_cb('Workout removed from favorites.');
          } else {
            favoriteVideos.insertAt(0, video);
            success_cb('Workout added to favorites.');

            _this.get('analytics.intercom').trackEvent('add-favorite', { video_name: video.get('name') });
            _this.get('analytics.google').trackEvent('workout', 'add_favorite', 'personalize');
          }
          _this.get('session.currentUser').save();
        });
      } else {
        error_cb('Please register in order to perform this action.');
      }
    },

    getOrCreateWorkout: function getOrCreateWorkout(video, success_cb, error_cb) {
      var _this2 = this;

      if (this.get('session.currentUser.id')) {
        (function () {
          var user = _this2.get('session.currentUser'); //this.get('store').peekRecord('user', this.get('session.currentUser.id'));
          var workouts = _this2.get('store').peekAll('workout');
          //const existingWorkout = workouts.findBy('video.id', video.get('id'));
          var existingWorkout = workouts.filter(function (workout) {
            return workout.get('video.id') === video.get('id') && workout.get('status') === 'incomplete';
          })[0];
          if (existingWorkout) {
            _this2.get('emag').log('[Video Service] Loaded cached workout.');
            success_cb(existingWorkout);
          } else {
            _this2.get('emagSocial').getObjectId(function (id) {
              var workout = _this2.get('store').createRecord('workout', {
                id: id,
                user: user,
                video: video,
                premium: video.get('isFromKaltura')
              });

              workout.save().then(function (savedWorkout) {
                _this2.get('emag').log('[Video Service] New workout created.');
                success_cb(savedWorkout);
              });
            }, function () {
              error_cb('Please register in order to perform this action.');
            });
          }
        })();
      } else {
        error_cb('Please register in order to perform this action.');
      }
    }
  });
});