define('hoolio-www/tv/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    tvService: _ember['default'].inject.service('tv'),

    activate: function activate() {
      var controller = this.controllerFor('application');
      controller.set('hideTopNavbar', true);
      controller.set('hideFooter', true);
    },

    deactivate: function deactivate() {
      var appController = this.controllerFor('application');
      appController.set('hideTopNavbar', false);
      appController.set('hideFooter', false);
    },

    afterModel: function afterModel(model, transition) {
      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    model: function model() {
      // Channel
      return this.get('tvService').fetchChannel();
    },

    resetController: function resetController(controller, isExiting, transition) {
      /*if (isExiting) {
        const _changeVideoTimer = this.get('tvService.changeVideoTimer');
        if(_changeVideoTimer) {
          Ember.run.cancel(_changeVideoTimer);
          this.set('tvService.changeVideoTimer', null);
        }
         this.set('tvService.activeVideoIndex', -1);
        this.set('tvService.lastActiveVideoIndex', -2);
        this.set('tvService.activeVideo', null);
        this.set('tvService.activeAd', null);
      }*/
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});