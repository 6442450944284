define('hoolio-www/components/stack-blur/component', ['exports', 'ember'], function (exports, _ember) {
  /* globals Blur*/

  exports['default'] = _ember['default'].Component.extend({
    resizeService: _ember['default'].inject.service('resize'),
    classNames: ['stack-blur-component'],

    init: function init() {
      this._super.apply(this, arguments);
      this.get('resizeService').on('debouncedDidResize', this.onResize.bind(this));
    },

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      /*console.log(this.attrs.photoUrl);
      if(this.attrs.photoUrl){
        stackBlurImage( this.elementId+'-img', this.elementId+'-canvas', 30, false );
        this.onResize();
      }*/
      _ember['default'].$('body').addClass('blurred-bg');
    }),

    onInitialize: _ember['default'].on('didReceiveAttrs', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.set('blurAmount', this.attrs.blurAmount || 16);
        this.initBlur(this.attrs.photoUrl);
      });
    }),

    initBlur: function initBlur(photoUrl) {
      var _this = this;

      if (photoUrl) {
        (function () {
          var crossOriginImg = new Image();
          crossOriginImg.setAttribute('crossorigin', 'anonymous');
          crossOriginImg.onload = function () {
            var img = Blur.stackBlur(crossOriginImg, _this.get('blurAmount'));
            _this.$().html(img);
            _this.onResize();
          };

          crossOriginImg.src = 'https://cors-anywhere.herokuapp.com/' + photoUrl;
        })();
      } else {
        return false;
      }
    },

    onResize: function onResize(event) {
      //console.log(`width: ${window.innerWidth}, height: ${window.innerHeight}`);
      var canvas = this.$('canvas');
      var ratio = Math.abs(canvas.width() / canvas.height());
      if (window.innerWidth / ratio < window.innerHeight) {
        canvas.width(window.innerHeight * ratio);
        canvas.height(window.innerHeight);
      } else {
        canvas.width(window.innerWidth);
        canvas.height(window.innerWidth / ratio);
      }
    },

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      _ember['default'].$('body').removeClass('blurred-bg');
    })
  });
});