define('hoolio-www/components/studio-syncing/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    pubnubService: _ember['default'].inject.service('pubnub'),
    flashMessages: _ember['default'].inject.service(),
    bot: _ember['default'].inject.service(),
    tvService: _ember['default'].inject.service('tv'),
    config: _ember['default'].inject.service(),

    classNames: ['studio-syncing-component'],
    channel: '',
    studioChannel: _ember['default'].computed('channel', function () {
      return 'studio-' + this.get('channel') + '-' + this.get('config.environment');
    }),

    topUserMovesboard: _ember['default'].computed('session.currentUser.userPreference.topMovesboard', function () {
      return this.get('session.currentUser.userPreference').then(function (prefs) {
        var topMovesboard = prefs.get('topMovesboard');
        if (topMovesboard) {
          return topMovesboard;
        } else {
          return null;
        }
      });
    }),

    onInit: _ember['default'].on('init', function () {
      var _pubnubService = this.get('pubnubService');
      var channel = [this.get('studioChannel')];

      this.set('users', _ember['default'].A([]));

      var studioPb = _pubnubService.getInstance('studioSyncPb');
      if (!studioPb.get('initialized')) {
        throw "[Pubnub] No studioSyncPb instance found. Initialize first one.";
      }

      if (!studioPb.isConnectedToChannel(channel[0])) {
        this.registerGlobalPubnubEvents();

        // Subscribe to the global studio channel
        studioPb.subscribe(channel, true);
      }
    }),

    registerGlobalPubnubEvents: function registerGlobalPubnubEvents() {
      var studioPb = this.get('pubnubService').getInstance('studioSyncPb');
      studioPb.on('status:' + this.get('studioChannel'), this.onStatusEvent.bind(this));
      studioPb.on('message:' + this.get('studioChannel'), this.onMessageEvent.bind(this));
      studioPb.on('presence:' + this.get('studioChannel'), this.onPresenceEvent.bind(this));
    },

    onStatusEvent: function onStatusEvent(e) {
      var _this = this;

      //console.log(e);
      if (e.operation === "PNSubscribeOperation" && e.category === "PNConnectedCategory") {
        (function () {
          var studioPb = _this.get('pubnubService').getInstance('studioSyncPb');
          var userFirstName = _this.get('session.currentUser.firstName');
          _this.get('topUserMovesboard').then(function (data) {
            var userInfo = "Hoolio is getting to know " + userFirstName + ". Not enough exercise data yet!";
            if (Object.keys(data).length > 0 && data.constructor === Object) {
              userInfo = userFirstName + " works out for " + _this.parseCamelcase(data["aims"]) + ", likes more " + _this.parseCamelcase(data["types"]) + " workouts and moodwise likes " + _this.parseCamelcase(data["mood"] + ".");
            }

            studioPb.set('userState', {
              name: userFirstName,
              photo: _this.get('session.currentUser.photoUrl'),
              cameraOn: false,
              userInfo: userInfo
            });
            studioPb.setState([_this.get('studioChannel')]);

            _this.getUsers();
          });
        })();
      } else if (e.operation === "PNUnsubscribeOperation") {}
    },

    onMessageEvent: function onMessageEvent(e) {
      //console.log(e);
    },

    onPresenceEvent: function onPresenceEvent(e) {
      var flashMessages = _ember['default'].get(this, 'flashMessages'),
          userId = this.get('session.currentUser.id'),
          studioPb = this.get('pubnubService').getInstance('studioSyncPb'),
          chatPb = this.get('pubnubService').getInstance('chatPb'),
          channel = [this.get('studioChannel')],
          users = this.get('users');

      if (e.uuid != userId) {
        if (e.action === 'join') {
          //console.log(e);
          /*Ember.run.later(this, function() {
            studioPb.getState(e.uuid, channel).then((data) => {
              console.log(data);
              const newUser = {
                uuid: e.uuid,
                state: data.channels[channel[0]]
              };
              users.pushObject(newUser);
              flashMessages.info(newUser.state.name + " just joined!");
            });
          }, 1000);*/
          //this.getUsers();

        } else if (e.action === 'leave') {
            //console.log(e);
            var user = users.findBy('uuid', e.uuid);
            if (user) {
              var msg = user.state.name + " left the studio";
              flashMessages.info(msg);

              users.removeObject(user);
            }
          } else if (e.action === 'state-change') {
            //console.log(e);
            var user = users.findBy('uuid', e.uuid);
            if (!user) {
              var newUser = _ember['default'].Object.create({
                uuid: e.uuid,
                state: e.state
              });
              users.pushObject(newUser);

              var msg = newUser.state.name + " just joined!";
              flashMessages.info(msg);
            } else if (user) {
              //console.log(e);
              user.set("state.cameraOn", e.state.cameraOn);
            }
          } else if (e.action === 'timeout') {
            //console.log(e);
          }
      } else if (e.uuid === userId) {
          if (e.action === 'join') {
            //console.log(e);
            flashMessages.info("Welcome to Studio");

            var msg = this.get('session.currentUser.firstName') + " joined the studio";
            if (chatPb.isConnectedToChannel("chat-" + this.get('tvService.coreInfo.id'))) {
              this.set('bot.botUserData.photo', this.get('session.currentUser.photoUrl'));
              this.get('bot').publish(chatPb, "chat-" + this.get('tvService.coreInfo.id'), msg);
            }
          } else if (e.action === 'leave') {} else if (e.action === 'state-change') {
            //console.log(e);
            var user = users.findBy('uuid', e.uuid);
            if (user) {
              //console.log(user);
              user.set("state.cameraOn", e.state.cameraOn);
            }
          } else if (e.action === 'timeout') {
            //console.log(e);
          }
        }
    },

    parseCamelcase: function parseCamelcase(str) {
      return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },

    getUsers: function getUsers() {
      _ember['default'].run.later(this, function () {
        var _this2 = this;

        var studioPb = this.get('pubnubService').getInstance('studioSyncPb'),
            channel = [this.get('studioChannel')];

        studioPb.hereNow(channel).then(function (data) {
          var users = data.channels[_this2.get('studioChannel')].occupants.map(function (user) {
            return _ember['default'].Object.create(user);
          });
          _this2.get('users').pushObjects(users);
        });
      }, 2000);
    },

    _onDestroyElement: _ember['default'].on('willDestroyElement', function () {})
  });
});