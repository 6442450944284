define('hoolio-www/components/kaltura-player/component', ['exports', 'ember'], function (exports, _ember) {
  /* globals kWidget, easyrtc */

  exports['default'] = _ember['default'].Component.extend({
    videoPlayerService: _ember['default'].inject.service('video-player'),
    emag: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    browser: _ember['default'].inject.service(),

    classNames: ['kaltura-player'],
    player: null,
    partnerId: _ember['default'].computed.alias('config.kaltura_partner_id'),
    uiConfId: _ember['default'].computed.alias('config.kaltura_player_id'),
    isPlaying: false,
    isMuted: false,
    responsive: true,
    autoplay: true,

    _onReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      var _this = this;

      if (this.attrs.video) {
        // Compare new video id with the cached one
        if (this.get('videoId') != this.attrs.video) {
          this.set('videoId', this.attrs.video);
          if (this.attrs.preview) {
            this.initVideoPlayer();
          } else {
            this.get('kaltura').getVideoKS(this.attrs.video, function () {
              _this.initVideoPlayer();
            }, function (error) {
              _this.get('emag').log(error);
            });
          }
        }
      }

      if (this.attrs.autoplay === false) {
        this.set('autoplay', false);
      }

      if (this.attrs.responsive === false) {
        this.set('responsive', false);
      }
    }),

    initVideoPlayer: function initVideoPlayer() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this2 = this;

        var ks = this.get('kaltura.sessions.video');
        ks = ks ? ks : '';
        kWidget.embed({
          'targetId': 'kaltura-video-player-' + this.get('elementId'),
          'wid': '_' + this.get('partnerId'),
          'uiconf_id': this.get('uiConfId'),
          'entry_id': this.attrs.video,
          'flashvars': { // flashvars allows you to set runtime uiVar configuration overrides.
            'ks': ks,
            'autoPlay': this.get('browser.isMobile') ? false : this.get('autoplay'),
            'externalInterfaceDisabled': false,
            'metadataProfileId': 5570781, // the metadata profile id from your account
            'requiredMetadataFields': false,
            'controlBarContainer.plugin': this.attrs.controls ? true : false,
            'largePlayBtn.plugin': this.get('browser.isMobile') ? true : false,
            'loadingSpinner.plugin': true,
            'mediaProxy.mediaPlayFrom': this.attrs.startSeconds || 0,
            'controlBarContainer': {
              'plugin': this.attrs.controls ? true : false,
              'hover': this.attrs.controls ? true : false
            }
          },
          'params': { // params allows you to set flash embed params such as wmode, allowFullScreen etc
            'wmode': 'transparent'
          },
          readyCallback: function readyCallback(playerId) {
            _this2.get('emag').log('[Kaltura player] ' + playerId + ' is ready.');
            var _player = document.getElementById(playerId);
            _this2.set('player', _player);
            _this2.set('videoPlayerService.player', _player);

            _this2.$('#' + playerId).removeClass('hidden');
            _this2.$('#' + playerId).height(_this2.$().parent().height());

            _player.kBind('mediaReady', function () {
              //this.get('emag').log('[Kaltura player] Entry name: '+ that.player.evaluate('{mediaProxy.entry.name}') );
              //this.get('emag').log('[Kaltura player] Entry metadata: ', that.player.evaluate('{mediaProxy.entryMetadata}'));

              _this2.set('videoPlayerService.mediaIsReady', true);
              _this2.set('videoPlayerService.currentTimeValue', 0);
            });

            _player.kBind("playerPlayed", function (data, id) {
              /*if(!this.get('isPlaying')) {
                //console.log('Play');
                this.startTimer();
                this.set('isPlaying', true);
                this.set('videoPlayerService.isPlaying', true);
              }*/
              //this.sendMediaPlayerAction('play', this.player.evaluate('{video.player.currentTime}'));

              if (_this2.attrs.onPlayerStartEvent) {
                _this2.attrs.onPlayerStartEvent();
              }
            });

            _player.kBind("playerPaused", function (data, id) {
              //console.log('Pause');
              /*this.stopTimer();
              this.set('isPlaying', false);
              this.set('videoPlayerService.isPlaying', false);*/
              //this.sendMediaPlayerAction('pause', this.player.evaluate('{video.player.currentTime}'));
            });

            _player.kBind("playerUpdatePlayhead", function (data, id) {
              //this.sendMediaPlayerAction('playHead', this.player.evaluate('{video.player.currentTime}'));
            });

            _player.kBind('playerSeekStart', function () {
              //console.log('Seek start');
              _this2.set('isPlaying', false);
              _this2.set('videoPlayerService.isPlaying', false);
            });

            _player.kBind('playerSeekEnd', function () {
              //console.log('Seek end');
              _ember['default'].run.next(_this2, function () {
                this.set('isPlaying', true);
                this.set('videoPlayerService.isPlaying', true);
                _player.sendNotification('doPlay');
              });
            });

            _player.kBind("playerPlayEnd", function () {
              console.log('Player end');
              _this2.set('videoPlayerService.currentTimeValue', parseInt(_this2.player.evaluate('{mediaProxy.entry.duration}')));

              _this2.set('isPlaying', false);
              _this2.set('videoPlayerService.isPlaying', false);
              _this2.get('videoPlayerService').trigger('player-ended');
              _this2.set('videoPlayerService.isEnded', true);

              if (_this2.attrs.onPlayerEndEvent) {
                _this2.attrs.onPlayerEndEvent();
              }
            });

            _player.kBind("mute", function () {
              _this2.set('isMuted', true);
              _this2.set('videoPlayerService.isMuted', true);
            });

            _player.kBind("playerStateChange", function (state) {
              if (state === 'playing') {
                if (!_this2.get('isPlaying')) {
                  //console.log(state);
                  _this2.startTimer();
                  _this2.set('isPlaying', true);
                  _this2.set('videoPlayerService.isPlaying', true);
                }
              } else if (state === 'paused') {
                //console.log(state);
                _this2.stopTimer();
                _this2.set('isPlaying', false);
                _this2.set('videoPlayerService.isPlaying', false);
              } else {
                //console.log(state);
              }
            });

            //Set event listeners
            _this2.get('videoPlayerService').on('player-play', _this2, _this2.triggerPlayAction).on('player-pause', _this2, _this2.triggerPauseAction).on('player-seek', _this2, _this2.triggerSeekAction).on('player-forward', _this2, _this2.triggerForwardAction).on('player-backward', _this2, _this2.triggerBackwardAction).on('player-unmute', _this2, _this2.triggerUnmuteAction).on('player-mute', _this2, _this2.triggerMuteAction).on('player-chapter-seek', _this2, _this2.triggerChapterSeekAction);

            if (_this2.attrs.onPlayerReadyEvent) {
              _this2.attrs.onPlayerReadyEvent();
            }
          }
        });
      });
    },

    startTimer: function startTimer() {
      var _this3 = this;

      var _player = this.get('player');

      // stop any previously started timer we forgot to clear
      this.stopTimer();

      if (this.get('videoPlayerService.isEnded')) {
        this.set('videoPlayerService.currentTimeValue', 0);
        this.set('videoPlayerService.isEnded', false);
      }

      // every second update current time
      var timer = window.setInterval(function () {
        if (_this3.get('isPlaying')) {
          var _time = parseInt(_player.evaluate('{video.player.currentTime}'));
          if (_time > 0) {
            _this3.set('videoPlayerService.currentTimeValue', _time);
          }
        }
      }, 1000);

      // save the timer so we can stop it later
      this.set('timer', timer);
    },

    stopTimer: function stopTimer() {
      window.clearInterval(this.get('timer'));
    },

    onVideoIdChange: _ember['default'].observer('attrs.video', function () {
      if (this.get('videoId') != this.attrs.video) {
        this.set('videoId', this.attrs.video);
        var _player = this.get('player');
        if (_player) {
          _player.sendNotification("changeMedia", { 'entryId': this.attrs.video });
          this.set('isPlaying', false);
          this.set('videoPlayerService.isPlaying', false);
        }
      }
    }),

    _onDestroy: _ember['default'].on('willDestroyElement', function () {
      var _player = this.get('player');
      if (_player) {
        this.get('emag').log('[Kaltura player] Destroying Kaltura player...');
        _player.kUnbind("mediaReady");
        _player.kUnbind("playerPlayed");
        _player.kUnbind("playerPaused");
        _player.kUnbind("playerUpdatePlayhead");
        kWidget.destroy('kaltura-video-player-' + this.get('elementId'));
        this.set('isPlaying', false);
        this.set('clickedToPlay', false);
        this.set('videoPlayerService.currentTimeValue', 0);
        //this.set('videoPlayerService.isPlaying', false);
        this.set('videoPlayerService.player', null);
        this.set('videoPlayerService.mediaIsReady', false);
      }

      this.set('videoId', null);

      // clear the timer
      this.stopTimer();

      this.get('videoPlayerService').off('player-play', this, this.triggerPlayAction).off('player-pause', this, this.triggerPauseAction).off('player-seek', this, this.triggerSeekAction).off('player-forward', this, this.triggerForwardAction).off('player-backward', this, this.triggerBackwardAction).off('player-unmute', this, this.triggerUnmuteAction).off('player-mute', this, this.triggerMuteAction).off('player-chapter-seek', this, this.triggerChapterSeekAction);
    }),

    triggerPlayAction: function triggerPlayAction(data) {
      if (data && data.time) {
        this.set('videoPlayerService.currentTimeValue', parseInt(data.time));
        this.triggerSeekAction(parseInt(data.time));
      } else {
        this.send('play');
      }
    },

    triggerPauseAction: function triggerPauseAction(data) {
      /*if(data && data.time) {
        this.triggerSeekAction(data);
      }*/
      this.send('pause');
    },

    triggerChapterSeekAction: function triggerChapterSeekAction(data) {
      if (this.attrs.showChapters) {
        this.set('videoPlayerService.currentTimeValue', parseInt(data.time));
        this.get('player').sendNotification("doSeek", parseInt(data.time));
      }
    },

    triggerSeekAction: function triggerSeekAction(data) {
      if (!this.attrs.showChapters) {
        this.set('videoPlayerService.currentTimeValue', parseInt(data.time));
        this.get('player').sendNotification("doSeek", parseInt(data.time));
      }
    },

    triggerForwardAction: function triggerForwardAction() {
      if (!this.attrs.showChapters) {
        var time = this.get('videoPlayerService.currentTimeValue') + 20;
        this.set('videoPlayerService.currentTimeValue', time);
        this.get('player').sendNotification("doSeek", time);
      }
    },

    triggerBackwardAction: function triggerBackwardAction() {
      if (!this.attrs.showChapters) {
        var time = this.get('videoPlayerService.currentTimeValue') - 20;
        if (time < 0) {
          time = 0;
        }
        this.set('videoPlayerService.currentTimeValue', time);
        this.get('player').sendNotification("doSeek", time);
      }
    },

    triggerUnmuteAction: function triggerUnmuteAction() {
      this.send('unMute');
    },

    triggerMuteAction: function triggerMuteAction() {
      this.send('mute');
    },

    actions: {
      play: function play() {
        var _player = this.get('player');
        if (_player) {
          _player.sendNotification("doPlay");
        }
      },

      pause: function pause() {
        var _player = this.get('player');
        if (_player) {
          _player.sendNotification("doPause");
        }
      },

      mute: function mute() {
        var _player = this.get('player');
        if (_player) {
          _player.sendNotification("changeVolume", 0);
          this.set('isMuted', true);
          this.set('videoPlayerService.isMuted', true);
        }
      },

      unMute: function unMute() {
        var _player = this.get('player');
        if (_player) {
          _player.sendNotification("changeVolume", 1);
          this.set('isMuted', false);
          this.set('videoPlayerService.isMuted', false);
        }
      }
    }
  });
});