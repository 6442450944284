define('hoolio-www/week/model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic properties
    * 
    */
    description: _emberData['default'].attr('string'),

    /*
    *  Relationships
    * 
    */
    videos: _emberData['default'].hasMany('video', { inverse: null })
  });
});