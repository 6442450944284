define('hoolio-www/services/emag-net', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    emagSocial: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),

    utmParams: _ember['default'].Object.create({
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: ''
    }),

    withApiUrl: function withApiUrl(url) {
      return _hoolioWwwConfigEnvironment['default'].emaginal.apiServer + url;
    },

    getUrl: function getUrl(url, cb_success, cb_error) {
      this.callUrl(url, {}, 'get', cb_success, cb_error);
    },

    postUrl: function postUrl(url, data, cb_success, cb_error) {
      this.callUrl(url, data, 'post', cb_success, cb_error);
    },

    deleteUrl: function deleteUrl(url, cb_success, cb_error) {
      this.callUrl(url, {}, 'delete', cb_success, cb_error);
    },

    callUrl: function callUrl(url, data, method, cb_success, cb_error) {
      var _this = this;

      var requestUrl = url;
      var user_id = this.get('emagSocial.credentials.user_id'),
          session_key = this.get('emagSocial.credentials.session_key'),
          utm_source = this.get('utmParams.utm_source'),
          utm_medium = this.get('utmParams.utm_medium'),
          utm_campaign = this.get('utmParams.utm_campaign'),
          utm_term = this.get('utmParams.utm_term'),
          utm_content = this.get('utmParams.utm_content'),
          current_time_utc = new Date().getTime(),
          apiUrlArray = ['/'];

      apiUrlArray.forEach(function (apiUrl, index, array) {
        if (url.indexOf(apiUrl) >= 0) {
          requestUrl = _this.withApiUrl(url);
        }
      });

      _ember['default'].$.support.cors = true;
      _ember['default'].$.ajax({
        type: method,
        url: requestUrl,
        data: JSON.stringify(data),
        async: true,
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': session_key,
          'X-User-Id': user_id,
          'X-Current-Time-Utc': current_time_utc,
          'X-Utm-Source': utm_source,
          'X-Utm-Medium': utm_medium,
          'X-Utm-Campaign': utm_campaign,
          'X-Utm-Term': utm_term,
          'X-Utm-Content': utm_content
        },
        success: function success(data) {
          _this.get('emag').log('[Emag-net service] ' + requestUrl + ' ... success');
          cb_success(data);
        },
        error: function error(data) {
          _this.get('emag').log('[Emag-net service] ' + requestUrl + ' ... failed');
          cb_error(data);
        }
      });
    }

  });
});