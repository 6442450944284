define('hoolio-www/components/tree-map/component', ['exports', 'ember', 'ember-resize/mixins/resize-aware'], function (exports, _ember, _emberResizeMixinsResizeAware) {
  /* globals CarrotSearchFoamTree, window, $*/

  exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], {
    browser: _ember['default'].inject.service(),

    classNames: ['tree-map-component'],
    scrollToTreemap: false,

    onDidReceiveElement: _ember['default'].on('didReceiveAttrs', function () {

      this.set('scrollToTreemap', this.attrs.scrollToTreemap || false);

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this2 = this;

        //console.log(this.attrs.dataObject);
        if (this.attrs.dataObject && this.get('foamtree')) {
          (function () {
            var foamtree = _this2.get('foamtree');
            var groups = _this2.attrs.dataObject.map(function (obj, index) {
              //console.log(obj);
              var parsedObj = _this2.parseGroup(foamtree, obj);
              if (obj.groups) {
                var nestedGroups = obj.groups.map(function (nObj, index) {
                  var parsedNestedObj = _this2.parseGroup(foamtree, nObj);
                  parsedNestedObj['parentId'] = parsedObj.id;
                  return parsedNestedObj;
                });
                parsedObj.groups = nestedGroups;
              }

              return parsedObj;
            });
            //console.log(groups);

            _this2.get('foamtree').set("dataObject", { groups: groups });
          })();
        }
      });
    }),

    parseGroup: function parseGroup(foamtree, obj) {
      var group = {
        id: obj.id,
        // Make polygon size reflect the number of views
        weight: parseInt(obj.weight),
        label: obj.label,
        // We'll need a per-group random number
        random: 0.8 + 0.2 * Math.random(),

        // A reference to the source photo object, we'll need it to draw labels
        obj: obj,

        groups: obj.groups,

        moves: obj.moves,

        // Low-resolution image
        image: undefined,
        imageLoaded: false, // true when image has just been loaded
        imageLoadedTime: undefined, // time the image completed loading, used for fading-in animation

        // High-resolution image
        hiresImage: undefined,

        // True when some image-specific data is loading. We'll set this flag to true
        // when the list of author's images is loading to show a spinner in that group.
        loading: false

      };

      // Low-resolution image loaded with CORS headers
      //crossOriginImage: undefined
      if (obj.photos) {
        // Initiate loading of the low-resolution image
        var img = new Image();
        img.onload = function () {
          // Once the image has been loaded,
          // put it in the group's data object
          group.image = img;
          group.imageLoaded = true;
          group.imageLoadedTime = Date.now();

          // Schedule FoamTree redraw to show the newly loaded image
          foamtree.redraw(false, group);
        };
        img.src = obj.photos[0];

        //console.log(imageUrl(photo, "b"));
        // Initiate loading of the low-resolution image with CORS headers. We'll need a separate
        // image to be able to read its image data and apply blur.
        // There is a long standing bug in Flickr that sometimes breaks CORS handling in browsers:
        // https://www.flickr.com/groups/51035612836@N01/discuss/72157629144244216/72157643738447673
        // If the bug wasn't there, we wouldn't have to load the same image twice.
        // If the image fails to load, we'll fall back to non-blurred backdrop image.
        /*var crossOriginImg = new Image();
        crossOriginImg.setAttribute('crossorigin', 'anonymous');
        crossOriginImg.onload =  () => {
          group.crossOriginImage = crossOriginImg;
        };
        crossOriginImg.src = 'https://cors-anywhere.herokuapp.com/'+obj.photos[0];*/
      }
      return group;
    },

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      var _this3 = this;

      if (CarrotSearchFoamTree.supported) {
        (function () {
          var _this = _this3,
              _page = $('html,body');
          var onSelection = _this3.attrs.onSelection;
          var foamtree = new CarrotSearchFoamTree({
            id: _this3.elementId,
            pixelRatio: window.devicePixelRatio || 1,

            // Show relaxation
            relaxationVisible: _this3.get('browser.isMobile') ? false : true,
            relaxationMaxDuration: 8000,
            relaxationQualityThreshold: _this3.get('browser.isMobile') ? 2.0 : 0.2,

            groupMinDiameter: 0,
            maxGroupLevelsDrawn: 2,
            maxGroupLabelLevelsDrawn: 2,
            groupBorderRadius: 0.5,
            groupBorderWidth: 2,

            groupSelectionOutlineWidth: 0,
            groupSelectionOutlineColor: "hsla(193, 87%, 50%, 1)",
            groupFillType: "plain",
            groupStrokeType: "none",
            exposeEasing: "cubicIn",
            parentFillOpacity: 0.9,
            parentStrokeOpacity: 0,
            groupLabelFontFamily: "Lato",
            groupLabelFontWeight: "600",
            /*groupLabelDarkColor: "hsla(193, 87%, 50%, 1)",
            groupLabelLightColor: "hsla(193, 87%, 50%, 1)",*/
            groupLabelDarkColor: "#fff",
            groupLabelLightColor: "#fff",
            groupLabelColorThreshold: 0,
            attributionLogoScale: 0,
            attributionPosition: 1,
            attributionWeight: 0.01,
            attributionTheme: "dark",
            zoomMouseWheelFactor: 1,

            rainbowStartColor: "rgba(0,0,0,0.98)",
            rainbowEndColor: "rgba(0,0,0,0.98)",
            rainbowLightnessCorrection: 0,

            // Draw custom content during animations
            wireframeContentDecorationDrawing: "always",
            wireframeLabelDrawing: "always",

            // Call group content decorator on every polygon draw.
            groupContentDecoratorTriggering: "onSurfaceDirty",

            // The decorator that draws our images
            groupContentDecorator: function groupContentDecorator(opts, params, vars) {
              var group = params.group;
              var ctx = params.context;
              var exposure = params.exposure;
              vars.groupLabelDrawn = true;

              // Draw image once loaded
              if (params.group.image) {
                // If polygon changed, recompute the inscribed rectangle
                if (params.shapeDirty || group.imageLoaded) {
                  group.imageLoaded = false;
                  // Compute the rectangle into which we'll render the image
                  group.boundingBox = CarrotSearchFoamTree.geometry.rectangleInPolygon(params.polygon, params.polygonCenterX, params.polygonCenterY, group.image.width / group.image.height, 4);
                }

                // Draw the image
                var isFirefox = window.navigator.userAgent.indexOf("Firefox") >= 0;
                //var imageSize = isFirefox ? Math.round(group.boundingBox.w) : group.boundingBox.w;
                //ctx.drawImage(params.group.image, group.boundingBox.x, group.boundingBox.y, imageSize, imageSize);

                // Rectangle inscribed in the polygon. We'll set the aspect ratio of the rectangle to be the
                // same as the aspect ratio of the image. When the group is exposed, we'll draw the full
                // image in the inscribed rectangle.
                group.inscribedBox = CarrotSearchFoamTree.geometry.boundingBox(params.polygon);

                var mainImageBox;
                if (exposure <= 0) {
                  // Not exposed, render cropped image
                  mainImageBox = group.boundingBox;
                } else if (exposure == 1) {
                  // Exposed, render full image
                  mainImageBox = group.inscribedBox;
                } else {
                  // Expose animation in progress, transition the image rectangle geometry.
                  mainImageBox = {
                    x: group.boundingBox.x * (1 - exposure) + group.inscribedBox.x * exposure,
                    y: group.boundingBox.y * (1 - exposure) + group.inscribedBox.y * exposure,
                    w: group.boundingBox.w * (1 - exposure) + group.inscribedBox.w * exposure,
                    h: group.boundingBox.h * (1 - exposure) + group.inscribedBox.h * exposure
                  };
                }

                // Set the group polygon path on the drawing context.
                ctx.beginPath();
                params.polygonContext.replay(ctx);
                ctx.closePath();

                // Since the image is larger than the polygon, we'll need to apply
                // clipping so that we don't draw beyond the polygon's area.
                ctx.save();
                ctx.clip();

                drawImageInBox(params.group.image, mainImageBox);
                var centerX = params.polygonCenterX;

                //if (exposure > 0.1) {
                var scratch = ctx.scratch();

                var info = scratch.fillPolygonWithText(
                // Fit the extra text inside the main polygon
                params.polygon,

                // Fit the extra text below the main label
                centerX, params.labelBoxTop + params.labelBoxHeight + 4,

                // use non-breakable space to prevent line breaks
                parseInt(params.group.moves) + ' MOVEs', {
                  maxFontSize: 0.5 * params.labelFontSize, // restrict max font size
                  verticalAlign: "top", // flow the text downwards from the center point
                  verticalPadding: 0.1, // use some smaller than default padding
                  maxTotalHeight: 1 // use the full available height
                });

                // Draw the rectangle. FoamTree already set for us the color
                // it used to draw the label, we'll use that color.
                if (info.fit) {
                  var labelBox = info.box;
                  var boxMargin = labelBox.h * 0.1;
                  ctx.roundRect(labelBox.x - 2 * boxMargin, labelBox.y - boxMargin, labelBox.w + 4 * boxMargin, labelBox.h + 2 * boxMargin, boxMargin * 2);

                  ctx.globalAlpha = 0.55;
                  ctx.fill();
                  ctx.globalAlpha = 0.85;
                  ctx.lineWidth = boxMargin * 0.3;
                  ctx.stroke();

                  // Draw the text from our scratch buffer
                  //ctx.globalAlpha = exposure.toFixed(2);
                  ctx.fillStyle = "rgba(255, 255, 255, 1";
                  scratch.replay(ctx);
                  //}
                }

                ctx.restore();
              }

              // Draws the image positioned in the provided rectangle.
              function drawImageInBox(image, box, multiplier) {
                var groupWidthToHeight = box.w / box.h;
                var imageWidthToHeight = image.width / image.height;

                var scale = groupWidthToHeight < imageWidthToHeight ? box.h / image.height : box.w / image.width;

                var xOffset = box.x / scale,
                    yOffset = box.y / scale;
                if (groupWidthToHeight < imageWidthToHeight) {
                  scale = box.h / image.height;
                  xOffset -= (image.width - box.w / scale) / 2;
                } else {
                  scale = box.w / image.width;
                  yOffset -= (image.height - box.h / scale) / 2;
                }

                group.scale = scale;

                ctx.save();
                ctx.scale(scale, scale);
                ctx.translate(xOffset, yOffset);
                if (multiplier) {
                  ctx.translate(image.width / 2, image.height / 2);
                  ctx.scale(multiplier, multiplier);
                  ctx.translate(-image.width / 2, -image.height / 2);
                }
                ctx.globalAlpha = 0.60;
                ctx.fill();
                ctx.drawImage(image, 0, 0);
                ctx.restore();
              }
            },

            // Expose group on single click
            onGroupClick: function onGroupClick(e) {
              //console.log(e);
              e.preventDefault();

              if (this.get("exposure").groups[0] && e.group && this.get("exposure").groups[0].label === e.group.label) {
                console.log("Same group selected!");
                return;
              }

              // Trigger selection action
              if (onSelection) {
                onSelection(e.group);
              }

              // Unexpose if clicked outside of any polygon
              if (!e.group) {
                this.set("exposure", { groups: [], all: true, exposed: false, keepPrevious: false });
                this.set("select", { groups: [], all: true, selected: false, keepPrevious: false });
                this.set("open", { groups: [], all: true, open: false, keepPrevious: false });
                return;
              } else if (!e.bottommostOpenGroup) {
                this.set("exposure", { groups: [e.group], keepPrevious: false });
                this.set("select", { groups: [e.group], keepPrevious: false });
                this.set("open", { groups: [e.group], keepPrevious: false });
              } else {
                this.set("exposure", e.group);
                this.set("select", e.group);
                this.set("open", e.group);
              }
              //this.redraw();

              if (_this.get('scrollToTreemap')) {
                _ember['default'].run.next(_this, function () {
                  _page.animate({ scrollTop: _this.$().offset().top - 70 }, 500);
                });
              }
            },

            // Prevent default action (expose) on double click
            onGroupDoubleClick: function onGroupDoubleClick(e) {
              e.preventDefault();
              // Clicked group is exposed, unexpose.
              this.set("exposure", null);
              this.set("select", null);
              this.set("open", null);

              if (onSelection) {
                onSelection(null);
              }
            },
            onGroupMouseWheel: function onGroupMouseWheel(e) {
              e.allowOriginalEventDefault();
              e.preventDefault();
            },
            onGroupDrag: function onGroupDrag(e) {
              e.allowOriginalEventDefault();
              e.preventDefault();
            },
            onGroupDragStart: function onGroupDragStart(e) {
              e.allowOriginalEventDefault();
              e.preventDefault();
            }
          });

          _this3.set('foamtree', foamtree);
          //console.log("Treemap visualization supported.");
        })();
      } else {
          // Display a warning or skip visualization.
          console.log("Treemap visualization not supported.");
        }
    }),

    // Resize treemap on window resize
    debouncedDidResize: function debouncedDidResize(width, height, evt) {
      if (this.get('foamtree')) {
        this.get('foamtree').resize();
      }
    },

    /*click(event) {
      if(this.get('browser.isMobile')) {
        this.createFoamTreeEventTrigger("click", event);
      }
    },
     doubleTap(event) {
      if(this.get('browser.isMobile')) {
        this.createFoamTreeEventTrigger("doubleclick", event);
      }
    },*/

    createFoamTreeEventTrigger: function createFoamTreeEventTrigger(type, event) {
      //console.log(event);
      if (type === "doubleclick") {
        this.get('foamtree').set("exposure", null);
        this.get('foamtree').set("select", null);
        this.get('foamtree').set("open", null);
        return;
      }
      var eventDetails = this.pageToElementRelative(event.currentTarget, event.pageX, event.pageY);
      this.get('foamtree').trigger(type, eventDetails);
    },

    // FoamTree requires element-relative coordinates.
    // Here is a simple conversion from page-relative ones.
    pageToElementRelative: function pageToElementRelative(element, clientX, clientY) {
      var target = {};
      var rect = element.getBoundingClientRect();
      var yThreshold = this.attrs.yThreshold || 0;
      target.x = clientX - rect["left"];
      target.y = clientY - rect["top"] + parseInt(yThreshold);
      console.log(target.y);
      return target;
    },

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      this.get('foamtree').dispose();
    })
  });
});