define('hoolio-www/components/tv-preview/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tvService: _ember['default'].inject.service('tv'),

    classNames: ['tv-preview-component', 'animated'],
    classNameBindings: ['isEnabled:slideInRight:slideOutRight'],
    isEnabled: true,

    actions: {
      gotoTV: function gotoTV() {
        this.get('router').transitionTo('tv');
      },

      closeTvPreview: function closeTvPreview() {
        this.toggleProperty('isEnabled');
      }
    }
  });
});