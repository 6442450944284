define('hoolio-www/search/route', ['exports', 'ember', 'hoolio-www/mixins/reset-scroll'], function (exports, _ember, _hoolioWwwMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsResetScroll['default'], {
    model: function model() {
      //return this.store.peekAll('video');
      return this.store.findAll('video');
    },

    afterModel: function afterModel(model, transition) {
      if (model.get('length') === 0) {
        model.set('isPending', true);
        this.store.queryRecord('default-dashboard', { source: "hoolio", name: "Default Dashboard 2 steps", expand: false }).then(function (data) {
          data.get('dashboard').then(function () {
            model.set('isPending', false);
          });
        });
      }

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set('q', '');
        controller.set('res', 'fs-0');
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});