define('hoolio-www/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  /* globals Stripe */

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: _ember['default'].inject.service(),
    modals: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),
    emagNet: _ember['default'].inject.service(),
    emagPricing: _ember['default'].inject.service(),

    onInit: _ember['default'].on('init', function () {
      var _this = this;

      this.get('modals').on('login', this, function () {
        _this.send('showLoginModal');
      });

      this.get('modals').on('register', this, function () {
        _this.send('showRegisterModal');
      });

      this.get('modals').on('pricing', this, function () {
        _this.send('showPricingModal');
      });

      Stripe.setPublishableKey(this.get('config.stripe.key'));
    }),

    beforeModel: function beforeModel() {
      var _this2 = this;

      this._super.apply(this, arguments);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this2.get('kaltura').getListKS(function (credentials) {
          resolve(credentials);
        }, function (error) {
          reject('[Hoolio] Getting new KS token failed.');
        });
      });
    },

    model: function model() {
      return this._loadCurrentUser();
    },

    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('session').loadCurrentUser();
    },

    afterModel: function afterModel() {
      _ember['default'].run.later(this, function () {
        //Prerender.io service
        window.prerenderReady = true;
      }, 5000);

      // Remove loading overlay
      _ember['default'].$('body #loading-hero').remove();
    },

    sessionAuthenticated: function sessionAuthenticated() {
      var _this3 = this;

      this._loadCurrentUser()['catch'](function () {
        return _this3.get('session').invalidate();
      });

      var attemptedTransition = this.get('session.attemptedTransition');

      this._loadCurrentUser().then(function () {
        if (attemptedTransition && attemptedTransition.targetName != "dashboard") {
          (function () {
            var _queryParams = attemptedTransition.queryParams; //this.get('session.queryParams');
            if (!_ember['default'].$.isEmptyObject(_queryParams)) {
              _ember['default'].run.next(_this3, function () {
                this.transitionTo(attemptedTransition.targetName, { queryParams: _queryParams });
                this.set('session.attemptedTransition', null);
              });
            } else {
              attemptedTransition.retry();
              _this3.set('session.attemptedTransition', null);
            }
          })();
        } else {
          if (_this3.get('session.currentUser') && !_this3.get('session.currentUser').get('fitschoolCompleted')) {
            _this3.transitionTo('onboarding');
          } else {
            /*if(!this.get('controller.dashboardController.mm')) {
              this.set('controller.dashboardController.mm', 'fu-0'); // Init default slide
            }*/
            _this3.transitionTo('dashboard');
          }
        }
      });
    },

    actions: {
      /*loading() {
        const controller = this.get('controller');
        if(controller) {
          controller.set('loadingRoute', true);
        }
      },*/

      didTransition: function didTransition() {
        var controller = this.get('controller');
        _ember['default'].run.next(this, function () {
          if (controller.get('login')) {
            this.send('showLoginModal');
          }

          if (controller.get('register')) {
            this.send('showRegisterModal');
          }

          if (controller.get('pricing')) {
            this.send('showPricingModal');
          }
        });
      },

      queryParamsDidChange: function queryParamsDidChange(params) {
        _ember['default'].run.next(this, function () {
          // Store query params to attemptedTransition
          if (!_ember['default'].$.isEmptyObject(params)) {
            if (this.get('session.attemptedTransition')) {
              for (var key in params) {
                if (params.hasOwnProperty(key) && key != 'login' && key != 'register' && key != 'pricing') {
                  this.get('session.attemptedTransition').queryParams[key] = params[key];
                }
              }
            }
          }

          if (params['utm_source']) {
            this.set('emagNet.utmParams.utm_source', params['utm_source']);
          }

          if (params['utm_medium']) {
            this.set('emagNet.utmParams.utm_medium', params['utm_medium']);
          }

          if (params['utm_campaign']) {
            this.set('emagNet.utmParams.utm_campaign', params['utm_campaign']);
          }

          if (params['utm_term']) {
            this.set('emagNet.utmParams.utm_term', params['utm_term']);
          }

          if (params['utm_content']) {
            this.set('emagNet.utmParams.utm_content', params['utm_content']);
          }

          if (params['pricing']) {
            this.send('showPricingModal');
          }

          if (params['register']) {
            this.send('showRegisterModal');
          }
        });
      },

      nestedRouteTransitionIn: function nestedRouteTransitionIn(transition) {
        //console.log(transition);
        // Send data to intercom
        this.get('analytics.intercom').update();

        if (transition) {
          //&& transition.targetName != "dashboard"
          this.set('session.attemptedTransition', transition);
          //console.log('current: '+this.get('session.attemptedTransition').targetName);
        }
      },

      nestedRouteTransitionOut: function nestedRouteTransitionOut(transition) {
        //console.log(transition);
        // Send data to intercom
        this.get('analytics.intercom').update();

        var attemptedTransition = this.get('session.attemptedTransition');
        if (attemptedTransition) {
          this.set('session.previousTransition', attemptedTransition);
          //console.log('prev: '+this.get('session.attemptedTransition').targetName);
        }
      },

      logout: function logout() {
        this.get('analytics.intercom').shutdown();
        this.get('session').invalidate();
      },

      showLoginModal: function showLoginModal(close_cb) {
        this.send('showModal', 'login-modal', {
          title: 'User login',
          okText: '',
          closeText: '',
          closed_cb: function closed_cb() {
            if (close_cb) {
              close_cb();
            }
          }
        });
      },

      showRegisterModal: function showRegisterModal(close_cb) {
        this.send('showModal', 'register-modal', {
          title: 'Create your account',
          okText: '',
          closeText: '',
          closed_cb: function closed_cb() {
            if (close_cb) {
              close_cb();
            }
          }
        });
      },

      showPricingModal: function showPricingModal(close_cb) {
        var _this4 = this;

        this.store.query('subscription-plan', { application: 'web' }).then(function (plans) {
          _this4.send('showModal', 'pricing-modal', {
            plans: plans,
            closed_cb: function closed_cb() {
              if (close_cb) {
                close_cb();
              }
            }
          });

          /* Track intercom event */
          _this4.get('analytics.intercom').trackEvent('pricing-table-opened');
          _this4.get('analytics.google').trackEvent('pricing-table', 'opened', 'engage');
        }, function (error) {
          console.log(error);
        });
      },

      // GLOBAL SHOW MODAL ACTION. This can trigger from every controller with bubble :)
      showModal: function showModal(name, content, params) {
        // Prevent 2 opened modal
        var _controller = this.get('controller');
        if (_controller.get('modalIsOpen')) {
          var _modalName = undefined;
          if (_controller.get('login')) {
            _modalName = 'login-modal';
          } else if (_controller.get('register')) {
            _modalName = 'register-modal';
          } else if (_controller.get('pricing')) {
            _modalName = 'pricing-modal';
          }
          console.warn('A ' + _modalName + ' was already open, but i closed it for you!');
          this.send('removeModal');
        }

        _controller.set('modalIsOpen', true);
        _controller.set(name.split("-")[0], true); // login || register || pricing
        this.controllerFor(name).set('content', content);
        return this.render(name, {
          into: 'application',
          outlet: 'modal',
          params: params
        });
      },

      // GLOBAL REMOVE MODAL ACTION.
      removeModal: function removeModal() {
        var _controller = this.get('controller');
        _controller.set('modalIsOpen', false);
        _controller.set('login', false);
        _controller.set('register', false);
        _controller.set('pricing', false);

        return this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },

      // On error redirect to notfound page.
      error: function error(_error, transition) {
        this.get('emag').error(_error);
        this.transitionTo('notfound');
        //Bubble up action
        return true;
      },

      clearSearch: function clearSearch() {
        this.set('controller.search', '');
      },

      gotoDashboard: function gotoDashboard() {
        this.transitionTo('dashboard');
      },

      gotoFitnessplans: function gotoFitnessplans() {
        this.transitionToRoute('fitnessplans');
      },

      gotoPrevRoute: function gotoPrevRoute() {
        var _this5 = this;

        var previousTransition = this.get('session.previousTransition');
        if (previousTransition) {
          (function () {
            var _queryParams = previousTransition.queryParams; //this.get('session.queryParams');
            if (!_ember['default'].$.isEmptyObject(_queryParams)) {
              _ember['default'].run.next(_this5, function () {
                this.transitionTo(previousTransition.targetName, { queryParams: _queryParams });
              });
            } else {
              previousTransition.retry();
            }
          })();
        } else {
          this.transitionTo('dashboard');
        }
      }
    }
  });
});