define('hoolio-www/fitnessplans/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    fitnessplanService: _ember['default'].inject.service('fitnessplan'),
    appController: _ember['default'].inject.controller('application'),
    modals: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    queryParams: ['fp', 'it'],
    fp: "fp-0",
    it: "summary",

    defaultFitSliderOptions: {
      margin: 5,
      wrapAround: false,
      cellSelector: '.fitnessplan-slider-item'
    },

    isLoading: false,

    _joinFitnessplan: function _joinFitnessplan(fitnessplan) {
      var _this = this;

      this.set('isLoading', true);
      var flashMessages = _ember['default'].get(this, 'flashMessages');
      this.get('fitnessplanService').registerToFitnessplan(fitnessplan, function (userFitnessplan) {
        _this.send('removeModal');
        _this.set('isLoading', false);

        /* Track intercom event */
        var metadata = {
          title: fitnessplan.get('name'),
          start_date: moment().unix()
        };
        _this.get('analytics.intercom').trackEvent('fitnessplan-scheduled', metadata);
        _this.get('analytics.intercom').update({ active_fitnessplan: fitnessplan.get('name') });
        _this.get('analytics.google').trackEvent('fitnessplan', 'schedule', 'engage');

        flashMessages.success("Congrats! You are ready to start your fitness plan.");
        _this.transitionToRoute('dashboard');
      }, function (msg) {
        _this.send('removeModal');
        _this.set('isLoading', false);
        flashMessages.danger(msg);
        _this.get('modals').trigger('pricing');
      });
    },

    actions: {
      goBack: function goBack() {
        this.send('gotoPrevRoute');
      },

      joinFitnessplan: function joinFitnessplan(fitnessplan) {
        var _this2 = this;

        if (!this.get('session.isAuthenticated')) {
          this._joinFitnessplan(fitnessplan);
        } else {
          this.get('session.currentUser.activeFitnessPlan').then(function (activeFitnessPlan) {
            if (activeFitnessPlan && activeFitnessPlan.get('incompleteWorkouts.firstObject')) {
              _this2.send('showModal', 'general-warning-modal', {
                title: 'Wait a minute...',
                body: "You currently have an active fitness plan. Do you want to quit '" + activeFitnessPlan.get('fitnessplan.name') + "' and join '" + fitnessplan.get('name') + "'?",
                okText: "Yes, i do",
                closeText: 'No',
                confirm_cb: function confirm_cb() {
                  _this2._joinFitnessplan(fitnessplan);
                },
                cancel_cb: function cancel_cb() {
                  //console.log('cancel');
                },
                closed_cb: function closed_cb() {
                  //console.log('closed');
                }
              });
            } else {
                _this2._joinFitnessplan(fitnessplan);
              }
          });
        }
      },

      setActiveItem: function setActiveItem(item) {
        if (item) {
          this.set('fp', item);

          if (item.split('-')[1] != 0) {
            this.set('it', "summary");
          }
        }
      }
    }
  });
});