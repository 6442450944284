define('hoolio-www/subscription-plan/model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    active: _emberData['default'].attr('boolean'),
    amount: _emberData['default'].attr('number'),
    createdAt: _emberData['default'].attr('date'),
    currency: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    extraInfo: _emberData['default'].attr('string'),
    intervalCount: _emberData['default'].attr('number'),
    intervalType: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    packages: _emberData['default'].attr('array'),
    planId: _emberData['default'].attr('string'),
    premium: _emberData['default'].attr('boolean'),
    sources: _emberData['default'].attr('array'),
    statementDescriptor: _emberData['default'].attr('string'),
    syncedAt: _emberData['default'].attr('date'),
    trialPeriod: _emberData['default'].attr('number'),
    rawMetadata: _emberData['default'].attr('object'),
    applications: _emberData['default'].attr('array')
  });
});