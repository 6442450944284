define('hoolio-www/onboarding/route', ['exports', 'ember', 'hoolio-www/mixins/authenticated-route'], function (exports, _ember, _hoolioWwwMixinsAuthenticatedRoute) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsAuthenticatedRoute['default'], {
    session: _ember['default'].inject.service(),

    model: function model(params) {
      return this.get('session').loadCurrentUser();
    },

    afterModel: function afterModel(model, transition) {
      _ember['default'].$(document).attr('title', 'hoolio - Welcome');

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      controller.set('model', model);

      model.get('userPreference').then(function (pref) {
        //console.log(pref);
        if (!pref) {
          var newPref = _this.store.createRecord('user-preference', {
            user: model
          });
          newPref.save();
          controller.set('userPreference', newPref);
        } else {
          controller.set('userPreference', pref);
        }
      });

      this.get('store').findAll('character').then(function (characters) {
        controller.set('characters', characters);
      });

      this.get('store').findRecord('playlist', "1_vhheeij2").then(function (data) {
        controller.set('list1Videos', data.get("videos"));
      });

      this.get('store').findRecord('playlist', "1_db7rph3x").then(function (data) {
        controller.set('list2Videos', data.get("videos"));
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});