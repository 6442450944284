define('hoolio-www/serializers/rest-serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    primaryKey: '_id',

    /*serialize: function(snapshot, options) {
      return this._super(...arguments);
    },*/

    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var normalizedRootKey = this.payloadKeyFromModelName(typeClass.modelName);
      hash[normalizedRootKey] = this.serialize(snapshot, { includeId: true }); // Always include _id
    }
  });
});