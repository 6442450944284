define('hoolio-www/dashboard/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),
    videoService: _ember['default'].inject.service('video'),
    config: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    modals: _ember['default'].inject.service(),
    browser: _ember['default'].inject.service(),

    queryParams: ['hero'], // query params for each synced sliders value
    hero: 0, // hero = selected hero slide. Default value '0' which is the first slide
    trm: "trm-0",

    addHeroMargin: _ember['default'].computed("session.isAuthenticated", "heroComponents.[]", function () {
      return !this.get('session.isAuthenticated') && this.get('heroComponents');
    }),

    heroSliderOptions: _ember['default'].computed(function () {
      return {
        wrapAround: true,
        setGallerySize: false,
        freeScroll: false,
        pageDots: false,
        prevNextButtons: true,
        autoPlay: 7000,
        cellSelector: '.hero-component',
        initialIndex: this.get('hero')
      };
    }),

    style: _ember['default'].computed('', function () {
      var _style = 'background: url("/assets/img/general/dashboard_bg.jpg");'; //linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ),
      return new _ember['default'].String.htmlSafe(_style);
    }),

    // Load user's dashboard or set the default one.
    onUser: _ember['default'].on('init', _ember['default'].observer('session.currentUser', function () {
      this.set('userDashboard', _ember['default'].A([]));
      if (!this.get('session.isAuthenticated') && !this.get('session.currentUser')) {
        this.get('emag').log("[Hoolio] Setting up default dashboard...");
        _ember['default'].run.next(this, this.setupDefaultDashboard);
      }
    })),

    setupDefaultDashboard: function setupDefaultDashboard() {
      var _this = this;

      this.store.queryRecord('default-dashboard', { source: "hoolio", name: "Default Dashboard 2 steps", expand: false }).then(function (defaultDashboard) {
        _this.set('userDashboard', defaultDashboard.get('dashboard'));
        _this.set('heroComponents', defaultDashboard.get('hero'));
        _this.set('herorow', defaultDashboard.get('herorow'));
      });
    },

    setupUserDashboard: function setupUserDashboard(userDashboard) {
      var _this2 = this;

      var ids = userDashboard.map(function (row) {
        return row.id;
      });

      this.store.query("playlist", { id: ids }).then(function (data) {
        _this2.set('userDashboard', data);
      });
    },

    heroTrainersSliderOptions: {
      wrapAround: false,
      cellSelector: '.hero-row-item',
      cellAlign: 'center'
    },

    treemapResultsSliderOptions: {
      wrapAround: false
    },

    treemapBackgroundStyle: _ember['default'].computed("sesion.currentUser", "treemapBackgroundImage", function () {
      var img = this.get("session.currentUser") ? "https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/1_u1zp2ciy/width/1920/height/1024/" : this.get('treemapBackgroundImage');
      return new _ember['default'].String.htmlSafe("background: url(" + img + ") no-repeat center center;");
    }),

    treemapSearchResultsTitle: _ember['default'].computed("treemapSearchResults", "selectedGroup", function () {
      var _selectedGroup = this.get('selectedGroup');
      if (_selectedGroup && _selectedGroup.groups) {
        return this.get('treemapSearchResults.length') + " workouts matching your '" + _selectedGroup.label + "' map";
      } else {
        return this.get('treemapSearchResults.length') + " workouts to level up your '" + _selectedGroup.label + "' MOVEs";
      }
    }),

    treemapSearchResults: _ember['default'].computed('selectedGroup', function () {
      var _selectedGroup = this.get('selectedGroup');
      if (_selectedGroup) {
        var pararms = {
          scoreboard: true,
          hooliometry: this.get('treeDataObject.id')
        };
        if (_selectedGroup.groups) {
          pararms[_selectedGroup.id] = '*';
        } else {
          pararms[_selectedGroup.parentId] = _selectedGroup.id;
        }
        return this.store.query('video', pararms);
      } else {
        return [];
      }
    }),

    actions: {
      // This is the top action for setting the active item for all dashboards fit-sliders (DDAU FTW).
      setActiveItem: function setActiveItem(item) {
        //console.log('---------------');
        this.set('fit', item);
      },

      onGroupSelection: function onGroupSelection(data) {
        this.set('selectedGroup', data);
      },

      setActiveTreeItem: function setActiveTreeItem(item) {
        this.set('trm', item);
      },

      joinWorkout: function joinWorkout(video) {
        var _this3 = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        this.get('store').findRecord('video', this.attrs.item.get('id')).then(function (video) {
          _this3.get('videoService').getOrCreateWorkout(video, function (workout) {
            _this3.get('router').transitionTo('workout', workout);
          }, function (msg) {
            flashMessages.danger(msg);
            _this3.get('modals').trigger('pricing');
          });
        });
      },

      gotoHeroTrainer: function gotoHeroTrainer(itemId, elementId, cellElement, item) {
        this.transitionToRoute('trainer', item.trainer);
      }
    }
  });
});