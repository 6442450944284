define('hoolio-www/helpers/round-num', ['exports', 'ember'], function (exports, _ember) {

  var round = function round(params) {
    // params[0] = number, params[1] = decimal places
    if (params[1]) {
      var dec = Math.pow(10, params[1]);
      return Math.round(params[0] * dec) / dec;
    } else {
      return Math.round(params[0]);
    }
  };
  exports['default'] = _ember['default'].Helper.helper(round);
});