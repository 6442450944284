define("hoolio-www/components/social-main-secondary/component", ["exports", "ember"], function (exports, _ember) {
  /* globals Instafeed*/
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["social-main-secondary-component"],

    _options: {
      freeScroll: true,
      freeScrollFriction: 0.05,
      contain: true,
      cellAlign: "left",
      cellSelector: '.slider-cell',
      pageDots: false,
      initialIndex: 0,
      prevNextButtons: true,
      wrapAround: false,
      arrowShape: {
        x0: 30,
        x1: 55, y1: 50,
        x2: 70, y2: 50,
        x3: 45
      }
    },

    onDidInsertElement: _ember["default"].on('didInsertElement', function () {})
  });
});