define('hoolio-www/services/analytics', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  /* globals Intercom, moment, CryptoJS, window */

  exports['default'] = _ember['default'].Service.extend({
    intercom: {
      boot: function boot(user) {
        var hash = CryptoJS.HmacSHA256(user.get('id'), _hoolioWwwConfigEnvironment['default'].intercom.key);
        _ember['default'].RSVP.hash({
          fitnessplan: user.get('activeFitnessPlan.fitnessplan'),
          subscription: user.get('activePremiumPlan.subscriptionPlan')
        }).then(function (data) {
          window.Intercom('boot', {
            app_id: _hoolioWwwConfigEnvironment['default'].intercom.app_id,
            name: user.get('fullName'),
            user_id: user.get('id'),
            email: user.get('email'),
            user_hash: hash.toString(),
            created_at: moment().unix(),
            active_fitnessplan: data.fitnessplan ? data.fitnessplan.get('name') : null,
            subscription: data.subscription ? data.subscription.get('name') : 'Basic'
          });
        });
      },

      update: function update(data) {
        data ? window.Intercom('update', data) : window.Intercom('update');
      },

      trackEvent: function trackEvent(name, metadata) {
        var _metadata = metadata || {};
        if (!name) {
          return;
        }
        window.Intercom('trackEvent', name, metadata);
      },

      shutdown: function shutdown() {
        window.Intercom('shutdown');
      }
    },

    google: {
      pageHasGa: function pageHasGa() {
        return window.ga && typeof window.ga === "function";
      },

      logTrackingEnabled: function logTrackingEnabled() {
        return !!window.ENV && !!window.ENV.LOG_EVENT_TRACKING;
      },

      logTracking: function logTracking() {
        _ember['default'].Logger.info('Tracking Google Analytics event: ', arguments);
      },

      trackPageView: function trackPageView(page) {
        if (this.pageHasGa()) {
          if (!page) {
            var loc = window.location;
            page = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;
          }

          window.ga('send', 'pageview', page);
        }

        if (this.logTrackingEnabled()) {
          this.logTracking('pageview', page);
        }
      },

      trackEvent: function trackEvent(category, action, label, value) {
        if (this.pageHasGa()) {
          window.ga('send', 'event', category, action, label, value);
        }

        if (this.logTrackingEnabled()) {
          this.logTracking('event', category, action, label, value);
        }
      }
    },

    facebook: {
      trackEvent: function trackEvent(type, data) {
        if (data) {
          window.fbq('track', type, data); // ex: type -> 'Lead'
        } else {
            window.fbq('track', type); // ex: type -> 'Lead'
          }
      }
    }
  });
});