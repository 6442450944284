define('hoolio-www/social-wall/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    afterModel: function afterModel(model, transition) {
      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});