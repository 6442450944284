define('hoolio-www/search/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    browser: _ember['default'].inject.service(),

    appController: _ember['default'].inject.controller('application'),

    queryParams: ['q'],
    q: _ember['default'].computed.alias('appController.search'),
    res: "fs-0",

    resultsSliderOptions: {
      wrapAround: false
    },

    searchResults: _ember['default'].computed('q', 'model.[]', function () {
      var filter = this.get('q').toLowerCase();
      if (filter.length > 2) {
        return this.get('model').filter(function (video) {
          return video.get('name').toLowerCase().indexOf(filter) !== -1 || video.get('description').toLowerCase().indexOf(filter) !== -1 || video.get('level').toLowerCase().indexOf(filter) !== -1 || video.get('trainerName').toLowerCase().indexOf(filter) !== -1 || video.get('muscleOrBodyArea').filter(function (area) {
            return area.toLowerCase().indexOf(filter) !== -1;
          }).length > 0 || video.get('aim').filter(function (aim) {
            return aim.toLowerCase().indexOf(filter) !== -1;
          }).length > 0 || video.get('packages').filter(function (pck) {
            return pck.toLowerCase().indexOf(filter) !== -1;
          }).length > 0 || video.get('mood').filter(function (mood) {
            return mood.toLowerCase().indexOf(filter) !== -1;
          }).length > 0;
        });
      } else {
        return [];
      }
    }),

    actions: {
      // This is the top action for setting the active item for all dashboards fit-sliders (DDAU FTW).
      setActiveItem: function setActiveItem(item) {
        this.set('res', item);
      },

      goBack: function goBack() {
        this.send('gotoDashboard');
      }
    }
  });
});