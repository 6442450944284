define("hoolio-www/dashboard/route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),

    headTags: function headTags() {
      return [{
        type: "meta",
        tagId: "meta-og-title-tag",
        attrs: {
          property: "og:title",
          content: "hoolio - fit just got real"
        }
      }, {
        type: "meta",
        tagId: "meta-og-url-tag",
        attrs: {
          property: "og:url",
          content: window.location.href
        }
      }, {
        type: "meta",
        tagId: "meta-og-image-tag",
        attrs: {
          property: "og:image",
          content: "//app.hoolio.com/assets/img/general/socialmedia.jpg"
        }
      }, {
        type: "meta",
        tagId: "meta-og-description-tag",
        attrs: {
          property: "og:description",
          content: "hoolio is an online platform that puts fun into fitness by making it possible for you to workout with friends, integrate your wearables and track your fitness progress."
        }
      }, {
        type: "meta",
        tagId: "meta-twitter-title-tag",
        attrs: {
          name: "twitter:title",
          content: "hoolio - fit just got real"
        }
      }, {
        type: "meta",
        tagId: "meta-twitter-description-tag",
        attrs: {
          name: "twitter:description",
          content: "hoolio is an online platform that puts fun into fitness by making it possible for you to workout with friends, integrate your wearables and track your fitness progress."
        }
      }, {
        type: "meta",
        tagId: "meta-twitter-image-tag",
        attrs: {
          name: "twitter:image",
          content: "//app.hoolio.com/assets/img/general/socialmedia.jpg"
        }
      }];
    },

    beforeModel: function beforeModel(transition) {},

    model: function model() {
      return [{
        id: 1,
        component: "tile-user-row",
        classNames: "six"
      }, {
        id: 2,
        component: "tile-fitnessplan",
        classNames: "four"
      }, {
        id: 3,
        component: "tile-studios",
        classNames: "two-h"
      }, {
        id: 4,
        component: "tile-library",
        classNames: "two-h"
      }, {
        id: 5,
        component: "tile-studio",
        classNames: "one"
      }, {
        id: 6,
        component: "tile-trainers",
        classNames: "two-h"
      }, {
        id: 7,
        component: "tile-social-corner",
        classNames: "one"
      }, {
        id: 8,
        component: "tile-treemap",
        classNames: "two-h"
      }];
    },

    afterModel: function afterModel(model, transition) {
      _ember["default"].$(document).attr('title', 'hoolio - dashboard');

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);

      if (!this.get('session.isAuthenticated')) {
        this.store.query('default-treemap', { name: 'Alex' }).then(function (treemapData) {
          controller.set('treemapData', treemapData);
          controller.set('treeDataObject', treemapData.get('firstObject.userGroups'));
          controller.set('treeDataTitle', treemapData.get('firstObject.title'));
          controller.set('treeDataSubtext', treemapData.get('firstObject.subtext'));
          controller.set('treemapBackgroundImage', treemapData.get('firstObject.backgroundImage'));
        });
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});