define('hoolio-www/studios/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    flashMessages: _ember['default'].inject.service(),

    queryParams: ['cfs'],
    cfs: "cfs-0",

    defaultFitSliderOptions: {
      margin: 5,
      wrapAround: false
    },

    actions: {
      goBack: function goBack() {
        this.send('gotoPrevRoute');
      },

      setActiveItem: function setActiveItem(item) {
        if (item) {
          this.set('cfs', item);
        }
      }
    }
  });
});