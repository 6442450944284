define('hoolio-www/components/tile-trainers/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    classNames: ['tile-trainers-component', 'tile', 'animated', 'fadeIn'],
    classNameBindings: ['tile.classNames'],

    trainers: [{
      id: 43087751,
      img: "./assets/img/dashboard/tiles/trainer_6.jpg",
      name: "Svava Sigbertsdottir"
    }, {
      id: 43088461,
      img: "./assets/img/dashboard/tiles/trainer_2.jpg",
      name: "Joshua Holland"
    }, {
      id: 43088551,
      img: "./assets/img/dashboard/tiles/trainer_4.jpg",
      name: "Rory Knight"
    }, {
      id: 44125922,
      img: "./assets/img/dashboard/tiles/trainer_1.jpg",
      name: "Charlotte Dodson"
    }, {
      id: 35676991,
      img: "./assets/img/dashboard/tiles/trainer_3.jpg",
      name: "Rebecca Louise"
    }, {
      id: 43088101,
      img: "./assets/img/dashboard/tiles/trainer_5.jpg",
      name: "Sofia Xirotiri"
    }],

    trainersSliderOptions: _ember['default'].computed(function () {
      return {
        cellAlign: 'center',
        wrapAround: true,
        setGallerySize: false,
        freeScroll: false,
        pageDots: false,
        prevNextButtons: true,
        autoPlay: 5000,
        cellSelector: '.hero-tile',
        initialIndex: this.get('hero')
      };
    }),

    onInit: _ember['default'].on('init', function () {}),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {}),

    actions: {
      gotoTrainer: function gotoTrainer(itemId, elementId, cellElement, item) {
        this.get('router').transitionTo('trainer', item.id);
      }
    }
  });
});