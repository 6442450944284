define('hoolio-www/login-modal/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    emagSocial: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    rememberMe: true,
    isLoading: false,
    isFbLoading: false,

    // Clear local storage depending on whether "remember me" is checked or not
    rememberMeChanged: _ember['default'].on('init', _ember['default'].observer('rememberMe', function () {
      this.get('emagSocial').clearCredentialsOnWindowClose(!this.get('rememberMe'));
    })),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('errorMessage', '');
        this.set('isLoading', true);

        if (_ember['default'].$('#login-modal-form').parsley().validate()) {
          (function () {
            var flashMessages = _ember['default'].get(_this, 'flashMessages');
            var credentials = _this.getProperties('email', 'password', 'rememberMe');
            _this.get('session').authenticate('authenticator:hoolio', credentials).then(function () {
              flashMessages.success('Successfully logged in!');

              _this.get('analytics.google').trackEvent('sign', 'login', 'convert');

              _this.set('isLoading', false); // authentication was successful
              _this.send('dispose_modal');
            }, function (message) {
              _this.set('errorMessage', message);
              _this.set('isLoading', false);
            });
          })();
        } else {
          this.set('isLoading', false);
        }
      },

      // action to trigger authentication with Facebook
      authenticateWithFacebook: function authenticateWithFacebook() {
        var _this2 = this;

        this.set('isFbLoading', true);
        this.get('session').authenticate('authenticator:facebook').then(function () {
          // authentication was successful
          _this2.send('dispose_modal');
        }, function (message) {
          _this2.set('isFbLoading', false);
          _this2.set('errorMessage', message);
        });
      },

      gotoPassword: function gotoPassword() {
        this.send('dispose_modal');
        this.transitionToRoute('password');
      },

      dispose_modal: function dispose_modal() {
        if (this.get('model.closed_cb')) {
          this.get('model.closed_cb')();
        }
        this.set('errorMessage', '');
        this.set('isLoading', false);
        this.set('isFbLoading', false);
        this.send('removeModal');
      }
    }
  });
});