define('hoolio-www/services/emag-pricing', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    emagNet: _ember['default'].inject.service(),

    /*
      Subscribe user to plan
    */
    purchasePlan: function purchasePlan(data, cb_success, cb_error) {
      var url = "/userSubscriptions";
      this.get('emagNet').postUrl(url, data, function (response) {
        cb_success(response);
      }, function (response) {
        console.error(response.responseText);
        cb_error('Something went wrong. Please try again.');
      });
    }
  });
});