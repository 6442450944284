define('hoolio-www/components/tile-studio/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['tile-studio-component', 'tile', 'animated', 'fadeIn'],
    classNameBindings: ['tile.classNames'],

    style: _ember['default'].computed('', function () {
      var _style = 'background: url("./assets/img/dashboard/tiles/free_studio.jpg");';
      return new _ember['default'].String.htmlSafe(_style);
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {}),

    click: function click() {
      this.get('router').transitionTo('studio', '58497f4d36e58920fcba256c');
    }
  });
});