define('hoolio-www/components/stars-tooltip/component', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, _ember, _emberCliTooltipsterComponentsToolTipster) {
  exports['default'] = _emberCliTooltipsterComponentsToolTipster['default'].extend({
    classNames: ['stars-tooltip'],

    // define properties
    content: _ember['default'].computed('attrs.stars', function () {
      if (!isNaN(this.attrs.starRating)) {
        var copy = '<div class="stars-tooltip-content"><p>Total ' + this.attrs.starRating + ' out of 5 stars</p><ul>';
        for (var rating in this.attrs.stars) {
          var perc = Math.round(this.attrs.stars[rating] / this.attrs.totalRatings * 1000) / 10;
          copy += '<li>' + rating + ' star <span class="progress"><div class="progress-bar" role="progressbar" aria-valuenow="' + perc + '" aria-valuemin="0" aria-valuemax="100" style="width: ' + perc + '%;">' + perc + '%</div></span></li>';
        }
        copy += '</ul></div>';
        return copy;
      } else {
        return 'Not rated yet';
      }
    }),
    contentAsHTML: true,
    position: 'bottom',
    animation: 'grow',
    //autoClose: false,
    delay: 0
  });
});