define('hoolio-www/explore/route', ['exports', 'ember', 'hoolio-www/mixins/authenticated-route'], function (exports, _ember, _hoolioWwwMixinsAuthenticatedRoute) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsAuthenticatedRoute['default'], {
    session: _ember['default'].inject.service(),

    model: function model() {
      return this.get('session').loadCurrentUser();
      /*return [
        {
          label: "Mood", 
          weight:2,
          groups: [
            { label: "Fun", weight:0.1, sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Booster", weight:7, sentiment: 1, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Passion", weight:3, sentiment: -1, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Time", weight:0,  sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" }
          ]
        },
        { 
          label: "Level", 
          weight:4,
          groups: [
            { label: "Intermediate", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg"},
            { label: "Beginner", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg"},
            { label: "Expert",  sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" }
          ]
        },
        { label: "Aims", 
          weight:6,
          groups: [
            { label: "Body transformation", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Body beauty", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Monny",  sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Body balance",  sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Burn and sweat",  sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Weight and fat",  sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" }
          ]
        },
        { label: "Duration", 
          weight:8,
          groups: [
            { label: "Very short", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Medium", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Very long", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Short",  sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" }
          ]
        },
        { 
          label: "Target", 
          weight:10,
          groups: [
            { label: "Beach body", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg"},
            { label: "Back to basics", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg"},
            { label: "Techy", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Spice up", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Busy mom", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" }
          ]
        },
        { 
          label: "Type", 
          weight:12,
          groups: [
            { label: "Mind & body", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Core", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Burn", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Dance", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" },
            { label: "Martial arts", sentiment: 0, photo: "/assets/img/general/dashboard_jump.jpg" }
          ]
        }
      ];*/
    },

    afterModel: function afterModel(model, transition) {
      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);

      model.get('userPreference').then(function (pref) {
        if (pref) {
          controller.set('dataObject', pref.get('userGroups'));
        }
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});