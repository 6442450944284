define('hoolio-www/services/kaltura', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    emagNet: _ember['default'].inject.service(),
    emagSocial: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),

    sessions: _ember['default'].Object.create({
      list: null,
      video: null
    }),

    /*
      Get a KS for listing all videos
    */
    getListKS: function getListKS(cb_success, cb_error) {
      var _this = this;

      var ks = this.get('sessions.list');
      if (ks) {
        this.get('emag').log('[Kaltura service] Restoring KS for listing.');
        cb_success(this.get('emagSocial.credentials'));
      } else {
        this.get('emag').log('[Kaltura service] Getting new KS for listing.');
        var url = '/listsession';
        this.get('emagNet').getUrl(url, function (response) {
          _this.set('sessions.list', response.session);
          cb_success(_this.get('emagSocial.credentials'));
        }, function (error) {
          _this.set('sessions.list', null);
          cb_error(error.statusText);
        });
      }
    },

    /*
      Get a KS for playing a specific video
    */
    //controlBarContainer.plugin -> flase //Hide controls
    getVideoKS: function getVideoKS(videoId, cb_success, cb_error) {
      var _this2 = this;

      this.get('emag').log('[Kaltura service] Getting new KS for video with id: ' + videoId + '.');
      var url = '/videosession/' + videoId;
      this.get('emagNet').getUrl(url, function (response) {
        _this2.set('sessions.video', response.session);
        cb_success(_this2.get('emagSocial.credentials'));
      }, function (error) {
        _this2.set('sessions.video', null);
        cb_error(error.statusText);
      });
    }
  });
});