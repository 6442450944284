define('hoolio-www/components/image-loader/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['image-loader'],
    classNameBindings: ['fixedSize::responsive'],
    attributeBindings: ['style'],
    placeholder: 'assets/img/general/default-image-2.png',

    fixedSize: _ember['default'].computed.or('attrs.width', 'attrs.height'),
    style: _ember['default'].computed('attrs.width', 'attrs.height', function () {
      var _style = '';
      if (this.attrs.width) {
        _style += 'width:' + this.attrs.width + 'px;';
      }

      if (this.attrs.height) {
        _style += 'height:' + this.attrs.height + 'px;';
      }
      return new _ember['default'].String.htmlSafe(_style);
    }),

    onInitialize: _ember['default'].on('didInsertElement', function () {
      //didReceiveAttrs
      if (this.attrs.url) {
        _ember['default'].run.scheduleOnce('afterRender', this, 'setImage', this.attrs.url);
      }
    }),

    onUpdate: _ember['default'].on('willUpdate', function () {
      if (this.attrs.url) {
        _ember['default'].run.scheduleOnce('afterRender', this, 'setImage', this.attrs.url);
      }
    }),

    setImage: function setImage(url) {
      var _this = this;

      if (!url) {
        return;
      }

      var image = this.$("img.hidden-img"),
          image_content = this.$('.img-content'),
          isCached = false;

      if (image && typeof image[0] != 'undefined') {
        isCached = image[0].complete; // Load cached img
      }

      if (image) {
        if (!isCached) {
          image.one("load", function () {
            _this.setImageUrl(url);
            if (_this.attrs.onImageLoad) {
              _this.attrs.onImageLoad();
            }
          });

          image.one("error", function () {
            image.off('load');
            console.error('Image loader error');
            _this.setImageUrl(_this.placeholder);
          });
          //console.log('New image');
        } else {
            //console.log('Cached image');
            this.setImageUrl(url);
          }
      }
    },

    setImageUrl: function setImageUrl(url) {
      var img_content = this.$('.img-content');
      img_content.css('background-image', 'url(' + url + ')');
      img_content.addClass('animated fadeIn');
      this.$('.circle-spinner').remove();

      if (this.attrs.width) {
        img_content.css('width', this.attrs.width + 'px');
      }

      if (this.attrs.height) {
        img_content.css('height', this.attrs.height + 'px');
      }
    },

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      this.set('lazyUrl', '');
      this.$("img.hidden-img").off();
    })
  });
});