define('hoolio-www/helpers/digital-clock', ['exports', 'ember'], function (exports, _ember) {
  /*global moment*/

  exports['default'] = _ember['default'].Helper.helper(function (params) {
    var h = Math.floor(params[0] / 3600);
    var m = Math.floor(params[0] % 3600 / 60);
    var s = Math.floor(params[0] % 60);
    var addZero = function addZero(number) {
      return number < 10 ? '0' + number : '' + number;
    };
    var formatHMS = function formatHMS(h, m, s) {
      if (h > 0) {
        return addZero(h) + ':' + addZero(m);
      }
      return addZero(m) + ':' + addZero(s);
    };
    return new _ember['default'].String.htmlSafe(formatHMS(h, m, s));
  });
});