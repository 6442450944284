define('hoolio-www/components/explore-main-primary/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['explore-main-primary'],
    attributeBindings: ['style'],

    style: _ember['default'].computed('this.attrs.item.bgImageUrl', function () {
      if (this.attrs.item.bgImageUrl) {
        return _ember['default'].String.htmlSafe('background-image: url("' + this.attrs.item.bgImageUrl + '")');
      }
    })
  });
});