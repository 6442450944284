define('hoolio-www/workout/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    opentok: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    pubnub: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),
    health: _ember['default'].inject.service(),
    videoPlayer: _ember['default'].inject.service(),
    resizeService: _ember['default'].inject.service('resize'),
    analyticsService: _ember['default'].inject.service('analytics'),
    flashMessages: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    videoChapters: _ember['default'].inject.service(),
    browser: _ember['default'].inject.service(),

    viewLayout: 'horizontal-layout',
    stopwatchStarted: true,
    finishIsLoading: false,
    autosaved: false,

    onInit: _ember['default'].on('init', function () {
      var _this = this;

      //this.checkWindowRatio();
      this.get('resizeService').on('debouncedDidResize', function (event) {
        _this.checkWindowRatio();
      });
    }),

    disableForwardBtn: _ember['default'].computed.empty('videoChapters.nextChapter'),
    disableBackwardBtn: _ember['default'].computed.empty('videoChapters.prevChapter'),

    receivedHeartrate: _ember['default'].computed.gt('health.heartrate', 0),
    receivedSteps: _ember['default'].computed.gt('health.steps', 0),

    playerStopped: _ember['default'].computed.not('videoPlayer.isPlaying'),
    showElement: _ember['default'].computed.or('isMouseMoved', 'playerStopped'),

    //videoChapters: Ember.computed.alias('model.video.chapters'),

    checkWindowRatio: function checkWindowRatio() {
      if (window.innerWidth / window.innerHeight < 1.5) {
        this.set('viewLayout', 'vertical-layout');
      } else {
        this.set('viewLayout', 'horizontal-layout');
      }
    },

    backgroundPhotoUrl: _ember['default'].computed('model.video.isFromYoutube', function () {
      var photoUrl = this.get('model.video.isFromYoutube') ? this.get('model.video.youtubeThumbnail') : this.get('model.video.thumbnailUrlWide');
      return photoUrl;
    }),

    onWorkoutFinish: _ember['default'].on('init', _ember['default'].observer('model.isCompleted', function () {
      var _this2 = this;

      if (this.get('model.isCompleted') && !this.get('autosaved')) {
        this.set('autosaved', true);
        this.set('model.status', 'complete');

        this.set('workoutMetric.sensorSteps', this.get('health.steps'));
        this.set('workoutMetric.computedMoves', this.get('model.video.moves'));
        this.set('workoutMetric.completedPercentage', this.get('model.video.completedPercentage'));
        this.set('workoutMetric.secondsOnLive', Math.floor(this.get('model.secondsOnLive')));
        this.set('workoutMetric.secondsOnVideo', Math.floor(this.get('videoPlayer.currentTimeValue')));

        this.get('workoutMetric').save().then(function (workoutMetric) {
          _this2.get('model').save().then(function (workout) {
            _this2.get('emag').log('[Hoolio] Autosaved workout.');
          }, function () {
            _this2.get('emag').error('[Hoolio] Could not save workout.');
          });
        }, function () {
          _this2.get('emag').error('[Hoolio] Could not save workout-metrics.');
        });
      }
    })),

    actions: {
      goBack: function goBack() {
        this.send('gotoPrevRoute');
      },

      finishWorkout: function finishWorkout() {
        var _this3 = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        this.set('finishIsLoading', true);
        this.set('model.status', 'complete');

        this.set('workoutMetric.sensorSteps', this.get('health.steps'));
        this.set('workoutMetric.computedMoves', this.get('model.video.moves'));
        this.set('workoutMetric.completedPercentage', this.get('model.video.completedPercentage'));
        this.set('workoutMetric.secondsOnLive', Math.floor(this.get('model.secondsOnLive')));
        this.set('workoutMetric.secondsOnVideo', Math.floor(this.get('videoPlayer.currentTimeValue')));

        this.get('workoutMetric').save().then(function (workoutMetric) {
          _this3.get('model').save().then(function (workout) {
            _this3.set('finishIsLoading', false);

            var _message = {
              mode: "workout",
              status: "completed",
              completePercentage: workout.get('video.completedPercentage'),
              moves: workout.get('video.moves')
            };

            /*Broadcast pubnub msg to other users*/
            _this3.get('pubnub').getInstance('workoutSyncPb').publish({
              channel: _this3.get('opentok.opentokSession'),
              message: _message
            });

            /*Broadcast pubnub msg to mobile*/
            _this3.get('pubnub').getInstance('workoutSyncPb').publish({
              channel: _this3.get('session.currentUser.id'),
              message: _message
            });

            _this3.transitionToRoute('workout.completed', workout);
          }, function () {
            _this3.set('finishIsLoading', false);
            _this3.get('emag').error('[Hoolio] Could not save workout.');
            flashMessages.danger('There was a problem with saving workout. Please try again.');
            _this3.transitionToRoute('library');
          });
        }, function () {
          _this3.set('finishIsLoading', false);
          _this3.get('emag').error('[Hoolio] Could not save workout-metrics.');
          flashMessages.danger('There was a problem with saving workout metrics. Please try again.');
          _this3.transitionToRoute('library');
        });
      },

      inviteFriends: function inviteFriends() {
        this.send('showModal', 'workout-invitation-modal', {
          workout: this.get('model'),
          title: 'Invite friends',
          okText: 'Resume workout',
          closeText: '',
          closed_cb: function closed_cb() {}
        });
      },

      enableCamera: function enableCamera() {
        this.get('opentok').connectPublisher();
      },

      disableCamera: function disableCamera() {
        this.get('opentok').disconnectPublisher();
      },

      toggleViewLayout: function toggleViewLayout() {
        if (this.get('viewLayout') === 'vertical-layout') {
          this.set('viewLayout', 'horizontal-layout');
        } else {
          this.set('viewLayout', 'vertical-layout');
        }
      },

      toggleFullscreen: function toggleFullscreen() {
        this.fullscreen.toggle();
      },

      onStopwatchTick: function onStopwatchTick(time) {
        this.set('model.secondsOnLive', time);
      },

      gotoChapter: function gotoChapter(index) {
        this.get('videoChapters').gotoChapter(index);
      },

      showDesktopWarning: function showDesktopWarning(msg) {
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        msg = msg ? msg : 'This feature';
        flashMessages.danger(msg + ' is only available for desktop browsers.');
      }
    }
  });
});