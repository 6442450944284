define('hoolio-www/application/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    modals: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    browser: _ember['default'].inject.service(),

    dashboardController: _ember['default'].inject.controller('dashboard'),

    hasScrolled: false,

    onInit: _ember['default'].on('init', function () {
      _ember['default'].run.scheduleOnce("afterRender", this, function () {
        var _this = this;

        var $window = _ember['default'].$(window);
        $window.on('scroll', function () {
          if ($window.scrollTop() > 20) {
            _this.set('hasScrolled', true);
          } else {
            _this.set('hasScrolled', false);
          }
        });
      });
    }),

    search: '',
    onSearch: _ember['default'].on('init', _ember['default'].observer('search', function () {
      var _search = this.get('search');
      if (_search && _search.length > 2 && this.get('currentRouteName') !== 'search') {
        _ember['default'].run.next(this, function () {
          this.transitionToRoute('search', { queryParams: { q: _search } });
        });
      } /* else if(_search && _search.length === 0 && this.get('currentRouteName') === 'search') {
         Ember.run.next(this, function() {
           this.transitionToRoute('dashboard');
         });
        }*/
    })),

    queryParams: ['login', 'register', 'pricing', 'spi', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'],
    login: false, // queryParam
    register: false, // queryParam
    pricing: false, // queryParam
    spi: null, // selected plan index queryParam, valid values: 1,2,3

    utm_source: '', // queryParam
    utm_medium: '', // queryParam
    utm_campaign: '', // queryParam
    utm_term: '', // queryParam
    utm_content: '', // queryParam

    hideTopNavbar: false,
    hideFooter: false,

    modalIsOpen: false, // For general porposes, in order to make sure that only one model is open
    searchIsExpanded: false,

    trialMsg: _ember['default'].computed('session.currentUser.trialDaysLeft', function () {
      return 'Your free trial ends in ' + this.get('session.currentUser.trialDaysLeft') + ' days';
    }),

    actions: {
      toggleFullscreen: function toggleFullscreen() {
        this.fullscreen.toggle();
      },

      onSearchEnter: function onSearchEnter() {
        this.toggleProperty('searchIsExpanded');
      },

      onSearchLeave: function onSearchLeave() {
        _ember['default'].run.later(this, function () {
          this.toggleProperty('searchIsExpanded');
        }, 300);
      }
    }
  });
});