define('hoolio-www/components/fitnessplan-row-info/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    fitnessplanService: _ember['default'].inject.service('fitnessplan'),
    session: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),

    classNames: ['fitnessplan-row-info'],
    attributeBindings: [],

    isLoading: false,
    initialTab: 'summary',

    actions: {
      joinFitnessplan: function joinFitnessplan(fitnessplan) {
        this.attrs.joinFitnessplan(fitnessplan);
      },

      joinWorkout: function joinWorkout(workout) {
        this.get('router').transitionTo('workout', workout);
      },

      gotoFitnessplans: function gotoFitnessplans() {
        this.get('router').transitionTo('fitnessplans');
      }
    }
  });
});