define('hoolio-www/studios/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    pubnubService: _ember['default'].inject.service('pubnub'),
    config: _ember['default'].inject.service(),
    emagNet: _ember['default'].inject.service(),

    model: function model() {
      return this.store.findAll('studio');
    },

    afterModel: function afterModel(model, transition) {
      _ember['default'].$(document).attr('title', 'hoolio - studio');

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      controller.set('model', model);

      var studioSyncPb = this.get('pubnubService').getInstance('studioSyncPb').initialize({
        subscribeKey: this.get('config.studioPubnub.subscribeKey'),
        publishKey: this.get('config.studioPubnub.publishKey'),
        uuid: this.get('session.currentUser.id'),
        ssl: true
      });

      var channels = [];
      model.forEach(function (studio) {
        channels.push('studio-' + studio.id + '-' + _this.get('config.environment'));

        _this.get('emagNet').getUrl('/studios/' + studio.id + '?playing=now', function (response) {
          //console.log(response.video.duration);
          studio.set('playingNow', response.video);
        }, function (error) {
          console.log(error);
        });

        _ember['default'].run.later(_this, function () {
          this.get('emagNet').getUrl('/studios/' + studio.id + '?playing=next', function (response) {
            //console.log(response.video.duration);
            studio.set('playingNext', response.video);
          }, function (error) {
            console.log(error);
          });
        }, 500);
      });

      _ember['default'].run.next(this, function () {
        if (channels.length && studioSyncPb) {
          studioSyncPb.hereNow(channels).then(function (data) {
            channels.forEach(function (channel, index) {
              //console.log(data.channels[channel]);
              if (data.channels[channel]) {
                _ember['default'].set(model.objectAt(index), 'occupancy', data.channels[channel].occupancy);
              }
            });
          });
        }
      });
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set('cfs', "cfs-0");
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      },

      loading: function loading(transition, originRoute) {
        var controller = this.controllerFor('studios');
        controller.set('currentlyLoading', true);
        transition.promise['finally'](function () {
          controller.set('currentlyLoading', false);
        });
      }
    }
  });
});