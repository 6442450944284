define('hoolio-www/breakpoints', ['exports'], function (exports) {
  exports['default'] = {
    mobile: '(max-width: 767px)',
    tablet: '(min-width: 768px) and (max-width: 992px)',
    desktop: '(min-width: 993px) and (max-width: 1200px)',
    jumbo: '(min-width: 1201px)',

    mobileOrTablet: '(max-width: 1024px)',
    tabletOrDesktop: '(min-width: 769px)'
  };
});