define('hoolio-www/video/adapter', ['exports', 'hoolio-www/adapters/rest-adapter', 'ember'], function (exports, _hoolioWwwAdaptersRestAdapter, _ember) {
  exports['default'] = _hoolioWwwAdaptersRestAdapter['default'].extend({
    /*handleResponse(status, headers, payload, requestData) {
      if (payload.video && payload.video.name.indexOf("Sorry this video has been removed") > -1) {
        return Ember.RSVP.reject(Ember.debug("Invalid video. "+payload.video._id+" video has been removed"));
      } else if(payload.videos) {
        payload.videos = payload.videos.filter(function(video, index){
          if(video.name.indexOf("Sorry this video has been removed") <= -1) {
            return video;
          } else {
            Ember.debug("Invalid video in index " +index+". "+video._id+" video has been removed");
          }
        });
      }
      return this._super(...arguments);
    }*/
  });
});