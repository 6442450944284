define('hoolio-www/initializers/kaltura', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'kaltura', 'service:kaltura');
    application.inject('controller', 'kaltura', 'service:kaltura');
    application.inject('component', 'kaltura', 'service:kaltura');
    application.inject('adapter', 'kaltura', 'service:kaltura');
  }

  exports['default'] = {
    name: 'kaltura',
    initialize: initialize
  };
});