define('hoolio-www/character/model', ['exports', 'ember-data/model', 'ember-data'], function (exports, _emberDataModel, _emberData) {
  exports['default'] = _emberDataModel['default'].extend({
    /*
    *  Basic attribures
    * 
    */
    aims: _emberData['default'].attr('array'),
    workoutTypes: _emberData['default'].attr('array'),
    name: _emberData['default'].attr('string'),
    imageSquare: _emberData['default'].attr('array'),
    notWorkoutTypes: _emberData['default'].attr('array'),
    image: _emberData['default'].attr('array'),
    description: _emberData['default'].attr('string'),
    moto: _emberData['default'].attr('string'),
    stats: _emberData['default'].attr('array'),
    title: _emberData['default'].attr('string'),

    /*
    *  Relationships
    * 
    */
    defaultTreemap: _emberData['default'].belongsTo('default-treemap', { inverse: null })
  });
});