define('hoolio-www/general-warning-modal/controller', ['exports', 'ember'], function (exports, _ember) {
  /* global $ */

  exports['default'] = _ember['default'].Controller.extend({
    isLoading: false,

    actions: {
      confirm: function confirm() {
        this.set('isLoading', true);
        if (this.get('model.confirm_cb')) {
          this.get('model.confirm_cb')();
        }
      },

      cancel: function cancel() {
        if (this.get('model.cancel_cb')) {
          this.get('model.cancel_cb')();
        }
        this.send('dispose_modal');
      },

      on_destroy_modal: function on_destroy_modal() {
        this.set('isLoading', false);
      },

      dispose_modal: function dispose_modal() {
        if (this.get('model.closed_cb')) {
          this.get('model.closed_cb')();
        }
        this.send('removeModal');
      }
    }
  });

  /*  Example usage
  
      this.send('showModal', 'general-warning-modal', {
        title:'Warning', 
        body:"test", 
        okText:'Ok',
        closeText: 'Close',
        confirm_cb() {
          console.log('confirm');
        },
        cancel_cb: function() {
          console.log('cancel');
        },
        closed_cb: function() {
          console.log('closed');
        }
      });
  */
});