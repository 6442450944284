define('hoolio-www/pricing-modal/controller', ['exports', 'ember'], function (exports, _ember) {
  /* globals Stripe */

  exports['default'] = _ember['default'].Controller.extend({
    emagSocial: _ember['default'].inject.service(),
    emagPricing: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    browser: _ember['default'].inject.service(),
    emagNet: _ember['default'].inject.service(),

    appController: _ember['default'].inject.controller('application'),

    onInit: _ember['default'].on('init', _ember['default'].observer('appController.spi', 'plans.[]', function () {
      if (this.get('plans.length') && this.get('appController.spi')) {
        this.send('setSelectedPlan', this.get('plans').objectAt(this.get('appController.spi') - 1));
      }
    })),

    isFbLoading: false,
    isLoading: false,
    purchaseCompleted: false,
    showRegisterForm: false,
    sp: null, // selected plan

    creditCard: _ember['default'].Object.create({
      name: "",
      number: null,
      expiry: null,
      cvc: null
    }),

    coupon: null,
    couponCode: '',
    hasCoupon: _ember['default'].computed.notEmpty('coupon'),

    firstSelected: _ember['default'].computed.equal('plan', 0),
    secondSelected: _ember['default'].computed.equal('plan', 1),
    thirdSelected: _ember['default'].computed.equal('plan', 2),

    plans: _ember['default'].computed.sort('model.plans', function (a, b) {
      var _a = a.get('amount'),
          _b = b.get('amount');
      if (_a > _b) {
        return 1;
      } else if (_a < _b) {
        return -1;
      }
      return 0;
    }),

    onStripeToken: function onStripeToken(status, response) {
      var _this = this;

      var flashMessages = _ember['default'].get(this, 'flashMessages');
      if (status === 402) {
        this.set("isLoading", false);
        flashMessages.danger(response.error.message);
      } else if (status === 200) {
        var data = {
          token: response.id,
          planId: this.get('sp.planId')
        };

        if (this.get('coupon')) {
          data['coupon'] = this.get('coupon.id');
        }

        this.get('emagPricing').purchasePlan(data, function (response) {
          //console.log(response);
          _this.set("isLoading", false);
          _this.set("purchaseCompleted", true);
          _this.store.push(_this.store.normalize('user-subscription', response.userSubscription));
          _this.get('session.currentUser').reload();

          /* Track intercom event */
          var _planName = _this.get('sp.name').toLowerCase(),
              metadata = {
            plan_name: _planName
          };
          _this.get('analytics.intercom').trackEvent('plan-register', metadata);
          _this.get('analytics.intercom').update({ subscription: _planName });
          _this.get('analytics.google').trackEvent('sign', 'register_' + _planName, 'upgrade');
          _this.get('analytics.facebook').trackEvent('Purchase', { name: _planName, value: _this.get('sp.amount'), currency: _this.get('sp.currency') });
        }, function (error) {
          _this.set("purchaseCompleted", false);
          _this.set("isLoading", false);
          flashMessages.danger(error);
          console.log(error);
        });
      }
    },

    actions: {
      resetAll: function resetAll() {
        this.set('numberError', '');
        this.set('cvcError', '');
        this.set('expiryError', '');
        this.set('nameError', '');

        this.set("purchaseCompleted", false);
        this.set("showRegisterForm", false);
        this.set("isFbLoading", false);
        this.set("isLoading", false);
        this.set('sp', null);
        this.set('appController.spi', null);

        this.set("creditCard", _ember['default'].Object.create({
          name: "",
          number: null,
          expiry: null,
          cvc: null
        }));

        this.set("coupon", null);
        this.set("couponCode", '');
        this.set('couponError', '');
      },

      closePricingModal: function closePricingModal() {
        if (this.get('model.closed_cb')) {
          this.get('model.closed_cb')();
        }

        this.send('resetAll');
        this.send('removeModal');
      },

      backToPricingTable: function backToPricingTable() {
        this.set('sp', null);
        this.set("showRegisterForm", false);
        this.set("coupon", null);
        this.set("couponCode", '');
        this.set('couponError', '');
      },

      setSelectedPlan: function setSelectedPlan(sp, upgrade) {
        this.set('numberError', '');
        this.set('cvcError', '');
        this.set('expiryError', '');
        this.set('nameError', '');
        this.set("showRegisterForm", false);

        this.set('sp', sp);

        var _planName = this.get('sp.name').toLowerCase(),
            _action = '',
            _label = '';

        if (!this.get('session.isAuthenticated')) {
          this.set("showRegisterForm", true);

          _action = _planName + '_registration_initiated';
          _label = 'interest';
        } else {
          _action = 'upgrade_' + _planName + '_initiated';
          _label = 'upgrade';

          /* Track intercom event */
          var metadata = {
            plan_name: _planName
          };
          this.get('analytics.intercom').trackEvent('upgrade_initiated', metadata);
          this.get('analytics.facebook').trackEvent('InitiateCheckout');
        }
        this.get('analytics.google').trackEvent('sign', _action, _label);
      },

      purchasePlan: function purchasePlan() {
        var isNumberValid = Stripe.card.validateCardNumber(this.get('creditCard.number')),
            isCvcValid = Stripe.card.validateCVC(this.get('creditCard.cvc')),
            isExpiryValid = Stripe.card.validateExpiry(this.get('creditCard.expiry')),
            isNameValid = this.get('creditCard.name') != "";

        if (!isNumberValid) {
          this.set('numberError', 'Invalid card number');
        }

        if (!isCvcValid) {
          this.set('cvcError', 'Invalid CVC number');
        }

        if (!isExpiryValid) {
          this.set('expiryError', 'Invalid expiry date');
        }

        if (!isNameValid) {
          this.set('nameError', 'Invalid card holder name');
        }
        if (isNumberValid && isCvcValid && isExpiryValid && isNameValid) {
          this.set("isLoading", true);
          Stripe.card.createToken(_ember['default'].$('#card-form'), this.onStripeToken.bind(this));
        }
      },

      showRegisterForm: function showRegisterForm() {
        this.set("showRegisterForm", true);
      },

      register: function register() {
        var _this2 = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        this.set('errorMessage', '');
        this.set('isLoading', true);

        if (this.get('password') !== this.get('passwordConfirmation')) {
          this.set('isLoading', false);
          this.set('errorMessage', 'Password mismatch. Please confirm your password.');
          return;
        }

        if (_ember['default'].$('#register-modal-form').parsley().validate()) {
          var data = this.getProperties('firstName', 'lastName', 'email', 'password');
          this.get('emagSocial').register(data, function (response) {
            var credentials = {
              email: response.user_email,
              password: _this2.get('password'),
              rememberMe: false
            };

            flashMessages.success('A verification link has been send to your email, in order to activate your account.', { timeout: 10000 });

            _this2.get('analytics.google').trackEvent('sign', 'register', 'convert');
            _this2.get('analytics.facebook').trackEvent('CompleteRegistration');

            _this2.get('session').authenticate('authenticator:hoolio', credentials).then(function () {
              _this2.set('isLoading', false); // authentication was successful
              //this.send('dispose_modal');
              _this2.set("showRegisterForm", false);
              if (!_this2.get('sp.premium')) {
                _this2.set("purchaseCompleted", true);
              }
            }, function (message) {
              _this2.set('errorMessage', message);
              _this2.set('isLoading', false);
            });
          }, function (error) {
            _this2.set('errorMessage', error);
            _this2.set('isLoading', false);
          });
        } else {
          this.set('isLoading', false);
        }
      },

      // action to trigger authentication with Facebook
      authenticateWithFacebook: function authenticateWithFacebook() {
        var _this3 = this;

        this.set('isFbLoading', true);

        this.get('session').authenticate('authenticator:facebook').then(function () {
          _this3.get('analytics.google').trackEvent('sign', 'register', 'convert');
          _this3.get('analytics.facebook').trackEvent('CompleteRegistration');

          // authentication was successful
          _this3.set("showRegisterForm", false);
          if (!_this3.get('sp.premium')) {
            _this3.set("purchaseCompleted", true);
          } else if (_this3.get('session.currentUser.hasPremiumPlan')) {
            _this3.send('closePricingModal');
          }
        }, function (message) {
          _this3.set('isFbLoading', false);
          _this3.set('errorMessage', message);
        });
      },

      applyCoupon: function applyCoupon() {
        var _this4 = this;

        if (!this.get('couponCode')) {
          this.set('couponError', 'Invalid coupon code.');
        } else {
          (function () {
            var flashMessages = _ember['default'].get(_this4, 'flashMessages');
            var url = '/coupon?code=' + _this4.get('couponCode');
            _this4.get('emagNet').getUrl(url, function (response) {
              _this4.set("coupon", response);
              if (response.metadata.desc) {
                flashMessages.success(response.metadata.desc + " applied.");
              }
            }, function (response) {
              _this4.set('couponError', 'Invalid coupon code.');
            });
            /*this.set("coupon", Ember.Object.create({
              "id": "25POFF2016",
              "object": "coupon",
              "amount_off": null,
              "created": 1478848078,
              "currency": "usd",
              "duration": "once",
              "duration_in_months": null,
              "livemode": false,
              "max_redemptions": 5,
              "metadata": {
                "desc": "50 percent off coupon"
              },
              "percent_off": 50,
              "redeem_by": 1510384075,
              "times_redeemed": 0,
              "valid": true
            }));
            flashMessages.success(this.get('coupon').metadata.desc + " applied.");*/
          })();
        }
      },

      removeCoupon: function removeCoupon() {
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        if (this.get('coupon').metadata.desc) {
          flashMessages.success(this.get('coupon').metadata.desc + " removed.");
        }
        this.set("coupon", null);
        this.set("couponCode", '');
        this.set('couponError', '');
      },

      clearCouponErrors: function clearCouponErrors() {
        this.set('couponError', '');
      }
    }
  });
});