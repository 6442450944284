define('hoolio-www/services/browser', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  /* globals navigator, window */

  exports['default'] = _ember['default'].Service.extend({
    mobile: {
      iOS: function iOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
      },
      Android: function Android() {
        return navigator.userAgent.match(/Android/i) ? true : false;
      },
      BlackBerry: function BlackBerry() {
        return navigator.userAgent.match(/BlackBerry/i) ? true : false;
      },
      Opera: function Opera() {
        return navigator.userAgent.match(/Opera Mini/i) ? true : false;
      },
      Windows: function Windows() {
        return navigator.userAgent.match(/IEMobile/i) ? true : false;
      },
      any: function any() {
        return this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows();
      }
    },

    isMobile: _ember['default'].computed("", function () {
      return this.get('mobile').any();
    })
  });
});