define('hoolio-www/services/config', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = Service.extend({
    __config__: computed(function () {
      return getOwner(this)._lookupFactory('config:environment');
    }),

    unknownProperty: function unknownProperty(path) {
      return get(this, '__config__.' + path);
    }
  });
});