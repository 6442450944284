define('hoolio-www/onboarding/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    emagSocial: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    queryParams: ['step'],
    step: 1,
    finishIsLoading: false,

    fitValueText: _ember['default'].computed('userPreference.fitLevel', 'model.healthData.gender', function () {
      var value = parseInt(this.get('userPreference.fitLevel'));
      var gender = this.get('model.healthData.gender');
      if (value === 1) {
        return gender === "male" ? "I would rather Netflix" : "Masterpiece in progress";
      } else if (value === 2) {
        return gender === "male" ? "I have new trainers" : "Almost fabulous";
      } else if (value === 3) {
        return gender === "male" ? "I go to the gym" : "I've got moves";
      } else if (value === 4) {
        return gender === "male" ? "Twice on Sunday's" : "Action hero";
      } else if (value === 5) {
        return gender === "male" ? "I'm batman" : "Beyoncé";
      }
    }),

    ageOptions: [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99],

    genderOptions: ['male', 'female'],

    list1VideosSelected: _ember['default'].computed.gt('userPreference.onboardingList1.length', 0),
    list2VideosSelected: _ember['default'].computed.gt('userPreference.onboardingList2.length', 0),
    showNextIfNoSelectedVideos: _ember['default'].computed.or('list1VideosSelected', 'list2VideosSelected'),
    hideNextIfNoSelectedVideos: _ember['default'].computed.not('showNextIfNoSelectedVideos'),

    list1Videos: [],
    list2Videos: [],

    photos: [{
      id: 1,
      url: "https://i.ytimg.com/vi/An3c-0tyX2c/1.jpg"
    }, {
      id: 2,
      url: "https://i.ytimg.com/vi/-cXdoL0qnVE/1.jpg"
    }, {
      id: 3,
      url: "https://i.ytimg.com/vi/zsaGmHsIGy8/1.jpg"
    }, {
      id: 4,
      url: "https://i.ytimg.com/vi/Zt38koLzE4g/1.jpg"
    }, {
      id: 5,
      url: "https://i.ytimg.com/vi/5hVAUMZkJq4/1.jpg"
    }, {
      id: 6,
      url: "https://i.ytimg.com/vi/lFhBNZ6Xm6w/1.jpg"
    }, {
      id: 7,
      url: "https://i.ytimg.com/vi/J_x9uYPlpBk/1.jpg"
    }, {
      id: 8,
      url: "https://i.ytimg.com/vi/r45YhhswIdw/1.jpg"
    }, {
      id: 9,
      url: "https://i.ytimg.com/vi/LwP8G5ot-kg/1.jpg"
    }, {
      id: 10,
      url: "https://i.ytimg.com/vi/jO7ISnME9pg/1.jpg"
    }, {
      id: 11,
      url: "https://i.ytimg.com/vi/VV5lqW4l2yA/1.jpg"
    }, {
      id: 12,
      url: "https://i.ytimg.com/vi/lUHVteb1T5Y/1.jpg"
    }, {
      id: 13,
      url: "https://i.ytimg.com/vi/rzO4evE0zAI/1.jpg"
    }, {
      id: 14,
      url: "https://i.ytimg.com/vi/MG69sFM1UIw/1.jpg"
    }, {
      id: 15,
      url: "https://i.ytimg.com/vi/NfvD5yEFh28/1.jpg"
    }, {
      id: 16,
      url: "https://i.ytimg.com/vi/q6NIWNnvOK0/1.jpg"
    }, {
      id: 17,
      url: "https://i.ytimg.com/vi/FWFUMNXTM0k/1.jpg"
    }, {
      id: 18,
      url: "https://i.ytimg.com/vi/bgyebWOm3fY/1.jpg"
    }, {
      id: 19,
      url: "https://i.ytimg.com/vi/gugu8jehy7A/1.jpg"
    }, {
      id: 20,
      url: "https://i.ytimg.com/vi/eZ95UBnjzig/1.jpg"
    }, {
      id: 21,
      url: "https://i.ytimg.com/vi/ke97mXhVtP4/1.jpg"
    }, {
      id: 22,
      url: "https://i.ytimg.com/vi/tl4JR94ZdCY/1.jpg"
    }, {
      id: 23,
      url: "https://i.ytimg.com/vi/EC2D3dF6UiI/1.jpg"
    }, {
      id: 24,
      url: "https://i.ytimg.com/vi/oS8_7KxNKhU/1.jpg"
    } /*,
      {
       id: 25,
       url: "https://i.ytimg.com/vi/zsaGmHsIGy8/1.jpg"
      },
      {
       id: 26,
       url: "https://i.ytimg.com/vi/l4vErU2pT0U/1.jpg"
      }*/
    ],

    videosSliderOptions: {
      wrapAround: false,
      pageDots: false
    },

    // ui-imagel-upload component settings
    photo_auto_submit: true,
    photo_upload_url: _ember['default'].computed(function () {
      return this.get('config.emaginal.apiServer') + '/media/images/upload/profile/';
    }),
    photo_headers: _ember['default'].computed(function () {
      var user_id = this.get('emagSocial.credentials.user_id'),
          session_key = this.get('emagSocial.credentials.session_key'),
          current_time_utc = new Date().getTime();

      return {
        'X-Auth-Token': session_key,
        'X-User-Id': user_id,
        'X-Current-Time-Utc': current_time_utc
      };
    }),
    photo_formData: _ember['default'].computed(function () {
      return { user: this.get('model.id') };
    }),
    upload_action_text: '',

    addRemoveFromList: function addRemoveFromList(array, $el) {
      this.get('store').findRecord('video', $el.data("video-id")).then(function (video) {
        var existingVideo = array.filterBy('id', video.get('id'))[0];
        if (existingVideo) {
          _ember['default'].$('#' + $el.attr("id") + ' .checkmark').removeClass('selected');
          array.removeObject(video);
        } else {
          _ember['default'].$('#' + $el.attr("id") + ' .checkmark').addClass('selected');
          array.pushObject(video);
        }
      });
      this.send('filtering');
    },

    actions: {
      setAge: function setAge(value) {
        this.set('model.healthData.age', parseInt(value));
      },

      setGender: function setGender(value) {
        this.set('model.healthData.gender', value);
      },

      addToList1SelectedVideos: function addToList1SelectedVideos(cellIndex, listId, cellElement) {
        var array = this.get('userPreference.onboardingList1'),
            $el = _ember['default'].$(cellElement);

        this.addRemoveFromList(array, $el);
      },

      addToList2SelectedVideos: function addToList2SelectedVideos(cellIndex, listId, cellElement) {
        var array = this.get('userPreference.onboardingList2'),
            $el = _ember['default'].$(cellElement);

        this.addRemoveFromList(array, $el);
      },

      selectCharacter: function selectCharacter(character) {
        if (character) {
          this.set('userPreference.character', character);
          this.send('nextScreen', true);
        }
      },

      onUploadSuccess: function onUploadSuccess(files, data, xhr) {
        var flash = _ember['default'].get(this, 'flashMessages');
        this.set('model.photoUrl', data.photoUrl);
        flash.success('Your profile photo changed successfully.');
        _ember['default'].$('.ajax-file-upload-statusbar').hide();
      },

      validateUserInfo: function validateUserInfo() {
        var age = this.get('model.healthData.age');
        var gender = this.get('model.healthData.gender');
        if (!age) {
          _ember['default'].$('#age-dropdown').removeClass('animated shake');
          _ember['default'].run.next(this, function () {
            _ember['default'].$('#age-dropdown').addClass('animated shake');
          });
        }

        if (!gender || gender === 'undefined') {
          _ember['default'].$('#gender-dropdown').removeClass('animated shake');
          _ember['default'].run.next(this, function () {
            _ember['default'].$('#gender-dropdown').addClass('animated shake');
          });
        }

        if (age && gender && gender != 'undefined') {
          this.send('nextScreen', true);
        }
      },

      validateUserVideos: function validateUserVideos() {
        if (this.get('hideNextIfNoSelectedVideos')) {
          _ember['default'].$('#help-text').removeClass('animated shake');
          _ember['default'].run.next(this, function () {
            _ember['default'].$('#help-text').addClass('animated shake');
          });
        } else {
          this.send('nextScreen');
        }
      },

      finishOnboarding: function finishOnboarding() {
        var _this = this;

        var flash = _ember['default'].get(this, 'flashMessages');
        var age = this.get('model.healthData.age');
        this.set('finishIsLoading', true);
        this.send('filtering');
        this.set('model.healthData.age', parseInt(age));
        this.set('model.healthData.height', parseInt(0));
        if (!this.get('model.healthData.gender')) {
          this.set('model.healthData.gender', '');
        }
        this.set('model.fitschoolCompleted', true);

        this.get('userPreference').save().then(function (pref) {
          _this.set('model.userPreference', pref);
          _this.get('model').save().then(function () {
            _this.set('finishIsLoading', false);
            flash.success('We hope you enjoy hoolio!');

            /* Track intercom event */
            _this.get('analytics.intercom').trackEvent('onboarding-completed');
            _this.get('analytics.google').trackEvent('sign', 'onboarded', 'personalize');

            _this.transitionToRoute('library');
          }, function (error) {
            _this.set('finishIsLoading', false);
            flash.success(error);
          });
        }, function (error) {
          _this.set('finishIsLoading', false);
          flash.success(error);
        });
      },

      nextScreen: function nextScreen(animateBg) {
        this.incrementProperty('step');
        if (animateBg) {
          this.send('filtering');
        }
      },

      prevScreen: function prevScreen(animateBg) {
        this.decrementProperty('step');
        if (animateBg) {
          this.send('filtering');
        }
      },

      filtering: function filtering() {
        var photos = this.get("photos");
        photos.forEach(function (e) {
          e._randomPosition = Math.random();
        });
        this.set("photos", photos.sortBy("_randomPosition"));
      }
    }
  });
});