define('hoolio-www/components/hero-fitnessplan/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['hero-fitnessplan-component'],

    style: _ember['default'].computed('attrs.data', function () {
      var _style = '';
      if (this.attrs.data.image) {
        _style += 'background: url("' + this.attrs.data.image + '") no-repeat top center';
      }
      return new _ember['default'].String.htmlSafe(_style);
    }),

    actions: {
      onCta: function onCta(data) {
        if (data.id) {
          if (data.queryParams) {
            this.get('router').transitionTo(data.resource, data.id, { queryParams: data.queryParams });
          } else {
            this.get('router').transitionTo(data.resource, data.id);
          }
        } else {
          if (data.queryParams) {
            this.get('router').transitionTo(data.resource, { queryParams: data.queryParams });
          } else {
            this.get('router').transitionTo(data.resource);
          }
        }
      }
    }
  });
});