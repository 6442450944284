define('hoolio-www/user-fitnessplan/serializer', ['exports', 'hoolio-www/serializers/rest-serializer', 'ember-data'], function (exports, _hoolioWwwSerializersRestSerializer, _emberData) {
  exports['default'] = _hoolioWwwSerializersRestSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      user: { serialize: 'ids', deserialize: 'ids' },
      week1Workouts: { serialize: 'records', deserialize: 'records' },
      week2Workouts: { serialize: 'records', deserialize: 'records' },
      week3Workouts: { serialize: 'records', deserialize: 'records' },
      week4Workouts: { serialize: 'records', deserialize: 'records' },
      week5Workouts: { serialize: 'records', deserialize: 'records' },
      week6Workouts: { serialize: 'records', deserialize: 'records' }
    }
  });
});