define('hoolio-www/components/qr-code/component', ['exports', 'ember'], function (exports, _ember) {
  /* globals QRCode */

  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['style'],
    classNames: ['qrcode'],
    width: 220,
    height: 220,
    qrcode: null,

    style: _ember['default'].computed(function () {
      var _style = 'width:' + (this.attrs.width + 8) + 'px; height:' + (this.attrs.height + 8) + 'px;padding:4px;background-color:#fff;margin: 0 auto;';
      return new _ember['default'].String.htmlSafe(_style);
    }),

    ondidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      if (this.attrs.width) {
        this.set('width', this.attrs.width);
      }

      if (this.attrs.height) {
        this.set('height', this.attrs.height);
      }
    }),

    ondidInsertElement: _ember['default'].on('didInsertElement', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, 'afterRenderEvent');
    }),

    afterRenderEvent: function afterRenderEvent() {
      var _options = {
        width: this.get('width'),
        height: this.get('height'),
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L
      };

      var qrcode = new QRCode(document.getElementById(this.elementId), _options);
      qrcode.makeCode(this.attrs.data);
      this.set('qrcode', qrcode);
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get('qrcode')) {
        this.get('qrcode').clear();
        this.set('qrcode', null);
      }
    }
  });
});