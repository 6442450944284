define('hoolio-www/components/read-more/component', ['exports', 'ember'], function (exports, _ember) {

  /*
  isOpen - The on-load state of the component (default: false)
  maxLines - The maximum visible lines when the component is closed (default: 4)
  lineHeight - Line height in pixels (default: 22)
  toggleClass - The class of the toggle link (default: 'read-more-toggle')
  openText - The open action text of the toggle link (default: 'Read more')
  closeText - The close action text of the toggle link (default: 'Close')
  bodyClass - The class of the content div (default: 'read-more-body')
  bodyCss - The default styles of the content div (default: 'overflow-y: hidden; width: 100%; display: block;')
  */

  exports['default'] = _ember['default'].Component.extend({
    browser: _ember['default'].inject.service(),

    classNames: 'read-more-component',
    classNameBindings: ['isOpen:is-open:is-closed'],

    mobileOnly: false,

    isOpen: false,
    maxLines: '',
    lineHeight: 22,

    toggleClass: '',
    openText: '',
    closeText: '',
    toggleText: _ember['default'].computed('openText', 'closeText', 'isOpen', function () {
      return this.get('isOpen') ? this.get('closeText') : this.get('openText');
    }),

    bodyClass: '',
    bodyCss: '',
    bodyStyle: _ember['default'].computed('isOpen', 'maxLines', 'bodyCss', function () {
      var styles = this.get('bodyCss');
      var maxHeight = this.get('maxLines') * this.get('lineHeight');
      if (!this.get('isOpen')) {
        styles += ' max-height: ' + maxHeight + 'px;';
      }
      return new _ember['default'].String.htmlSafe(styles);
    }),

    onDidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      this.set('mobileOnly', this.attrs.mobileOnly || false);
      this.set('isOpen', this.attrs.isOpen || false);
      this.set('maxLines', this.attrs.maxLines || 4);
      this.set('lineHeight', this.attrs.lineHeight || 22);
      this.set('toggleClass', this.attrs.toggleClass || 'read-more-toggle');
      this.set('openText', this.attrs.openText || 'Read more');
      this.set('closeText', this.attrs.closeText || 'Close');
      this.set('bodyClass', this.attrs.bodyClass || 'read-more-body');
      this.set('bodyCss', this.attrs.bodyCss || '');
    }),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isOpen');

        if (this.get('isOpen')) {
          if (this.attrs.onOpen) {
            this.attrs.onOpen();
          }
        } else {
          if (this.attrs.onOpen) {
            this.attrs.onClose();
          }
        }
      }
    }
  });
});