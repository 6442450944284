define('hoolio-www/components/studio-upnext/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tvService: _ember['default'].inject.service('tv'),

    classNames: ['studio-upnext-component', 'animated'],
    classNameBindings: ['isEnabled::slideOutRight'],
    isEnabled: true,

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      if (this.get('tvService.nextWorkoutVideo')) {
        _ember['default'].run.next(this, function () {
          _ember['default'].$('body').addClass('studio-upnext-open');
        });
      }
    }),

    mouseEnter: function mouseEnter() {
      this.set('isHovered', true);
    },

    mouseLeave: function mouseLeave() {
      this.set('isHovered', false);
    },

    _onDestroyElement: _ember['default'].on('willDestroyElement', function () {
      _ember['default'].$('body').removeClass('studio-upnext-open');
    }),

    actions: {
      closeTvPreview: function closeTvPreview() {
        this.toggleProperty('isEnabled');
        _ember['default'].$('body').removeClass('studio-upnext-open');
      }
    }
  });
});