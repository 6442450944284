define('hoolio-www/studio/controller', ['exports', 'ember'], function (exports, _ember) {
  /* globals moment */

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    tvService: _ember['default'].inject.service('tv'),
    opentok: _ember['default'].inject.service(),
    browser: _ember['default'].inject.service(),
    resizeService: _ember['default'].inject.service('resize'),
    pubnubService: _ember['default'].inject.service('pubnub'),
    bot: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),

    viewLayout: 'horizontal-layout',

    isChatOpen: false,
    isGuideOpen: false,
    channelLoaded: false,

    showElement: _ember['default'].computed.or('isMouseMoved', 'playerStopped'),

    onInit: _ember['default'].on('init', function () {
      var _this = this;

      //this.checkWindowRatio();
      this.get('resizeService').on('debouncedDidResize', function (event) {
        _this.checkWindowRatio();
      });
    }),

    onSchedule: _ember['default'].on('init', _ember['default'].observer('model.weeklySchedule.dailySchedules.[]', function () {
      if (this.get('model.weeklySchedule.dailySchedules.length')) {
        this.set('tvService.weeklySchedule', this.get('model.weeklySchedule.dailySchedules'));
        this.get('tvService').setChannel(this.get('model.weeklySchedule.dailySchedules')[moment().isoWeekday() - 1]);
      }
    })),

    backgroundPhotoUrl: _ember['default'].computed('tvService.activeVideo', function () {
      return this.get('tvService.activeVideo.thumbnail');
    }),

    checkWindowRatio: function checkWindowRatio() {
      if (window.innerWidth / window.innerHeight < 1.5) {
        this.set('viewLayout', 'vertical-layout');
      } else {
        this.set('viewLayout', 'horizontal-layout');
      }
    },

    studioChannel: _ember['default'].computed('channel', function () {
      return 'studio-' + this.get('tvService.coreInfo.id') + '-' + this.get('config.environment');
    }),

    chatChannel: _ember['default'].computed('channel', function () {
      return "chat-" + this.get('tvService.coreInfo.id') + '-' + this.get('config.environment');
    }),

    actions: {
      goBack: function goBack() {
        this.send('gotoPrevRoute');
      },

      likeUserStatus: function likeUserStatus(user) {
        var chatPb = this.get('pubnubService').getInstance('chatPb');
        var msg = this.get('session.currentUser.firstName') + " likes " + user + " status.";
        if (chatPb.isConnectedToChannel(this.get('chatChannel'))) {
          this.set('bot.botUserData.photo', this.get('session.currentUser.photoUrl'));
          this.get('bot').publish(chatPb, this.get('chatChannel'), msg);
        }
      },

      enableCamera: function enableCamera() {
        this.get('opentok').connectPublisher();

        var studioPb = this.get('pubnubService').getInstance('studioSyncPb');
        studioPb.set('userState.cameraOn', true);
        studioPb.setState([this.get('studioChannel')]);

        var chatPb = this.get('pubnubService').getInstance('chatPb');
        var msg = this.get('session.currentUser.firstName') + " enabled camera";
        if (chatPb.isConnectedToChannel(this.get('chatChannel'))) {
          this.set('bot.botUserData.photo', this.get('session.currentUser.photoUrl'));
          this.get('bot').publish(chatPb, this.get('chatChannel'), msg);
        }
      },

      disableCamera: function disableCamera() {
        this.get('opentok').disconnectPublisher();

        var studioPb = this.get('pubnubService').getInstance('studioSyncPb');
        studioPb.set('userState.cameraOn', false);
        studioPb.setState([this.get('studioChannel')]);

        var chatPb = this.get('pubnubService').getInstance('chatPb');
        var msg = this.get('session.currentUser.firstName') + " disabled camera";
        if (chatPb.isConnectedToChannel(this.get('chatChannel'))) {
          this.set('bot.botUserData.photo', this.get('session.currentUser.photoUrl'));
          this.get('bot').publish(chatPb, this.get('chatChannel'), msg);
        }
      },

      toggleViewLayout: function toggleViewLayout() {
        if (this.get('viewLayout') === 'vertical-layout') {
          this.set('viewLayout', 'horizontal-layout');
        } else {
          this.set('viewLayout', 'vertical-layout');
        }
      },

      toggleFullscreen: function toggleFullscreen() {
        this.fullscreen.toggle();
      },

      toggleChat: function toggleChat() {
        this.toggleProperty('isChatOpen');
      },

      toggleGuide: function toggleGuide() {
        this.toggleProperty('isGuideOpen');
      },

      onUnreadedMessage: function onUnreadedMessage(counter) {
        this.set('unreadedMessages', counter);
      }
    }
  });
});