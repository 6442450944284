define('hoolio-www/authenticators/facebook', ['exports', 'ember-simple-auth/authenticators/base', 'ember'], function (exports, _emberSimpleAuthAuthenticatorsBase, _ember) {
  /* global FB */

  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    emagSocial: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),

    restore: function restore(data) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!_ember['default'].isEmpty(data) && !_this.get('emagSocial.credentials.user_id')) {
          _this.get('emagSocial').setCredentials({ user_id: data.user_id, user_email: data.email, session_key: data.session_key });
          _this.get('emagSocial').validate(function (response) {
            _this.get('emag').log('[Hoolio] Successful Auto login.');
            resolve(data);
          }, function (error) {
            reject('[Hoolio] Auto login failed.');
          });
        } else {
          //reject('[Hoolio] Auto login failed.');
        }
      });
    },

    authenticate: function authenticate() {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var that = _this2;
        FB.getLoginStatus(function (fbResponse) {
          if (fbResponse.status === 'connected') {
            _ember['default'].run(function () {
              FB.api("/me", function (response) {
                if (response && !response.error) {
                  that.get('emagSocial').socialLogin({ access_token: fbResponse.authResponse.accessToken }, function (response) {
                    resolve(response);
                  }, function (error) {
                    reject(error);
                  });
                } else if (response.error) {
                  reject(response.error);
                }
              });
            });
          } else {
            try {
              FB.login(function (fbResponse) {
                if (fbResponse.authResponse) {
                  _ember['default'].run(function () {
                    FB.api("/me", function (response) {
                      if (response && !response.error) {
                        that.get('emag').log(response);
                        that.get('emagSocial').socialLogin({ access_token: fbResponse.authResponse.accessToken }, function (response) {
                          resolve(response);
                        }, function (error) {
                          reject(error);
                        });
                      }
                    });
                  });
                } else {
                  reject(fbResponse.error);
                }
              }, { scope: 'email,public_profile' });
            } catch (err) {
              reject("Ooops. Something went wrong.");
            }
          }
        });
      });
    },

    invalidate: function invalidate() {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var that = _this3;
        _this3.get('emagSocial').logout();
        FB.getLoginStatus(function (fbResponse) {
          if (fbResponse.session) {
            FB.logout(function (response) {
              that.get('emag').log('[Hoolio] Social logout.');
            });
          }
        });
        resolve();
      });
    }
  });
});