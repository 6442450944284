define('hoolio-www/components/studio-guide/component', ['exports', 'ember'], function (exports, _ember) {
  /*globals moment*/

  exports['default'] = _ember['default'].Component.extend({
    tvService: _ember['default'].inject.service('tv'),

    classNames: ['studio-guide-component', 'animated', 'fadeIn'],

    isGuideOpen: false,
    utcMode: _ember['default'].computed.alias('tvService.utcMode'),
    selectedDayIndex: 0,
    weekDays: _ember['default'].A([{
      name: 'Mon',
      date: moment().startOf('isoWeek').format("D/M")
    }, {
      name: 'Tue',
      date: moment().startOf('isoWeek').add(1, 'day').format("D/M")
    }, {
      name: 'Wed',
      date: moment().startOf('isoWeek').add(2, 'day').format("D/M")
    }, {
      name: 'Thu',
      date: moment().startOf('isoWeek').add(3, 'day').format("D/M")
    }, {
      name: 'Fri',
      date: moment().startOf('isoWeek').add(4, 'day').format("D/M")
    }, {
      name: 'Sat',
      date: moment().startOf('isoWeek').add(5, 'day').format("D/M")
    }, {
      name: 'Sun',
      date: moment().startOf('isoWeek').add(6, 'day').format("D/M")
    }]),
    setToday: _ember['default'].on('init', _ember['default'].observer('weekDays', function () {
      _ember['default'].set(this.get('weekDays').objectAt(moment().weekday() - 1), 'name', 'Today');
    })),

    onInit: _ember['default'].on('init', function () {
      this.set('selectedDayIndex', moment().weekday() - 1);
      this.get('tvService').resetGuideChannelToToday();
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      _ember['default'].run.next(this, function () {
        var id = this.get('tvService.activeVideo') ? 'collapse-' + this.get('tvService.activeVideo.subZoneId') : 'collapse-' + this.get('tvService.activeAd.subZoneId');
        this.send('setActivePanel', 'collapse-' + this.get('tvService.activeVideo.subZoneId'));
      });
    }),

    onChannel: _ember['default'].on('init', _ember['default'].observer('tvService.guideChannel', 'utcMode', 'selectedDayIndex', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (this.get('tvService.guideChannel')) {
          this.setChannel();

          this.$('.panel-group').removeClass('hidden');
        }
      });
    })),

    setChannel: function setChannel() {
      var _this = this;

      this.get('tvService.guideChannel').zones.forEach(function (zone, index1) {
        zone.subZones.forEach(function (subZone, index2) {
          var _prevDuration = 0;
          var _adsDuration = 0;
          subZone.videos.forEach(function (video, index3) {
            if (!video.guide) {
              _adsDuration = _adsDuration + video.duration;
            } else {
              var $el = _this.$('.zone-' + index1 + '.subZone-' + index2 + ' .video-' + index3);
              if ($el) {
                var videoStartTime = _prevDuration - _adsDuration;

                var _startTime = _this.get('utcMode') ? _this.get('tvService').resetDateToNow(subZone.startTime).add(Number(moment().utcOffset()), 'm') : _this.get('tvService').resetDateToNow(subZone.startTime);
                _ember['default'].set(video, 'startTime', _startTime.add(videoStartTime, 'seconds').format("HH:mm"));
                $el.find('.start-time small.extra').html("<i class='fa fa-flag-checkered' aria-hidden='true'></i> " + video.startTime + "&nbsp;&nbsp;&nbsp;" + "<i class='fa fa-clock-o' aria-hidden='true'></i> " + Math.round(video.duration / 60) + "'");
              }
              _adsDuration = 0;
            }
            _prevDuration = _prevDuration + video.duration;
          });
        });
      });
    },

    onWillDestroyElement: _ember['default'].on('willDestroyElement', function () {}),

    actions: {
      toggleGuide: function toggleGuide() {
        this.toggleProperty('isGuideOpen');
      },

      setActivePanel: function setActivePanel(id) {
        this.set('activePanel', id);
        _ember['default'].run.next(this, function () {
          var $el = this.$('#' + id + ' .media.now-playing').length ? this.$('#' + id + ' .media.now-playing') : this.$('#' + id);
          this.$().animate({
            scrollTop: $el.offset().top - 50 + 'px'
          });
        });
      },

      changeChannel: function changeChannel(dayIndex) {
        this.set('selectedDayIndex', dayIndex);
        if (dayIndex === moment().weekday() - 1) {
          this.get('tvService').resetGuideChannelToToday();
        } else {
          this.get('tvService').setGuideChannel(this.get('tvService.weeklySchedule')[dayIndex]);
          _ember['default'].run.next(this, function () {
            this.$('.panel-collapse.collapse').removeClass('in');
          });
        }
      }
    }
  });
});