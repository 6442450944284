define('hoolio-www/user/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    flashMessages: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    emagSocial: _ember['default'].inject.service(),
    emagNet: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),

    isEditing: false,
    healthData: null,

    ageOptions: [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99],

    genderOptions: ['male', 'female'],

    hasDefaultPhoto: _ember['default'].computed('model.photoUrl', function () {
      var photo = this.get('model.photoUrl');
      return photo && photo.indexOf('assets/img/general/default') > -1 ? true : false;
    }),

    // ui-imagel-upload component settings
    photo_auto_submit: true,
    photo_upload_url: _ember['default'].computed(function () {
      return this.get('config.emaginal.apiServer') + '/media/images/upload/profile/';
    }),
    photo_headers: _ember['default'].computed(function () {
      var user_id = this.get('emagSocial.credentials.user_id'),
          session_key = this.get('emagSocial.credentials.session_key'),
          current_time_utc = new Date().getTime();

      return {
        'X-Auth-Token': session_key,
        'X-User-Id': user_id,
        'X-Current-Time-Utc': current_time_utc
      };
    }),
    photo_formData: _ember['default'].computed(function () {
      return { user: this.get('model.id') };
    }),
    upload_action_text: '',

    actions: {
      setAge: function setAge(value) {
        this.set('model.healthData.age', parseInt(value));
      },

      setGender: function setGender(value) {
        this.set('model.healthData.gender', value);
      },

      onHistoryClick: function onHistoryClick() {
        var _this = this;

        this.store.query('user-subscription', { history: true }).then(function (history) {
          _this.set('userSubscriptions', history);
        });
      },

      saveProfileChanges: function saveProfileChanges() {
        var _this2 = this;

        var flash = _ember['default'].get(this, 'flashMessages');
        //const age = this.get('model.healthData.age');
        //this.set('model.healthData.age', parseInt(age));
        this.set('model.healthData.height', 0);
        this.get('model').save().then(function () {
          flash.success('Your changes has been saved.');
          _this2.set('isEditing', false);
        });
      },

      showPasswordModal: function showPasswordModal() {
        this.send('showModal', 'password-modal', {
          title: 'Change password',
          okText: '',
          closeText: ''
        });
      },

      onUploadSuccess: function onUploadSuccess(files, data, xhr) {
        var flash = _ember['default'].get(this, 'flashMessages');
        this.set('model.photoUrl', data.photoUrl);
        flash.success('Your profile photo changed successfully.');
        _ember['default'].$('.ajax-file-upload-statusbar').hide();
      },

      resendActivationEmail: function resendActivationEmail() {
        var flash = _ember['default'].get(this, 'flashMessages');
        this.get('emagSocial').resendActivationEmail(function (response) {
          flash.success('A verification link has been send to your email, in order to activate your account.', { timeout: 10000 });
        }, function (error) {
          flash.danger(error);
        });
      },

      cancelSubscriptionPlan: function cancelSubscriptionPlan() {
        var _this3 = this;

        this.send('showModal', 'general-warning-modal', {
          title: 'Cancel Subscription Warning',
          body: "<ul><li>- You will immediately lose access to hoolio.com <b>PREMIUM</b> features, including premium workouts, premium fitness plans and wearable integration</li></ul><br/><strong>Are you sure you want to cancel your subscription?</strong>",
          okText: "Yes, i'm sure",
          closeText: 'Cancel',
          confirm_cb: function confirm_cb() {
            var flash = _ember['default'].get(_this3, 'flashMessages');
            var _url = '/userSubscriptions/' + _this3.get('model.activePremiumPlan.id');
            var _planName = _this3.get('model.activePremiumPlan.subscriptionPlan.name').toLowerCase();
            _this3.get('emagNet').deleteUrl(_url, function (plan) {
              flash.success('Your subscription canceled successfully.');
              _this3.send('removeModal');
              _this3.get('model').reload();

              /* Track intercom event */
              var metadata = {
                plan_name: _planName
              };
              _this3.get('analytics.intercom').trackEvent('plan-canceled', metadata);
              _this3.get('analytics.google').trackEvent('churn', 'canceled_' + _planName, 'churn');
            }, function (error) {
              _this3.get('emag').error(error);
              flash.danger('Something went wrong. Please try again.');
              _this3.send('removeModal');
            });
          }
        });
      },

      deleteAccount: function deleteAccount() {
        var _this4 = this;

        this.send('showModal', 'general-warning-modal', {
          title: 'Delete Account Warning',
          body: "<ul><li>- You will immediately lose access to hoolio.com platform, including your workout activity, transaction history, and all other information associated with your account.</li><li>- Your payment information will be removed.</li><li>- This cannot be undone.</li></ul><br/><strong>Are you sure you want to delete your account?</strong>",
          okText: "Yes, i'm sure",
          closeText: 'Cancel',
          confirm_cb: function confirm_cb() {
            _this4.get('model').destroyRecord().then(function () {
              window.location.replace('/');
            });
          }
        });
      }
    }
  });
});