define("hoolio-www/helpers/parse-unix-time", ["exports", "ember"], function (exports, _ember) {
  exports.parseUnixTime = parseUnixTime;

  function parseUnixTime(params) {
    if (params[1]) {
      // format
      return moment.unix(params[0] / 10000000).format(params[1]);
    } else {
      return moment.unix(params[0] / 10000000).format("HH:mm");
    }
  }

  exports["default"] = _ember["default"].Helper.helper(parseUnixTime);
});