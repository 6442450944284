define("hoolio-www/utils/getFromString", ["exports", "ember"], function (exports, _ember) {

  var getFromString = function getFromString(string, key, separator) {
    separator = separator || ",";
    string = string || "";

    var value = string.split(key);
    if (value) {
      if (value.length === 2) {
        return value[1].split(separator)[0];
      } else if (value.length > 2) {
        var values = [];
        for (var i = 1; i < value.length; i++) {
          values.push(value[i].split(separator)[0]);
        }
        return values;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  exports["default"] = getFromString;
});