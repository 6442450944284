define('hoolio-www/components/tv-guide/component', ['exports', 'ember'], function (exports, _ember) {
  /*globals moment*/

  exports['default'] = _ember['default'].Component.extend({
    tvService: _ember['default'].inject.service('tv'),

    classNames: ['tv-guide-component'],

    isOpen: false,
    timeElementWidth: 600,
    utcMode: _ember['default'].computed.alias('tvService.utcMode'),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {}),

    onChannel: _ember['default'].on('init', _ember['default'].observer('tvService.channel', 'utcMode', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (this.get('tvService.channel')) {
          var $main = this.$('#main');
          this.setCurrentTimeIndicator($main);
          this.setChannel();

          this.set('selectedVideo', this.get('tvService.activeVideo'));

          // Show minutes for debugging
          /*const min = this.$('.minutes');
          let j = 0;
          for (var i = 0; i < (24*60); i++) {
            min.append( "<div class='min'>"+j+"</div>" );
            if(j===59) {
              j = 0;
            } else {
              j++;
            }
          }*/

          var _$main = document.getElementById("main");
          _$main.addEventListener('mousewheel', this.mouseWheelEvent);
          _$main.addEventListener('DOMMouseScroll', this.mouseWheelEvent);
        }
      });
    })),

    mouseWheelEvent: function mouseWheelEvent(e) {
      e = window.event || e;
      var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      var _$main = document.getElementById("main");
      _$main.scrollLeft -= delta * 40;
      e.preventDefault();
    },

    setChannel: function setChannel() {
      var _this = this;

      this.get('tvService.channel').zones.forEach(function (zone, index1) {
        zone.subZones.forEach(function (subZone, index2) {
          var _prevDuration = 0;
          var _adsDuration = 0;
          subZone.videos.forEach(function (video, index3) {
            if (!video.guide) {
              _adsDuration = _adsDuration + video.duration;
            } else {
              var $el = _this.$('.zone-' + index1 + ' .subZone-' + index2 + ' .video-' + index3);
              var videoStartTime = _prevDuration - _adsDuration;
              $el.css({
                left: _this.getLeftOffset(subZone.startTime, videoStartTime),
                width: _this.timeElementWidth * (Math.round((video.duration + _adsDuration) / 60 * 100) / 100) / 60
              });

              var _startTime = _this.get('utcMode') ? _this.get('tvService').resetDateToNow(subZone.startTime).add(Number(moment().utcOffset()), 'm') : _this.get('tvService').resetDateToNow(subZone.startTime);
              $el.find('.start-time small').html("<i class='fa fa-flag-checkered' aria-hidden='true'></i> " + _startTime.add(videoStartTime, 'seconds').format("HH:mm") + "&nbsp;&nbsp;&nbsp;" + "<i class='fa fa-clock-o' aria-hidden='true'></i> " + Math.round(video.duration / 60) + "'");
              //  + " - " + _startTime.add(videoStartTime, 'seconds').add(Math.round(video.duration+_adsDuration), 'seconds').format("HH:mm")
              _adsDuration = 0;
            }
            _prevDuration = _prevDuration + video.duration;
          });
        });
      });
    },

    getLeftOffset: function getLeftOffset(time, extraOffsetInSeconds) {
      extraOffsetInSeconds = extraOffsetInSeconds || 0;
      var extraOffsetInMinutes = extraOffsetInSeconds ? Math.round(extraOffsetInSeconds / 60 * 100) / 100 : 0;
      var _time = undefined;
      if (time) {
        _time = this.get('tvService').resetDateToNow(time);
      } else {
        _time = this.get('utcMode') ? moment.utc() : moment();
      }
      //const _time = time ? moment(time) : moment();
      var totalMinutes = _time.minutes() + extraOffsetInMinutes;
      var hours = _time.hour() + Math.floor(totalMinutes / 60);
      var minutes = Math.round(totalMinutes % 60 * 100) / 100;
      var $time = this.$('.times #t' + hours);
      var hourOffset = $time.offset().left - $time.parent().offset().left;
      var minOffset = this.timeElementWidth * minutes / 60;
      return hourOffset + minOffset;
    },

    currentTimeMnutes: 0,
    setCurrentTimeIndicator: function setCurrentTimeIndicator($main) {
      var _this2 = this;

      this.timer = setInterval(function () {
        _this2.set('currentTimeMnutes', moment().format("mm'"));
        _this2.positionCurrentTimeIndicator($main);
      }, 60000);
      this.set('currentTimeMnutes', moment().format("mm'"));
      this.scrollToCurrentVideo();
    },

    positionCurrentTimeIndicator: function positionCurrentTimeIndicator($main) {
      var _offset = this.getLeftOffset();
      $main.find('.current-time-indicator').css('left', _offset);
    },

    scrollToCurrentVideo: function scrollToCurrentVideo() {
      var $main = this.$('#main');
      var _offset = this.getLeftOffset();
      $main.find('.current-time-indicator').css('left', _offset);
      //$main.scrollLeft(_offset - (this.$('#main').width()/2));
      this.$('#main').stop().animate({
        scrollLeft: _offset - this.$('#main').width() / 2
      }, 250);
    },

    onDestroyElement: _ember['default'].on('willDestroyElement', function () {
      clearInterval(this.timer);
      this.timer = null;
      var _$main = document.getElementById("main");
      _$main.removeEventListener('mousewheel', this.mouseWheelEvent);
      _$main.removeEventListener('DOMMouseScroll', this.mouseWheelEvent);
      this.set('isOpen', false);
    }),

    actions: {
      onOpenClose: function onOpenClose() {
        this.toggleProperty('isOpen');

        if (!this.get('isOpen')) {
          //this.set('selectedVideo', null);
        } else {
            this.scrollToCurrentVideo();
          }
      },

      onVideoHover: function onVideoHover(video) {
        this.set('selectedVideo', video);
      }
    }
  });
});