define('hoolio-www/components/background-image-effect/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    browser: _ember['default'].inject.service(),

    classNames: ['background-image-effect-component'],

    onDidReceiveAttrs: _ember['default'].on('didReceiveAttrs', _ember['default'].observer('this.attrs.photoUrl', function () {
      var _image = this.attrs.photoUrl;
      var _blur = this.attrs.blur || "30px";
      if (!_ember['default'].$.browser.msie && !_ember['default'].$.browser.edge && !this.get('browser.isMobile')) {
        if (_image && _image.indexOf('assets/img/general/default') > -1) {
          return;
        } else if (this.attrs.blur != false) {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {

            _ember['default'].$('head style#' + this.elementId).remove();
            _ember['default'].$('<style id="' + this.elementId + '" type="text/css">#' + this.elementId + '.background-image-effect-component:before{background: url(' + _image + ');filter: blur(' + _blur + ');-webkit-filter: blur(' + _blur + ');-moz-filter: blur(' + _blur + ');-o-filter: blur(' + _blur + ');-ms-filter: blur(' + _blur + ');}</style>').appendTo('head');
          });
        } else if (this.attrs.blur === false) {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            _ember['default'].$('head style#' + this.elementId).remove();
            _ember['default'].$('<style id="' + this.elementId + '" type="text/css">#' + this.elementId + '.background-image-effect-component:before{background: url(' + _image + ');</style>').appendTo('head');
          });
        }
      } else {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          _ember['default'].$('head style#' + this.elementId).remove();
          _ember['default'].$('<style id="' + this.elementId + '" type="text/css">#' + this.elementId + '.background-image-effect-component:before{background-color: #292929;}</style>').appendTo('head');
        });
      }
    })),

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      _ember['default'].$('head style#' + this.elementId).remove();
    })
  });
});