define('hoolio-www/tv/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    videoPlayerService: _ember['default'].inject.service('video-player'),
    resizeService: _ember['default'].inject.service('resize'),
    tvService: _ember['default'].inject.service('tv'),

    onInit: _ember['default'].on('init', function () {
      var _this = this;

      //this.get('videoPlayerService').on( 'player-ended', this, this.playerEnded);

      this.get('resizeService').on('didResize', function (event) {
        _this.send('onPlayerReadyEvent');
      });
    }),

    actions: {
      onPlayerReadyEvent: function onPlayerReadyEvent() {
        /*Ember.run.scheduleOnce('afterRender', this, function(){
          if(window.emberYouTubePlayer) {
            window.emberYouTubePlayer.setSize(window.innerWidth, window.innerHeight - 52);
            window.emberYouTubePlayer.css({
              'margin-top': 52
            });
          }
        });*/
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          var $player = _ember['default'].$('.youtube-player');
          if ($player.length) {
            $player.css('height', 'auto');
            $player.css('max-width', '100%');
            $player.css('max-height', window.innerHeight - 50);
            var value = window.innerHeight - window.innerWidth;
            if (value > 0) {
              $player.css('max-width', '100%');
            } else {
              $player.css('max-width', (window.innerHeight - 50) * 1.7777);
            }
            $player.css({
              'margin-top': (window.innerHeight - 50 - $player.height()) / 2,
              'margin-left': 'auto',
              'margin-right': 'auto'
            });
          }
        });
      },

      toggleFullscreen: function toggleFullscreen() {
        this.fullscreen.toggle();
      }
    }
  });
});