define('hoolio-www/video-metadata/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    aim: _emberData['default'].attr('array'),
    muscleOrBodyArea: _emberData['default'].attr('array'),
    targetGroup: _emberData['default'].attr('array'),
    level: _emberData['default'].attr('string'),
    mood: _emberData['default'].attr('array'),
    equipment: _emberData['default'].attr('array'),
    specialization: _emberData['default'].attr('string'),
    source: _emberData['default'].attr('string'),
    youtubeUrl: _emberData['default'].attr('string'),
    youtubeDuration: _emberData['default'].attr('number'),
    youtubeThumbnail: _emberData['default'].attr('string'),
    moves: _emberData['default'].attr('number'),
    views: _emberData['default'].attr('number'),

    /*
    *  Relationships
    * 
    */
    relatedVideos: _emberData['default'].hasMany('video', { inverse: null }),

    /*
    *  Computed attribures
    * 
    */
    isFromYoutube: _ember['default'].computed('source', function () {
      return this.get('source') === 'YouTube';
    }),

    isFromKaltura: _ember['default'].computed('source', function () {
      return this.get('source') === 'Kaltura';
    }),

    isThumbHd: _ember['default'].computed('youtubeThumbnail', function () {
      var thumb = this.get('youtubeThumbnail');
      if (thumb) {
        return this.get('youtubeThumbnail').indexOf("maxresdefault") > -1;
      } else {
        return false;
      }
    }),

    youtubeId: _ember['default'].computed('youtubeUrl', function () {
      var url = this.get('youtubeUrl');
      if (url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[7].length == 11) {
          return match[7];
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),

    youtubeThumbnailSmall: _ember['default'].computed('source', 'youtubeUrl', function () {
      if (this.get('source') === 'YouTube') {
        if (typeof this.get('youtubeUrl') !== 'undefined' && this.get('youtubeUrl') !== '') {
          var regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
          var video_id = this.get('youtubeUrl').match(regExp);
          if (video_id !== null) {
            return 'https://i.ytimg.com/vi/' + video_id[2] + '/0.jpg';
          } else {
            return 'https://i.ytimg.com/vi/test/0.jpg';
          }
        } else {
          return 'https://i.ytimg.com/vi/test/1.jpg';
        }
      } else {
        return '';
      }
    })
  });
});