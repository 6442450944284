define('hoolio-www/password/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('session').get('isAuthenticated')) {
        this.transitionTo('library');
      } else {
        return this._super.apply(this, arguments);
      }
    },

    afterModel: function afterModel(model, transition) {
      _ember['default'].$(document).attr('title', 'hoolio - Reset password');

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});