define('hoolio-www/components/spinning-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'button',
    classNames: ['spinning-button-component', 'btn'],
    attributeBindings: ['isSpinning:disabled', 'type'],

    isSpinning: false,
    type: '',

    onDidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      this.set('isSpinning', this.attrs.isSpinning);
      this.set('type', this.attrs.type);
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      this.$().css('position', 'relative');
    }),

    maintainButtonDimensions: _ember['default'].on('didInsertElement', _ember['default'].observer('attrs.isSpinning', function () {
      if (this.attrs.isSpinning) {
        this.$().css({
          'width': this.$().outerWidth() + 'px',
          'height': this.$().outerHeight() + 'px'
        });
      } else {
        this.$().css({
          'width': '',
          'height': ''
        });
      }
    })),

    click: function click() {
      if (!this.attrs.isSpinning && this.attrs.action) {
        this.attrs.action();
      }
    }
  });
});