define('hoolio-www/trainer/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    name: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    isFeatured: _emberData['default'].attr('boolean'),
    pinterestURL: _emberData['default'].attr('string'),
    instagramURL: _emberData['default'].attr('string'),
    twitterURL: _emberData['default'].attr('string'),
    facebookURL: _emberData['default'].attr('string'),
    webURL: _emberData['default'].attr('string'),
    youTubePromoVideo: _emberData['default'].attr('string'),
    aboutHoolio: _emberData['default'].attr('string'),
    moto: _emberData['default'].attr('string'),
    expertise: _emberData['default'].attr('array'),
    performance: _emberData['default'].attr('object'),
    promoVideos: _emberData['default'].attr('array'),
    feedName: _emberData['default'].attr('string'),

    /*
    *  Relationships
    * 
    */
    videos: _emberData['default'].hasMany('video'),
    promoImages: _emberData['default'].hasMany('image'),
    profileImage: _emberData['default'].belongsTo('image'),
    backgroundImage: _emberData['default'].belongsTo('image'),

    /*
    *  Computed properties
    * 
    */
    firstName: _ember['default'].computed('name', function () {
      return this.get('name').split(' ')[0];
    }),

    youtubePromoId: _ember['default'].computed('youTubePromoVideo', function () {
      var url = this.get('youTubePromoVideo');
      if (url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[7].length == 11) {
          return match[7];
        } else {
          return null;
        }
      } else {
        return null;
      }
    })
  });
});