define('hoolio-www/components/week-range/component', ['exports', 'ember'], function (exports, _ember) {
  /* globals moment*/

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['week-range-component'],

    onInit: _ember['default'].on('didReceiveAttrs', function () {
      var _startingWeek = this.attrs.startingWeek;
      var _endingWeek = this.attrs.endingWeek;
      this.setCurrentWeek(_startingWeek ? _startingWeek : moment().startOf('isoWeek'), _endingWeek ? _endingWeek : moment().endOf('isoWeek'));
    }),

    setCurrentWeek: function setCurrentWeek(start, end) {
      this.set('startingWeek', start);
      this.set('endingWeek', end);
      this.set('readonly', this.attrs.readonly ? true : false);

      if (this.attrs.lockFuture) {
        this.set('lockNextWeek', this.attrs.lockFuture);
      }

      if (this.attrs.onDateChange) {
        this.attrs.onDateChange(this.get('startingWeek'), this.get('endingWeek'));
      }
    },

    parsedStartingWeek: _ember['default'].computed('startingWeek', function () {
      return this.get('startingWeek').format("DD");
    }),

    parsedEndingWeek: _ember['default'].computed('endingWeek', function () {
      return this.get('endingWeek').format("DD MMM");
    }),

    actions: {
      nextWeek: function nextWeek() {
        this.set('startingWeek', moment(this.get('startingWeek')).add(1, 'weeks').startOf('isoWeek'));
        this.set('endingWeek', moment(this.get('endingWeek')).add(1, 'weeks').endOf('isoWeek'));

        if (this.attrs.onDateChange) {
          this.attrs.onDateChange(this.get('startingWeek'), this.get('endingWeek'));
        }

        if (this.attrs.lockFuture && moment(moment()).isBefore(this.get('endingWeek'))) {
          this.set('lockNextWeek', true);
        } else {
          this.set('lockNextWeek', false);
        }
      },

      prevWeek: function prevWeek() {
        if (this.attrs.lockFuture && this.get('lockNextWeek') && moment(moment()).isAfter(this.get('startingWeek'))) {
          this.set('lockNextWeek', false);
        }

        this.set('startingWeek', moment(this.get('startingWeek')).subtract(1, 'weeks').startOf('isoWeek'));
        this.set('endingWeek', moment(this.get('endingWeek')).subtract(1, 'weeks').endOf('isoWeek'));

        if (this.attrs.onDateChange) {
          this.attrs.onDateChange(this.get('startingWeek'), this.get('endingWeek'));
        }
      },

      resetWeek: function resetWeek() {
        this.setCurrentWeek(moment().startOf('isoWeek'), moment().endOf('isoWeek'));
      }
    }
  });
});