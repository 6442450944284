define('hoolio-www/components/tile-library/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['tile-library-component', 'tile', 'animated', 'fadeIn'],
    classNameBindings: ['tile.classNames'],

    style: _ember['default'].computed('', function () {
      var _style = 'background: url("./assets/img/dashboard/tiles/library.jpg");'; //linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ),
      return new _ember['default'].String.htmlSafe(_style);
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {}),

    click: function click() {
      this.get('router').transitionTo('library');
    }
  });
});