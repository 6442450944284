define('hoolio-www/activate/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    emagSocial: _ember['default'].inject.service(),

    queryParams: ['token'],
    token: "",

    isLoading: true,
    isSuccessful: false,

    onToken: _ember['default'].on('init', _ember['default'].observer('token', function () {
      var _this = this;

      var _token = this.get('token');
      if (_token) {
        this.get('emagSocial').activateUser({ token: _token }, function (response) {
          _this.set('isLoading', false);
          _this.set('isSuccessful', true);
          _ember['default'].run.later(_this, function () {
            this.transitionToRoute('library');
          }, 3000);
        }, function (error) {
          _this.set('isLoading', false);
          _this.set('isSuccessful', false);
        });
      }
    }))
  });
});