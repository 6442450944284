define('hoolio-www/playlist/model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    name: _emberData['default'].attr('string'),

    /*
    *  Relationships
    * 
    */
    videos: _emberData['default'].hasMany('video')
  });
});