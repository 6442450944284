define('hoolio-www/workout/model', ['exports', 'ember-data', 'ember', 'hoolio-www/config/environment'], function (exports, _emberData, _ember, _hoolioWwwConfigEnvironment) {
  exports['default'] = _emberData['default'].Model.extend({
    _session: _ember['default'].inject.service('session'),
    /*
    *  Basic attribures
    * 
    */
    scheduled: _emberData['default'].attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    created: _emberData['default'].attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    started: _emberData['default'].attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    status: _emberData['default'].attr('string', { defaultValue: 'incomplete' }),
    premium: _emberData['default'].attr('boolean', { defaultValue: false }),
    maxParticipants: _emberData['default'].attr('number', { defaultValue: 3 }),
    openTokSession: _emberData['default'].attr('string', { defaultValue: '' }),
    secondsOnLive: _emberData['default'].attr('number', { defaultValue: 0 }), // Remove on serialize

    /*
    *  Relationships
    * 
    */
    userFitnessplan: _emberData['default'].belongsTo('user-fitnessplan', { inverse: null }),
    user: _emberData['default'].belongsTo('user', { inverse: null }),
    //participants: DS.hasMany('user', {inverse: null}),
    video: _emberData['default'].belongsTo('video'),

    /*
    *  Computed attribures
    * 
    */
    workoutUrl: _ember['default'].computed('id', function () {
      return location.origin + '/workouts/' + this.get('id');
    }),

    isCompleted: _ember['default'].computed.and('isUserEnoughTimeInside', 'video.isCompleted'),

    secondsOnLivePrercentage: _ember['default'].computed('secondsOnLive', 'video.duration', 'video.isFromYoutube', function () {
      var _time = this.get('secondsOnLive');
      var _duration = this.get('video.isFromYoutube') ? this.get('video.youtubeDuration') : this.get('video.duration');

      if (_time && _duration) {
        return Math.ceil(_time / _duration * 100);
      } else {
        return 0;
      }
    }),

    isUserEnoughTimeInside: _ember['default'].computed.gte('secondsOnLivePrercentage', 50),

    belongsToCurrentUser: _ember['default'].computed('user.id', '_session.currentUser.id', function () {
      var user = this.get('_session.currentUser');
      if (user && user.get('id')) {
        return this.get('user.id') === user.get('id');
      } else {
        return false;
      }
    })
  });
});