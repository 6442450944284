define('hoolio-www/services/session', ['exports', 'ember', 'ember-data', 'ember-simple-auth/services/session'], function (exports, _ember, _emberData, _emberSimpleAuthServicesSession) {
  exports['default'] = _emberSimpleAuthServicesSession['default'].extend({
    store: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    currentUser: null,

    loadCurrentUser: function loadCurrentUser() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var userId = _this.get('data.authenticated.user_id');
        if (!_ember['default'].isEmpty(userId)) {
          return _this.get('store').find('user', userId).then(function (user) {
            _this.set('currentUser', user);
            resolve(user);
          }, reject);
        } else {
          resolve();
        }
      });
    },

    _onCurrentUser: _ember['default'].observer('currentUser', function () {
      _ember['default'].run.next(this, function () {
        var user = this.get('currentUser');
        if (user) {
          // Boot intercom analytics
          this.get('analytics.intercom').boot(user);
        }
      });
    })
  });
});