define('hoolio-www/components/fit-slider-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['fit-slider-item'],
    attributeBindings: ['itemIdIndex:data-item-id'],
    isExpanded: _ember['default'].computed('attrs.activeItemId', 'attrs.itemId', function () {
      return this.attrs.itemId === this.attrs.activeItemId;
    }),
    itemIdIndex: _ember['default'].computed('attrs.itemId', function () {
      if (this.attrs.itemId) {
        return this.attrs.itemId.split('-')[1];
      }
    })
  });
});