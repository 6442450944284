define('hoolio-www/components/hero-row-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['hero-row-item'],

    focusIn: function focusIn() {
      this.set('hovered', true);
    },

    focusOut: function focusOut() {
      this.set('hovered', false);
    },

    mouseEnter: function mouseEnter() {
      this.set('hovered', true);
    },

    mouseLeave: function mouseLeave() {
      this.set('hovered', false);
    },

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      //console.log('destroy');
    })
  });
});