define('hoolio-www/components/studio-users-list/component', ['exports', 'ember', 'ember-resize/mixins/resize-aware'], function (exports, _ember, _emberResizeMixinsResizeAware) {
  exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], {
    opentok: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    tvService: _ember['default'].inject.service('tv'),

    tagName: 'ul',
    classNames: ['side-user-list'],

    tooltipSide: _ember['default'].computed('attrs.viewLayout', function () {
      if (this.attrs.viewLayout === "horizontal-layout") {
        return 'right';
      } else {
        return 'top';
      }
    }),

    debouncedDidResize: function debouncedDidResize(width, height, evt) {
      this.setPosition();
    },

    setPosition: function setPosition() {
      if (this.$()) {
        this.$().hide();
      }
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _playerContainerHeight = _ember['default'].$('.player-container').height();
        if (this.attrs.viewLayout === 'horizontal-layout') {
          this.$("li.side-user").width('100%');
          this.$("li.side-user").height('auto');
        } else {
          var userVideoHeight = window.innerHeight - _playerContainerHeight;
          this.$("li.side-user").width(userVideoHeight * 1.33);
          this.$("li.side-user").height(userVideoHeight);
        }
        this.$().show();
      });
    },

    onLayoutToggle: _ember['default'].on('init', _ember['default'].observer('attrs.viewLayout', 'opentok.connectionsCount', 'users.[]', function () {
      this.setPosition();
    })),

    onFullscreenToggle: _ember['default'].observer('fullscreen.isEnabled', function () {
      _ember['default'].run.later(this, this.setPosition, 1000);
    }),

    actions: {
      enableCamera: function enableCamera() {
        this.attrs.enableCamera();
      },

      likeUserStatus: function likeUserStatus(user) {
        this.attrs.likeUserStatus(user);
      }
    }
  });
});