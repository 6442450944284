define('hoolio-www/components/moves-bar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['moves-bar-component'],
    attributeBindings: ['componentStyle:style'],
    componentStyle: '',
    progressStyle: '',

    onInit: _ember['default'].on('didReceiveAttrs', function () {
      var _width = this.attrs.width ? this.attrs.width : 400;
      var _maxValue = this.attrs.maxValue ? this.attrs.maxValue : 4000;
      var _value = undefined;
      if (this.attrs.value) {
        if (this.attrs.value >= _maxValue) {
          _value = 100;
        } else {
          _value = Math.floor(this.attrs.value * 100 / _maxValue);
        }
      } else {
        _value = 0;
      }

      this.set('componentStyle', _ember['default'].String.htmlSafe('width:' + _width + 'px;'));
      this.set('progressStyle', _ember['default'].String.htmlSafe('width:0%;'));

      _ember['default'].run.next(this, function () {
        this.set('progressStyle', _ember['default'].String.htmlSafe('width:' + _value + '%;'));
      });
    })
  });
});