define('hoolio-www/user-fitnessplan/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    created: _emberData['default'].attr('date'),
    status: _emberData['default'].attr('string', { defaultValue: 'active' }),
    aims: _emberData['default'].attr('array'),
    /*
    *  Relationships
    * 
    */
    fitnessplanImages: _emberData['default'].hasMany('image'),
    user: _emberData['default'].belongsTo('user'),
    fitnessplan: _emberData['default'].belongsTo('fitnessplan'),
    fitnessplanHeroImage: _emberData['default'].belongsTo('image'),
    week1Workouts: _emberData['default'].hasMany('workout'),
    week2Workouts: _emberData['default'].hasMany('workout'),
    week3Workouts: _emberData['default'].hasMany('workout'),
    week4Workouts: _emberData['default'].hasMany('workout'),
    week5Workouts: _emberData['default'].hasMany('workout'),
    week6Workouts: _emberData['default'].hasMany('workout'),

    /*
    *  Computed attribures
    * 
    */
    workouts: _ember['default'].computed('week1Workouts.[]', 'week2Workouts.[]', 'week3Workouts.[]', 'week4Workouts.[]', 'week5Workouts.[]', 'week6Workouts.[]', function () {
      var stream = _ember['default'].A();
      stream.pushObjects(this.get('week1Workouts').toArray());
      stream.pushObjects(this.get('week2Workouts').toArray());
      stream.pushObjects(this.get('week3Workouts').toArray());
      stream.pushObjects(this.get('week4Workouts').toArray());
      stream.pushObjects(this.get('week5Workouts').toArray());
      stream.pushObjects(this.get('week6Workouts').toArray());
      return stream;
    }),
    incompleteWorkouts: _ember['default'].computed.filterBy('workouts', 'status', 'incomplete')
  });
});