define('hoolio-www/hooliometry/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    renderTemplate: function renderTemplate() {
      var hooliometryController = this.controllerFor('hooliometry');
      this.render('explore', {
        controller: hooliometryController
      });
    },

    queryParams: {
      name: {
        refreshModel: true
      }
    },

    afterModel: function afterModel(model, transition) {
      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    model: function model(params) {
      return this.store.query('default-treemap', params);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('dataObject', model.get('firstObject.userGroups'));
      controller.set('title', model.get('firstObject.title'));
      controller.set('subtext', model.get('firstObject.subtext'));
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});