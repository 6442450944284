define('hoolio-www/workout-invitation-modal/controller', ['exports', 'ember'], function (exports, _ember) {
  /*global FB */
  /*global escape */

  exports['default'] = _ember['default'].Controller.extend({
    opentok: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    msgText: _ember['default'].computed('model.workout.workoutUrl', function () {
      return "Hey," + escape('\n\n') + "Let's workout online together with hoolio. Click on the link below and join me for a free online workout." + escape('\n\n') + this.get('model.workout.workoutUrl') + escape('\n\n') + "At www.hoolio.com you will soon find the perfect solution for working out from home. This is just a sneak peek of what’s yet to come.";
    }),
    popUpAttrs: 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=500',
    isUrlEmpty: _ember['default'].computed.empty('model.workout.workoutUrl'),

    actions: {
      dispose_modal: function dispose_modal() {
        if (this.get('model.closed_cb')) {
          this.get('model.closed_cb')();
        }
        this.send('removeModal');
      },

      sendFacebookMessage: function sendFacebookMessage() {
        var that = this;
        FB.ui({
          method: 'send',
          name: 'Fitaborate invitation',
          to: '',
          link: that.get('model.workout.workoutUrl')
        });

        /* Track intercom event */
        var metadata = {
          social_service: 'facebook'
        };
        this.get('analytics.intercom').trackEvent('send-friend-invitation', metadata);
        this.get('analytics.google').trackEvent('social', 'invite_friend', 'social_workout');
      },

      sendGoogleMessage: function sendGoogleMessage() {
        window.open('https://plus.google.com/share?url=' + encodeURIComponent(this.get('model.workout.workoutUrl')), '', this.get('popUpAttrs'));

        /* Track intercom event */
        var metadata = {
          social_service: 'google-plus'
        };
        this.get('analytics.intercom').trackEvent('send-friend-invitation', metadata);
        this.get('analytics.google').trackEvent('social', 'invite_friend', 'social_workout');
      },

      sendEmailMessage: function sendEmailMessage() {
        var uri = 'mailto:?subject=' + encodeURIComponent("hoolio.com invitation") + "&body=" + this.get('msgText');
        window.open(uri);

        /* Track intercom event */
        var metadata = {
          social_service: 'email'
        };
        this.get('analytics.intercom').trackEvent('send-friend-invitation', metadata);
        this.get('analytics.google').trackEvent('social', 'invite_friend', 'social_workout');
      },

      alertAfterCopy: function alertAfterCopy() {
        var flash = _ember['default'].get(this, 'flashMessages');
        flash.success('Link copied to your clipboard.');

        /* Track intercom event */
        var metadata = {
          social_service: 'copied-link'
        };
        this.get('analytics.intercom').trackEvent('send-friend-invitation', metadata);
        this.get('analytics.google').trackEvent('social', 'invite_friend', 'social_workout');
      }
    }
  });
});