define('hoolio-www/components/player-controls/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    videoPlayerService: _ember['default'].inject.service('video-player'),
    classNames: ['player-controls-component'],

    actions: {
      play: function play() {
        this.get('videoPlayerService').trigger('player-play');
      },

      pause: function pause() {
        this.get('videoPlayerService').trigger('player-pause');
      },

      togglePlayerState: function togglePlayerState() {
        if (this.get('videoPlayerService.isPlaying')) {
          this.get('videoPlayerService').trigger('player-pause');
        } else {
          this.get('videoPlayerService').trigger('player-play');
        }
      },

      forward: function forward() {
        this.get('videoPlayerService').trigger('player-forward');
      },

      backward: function backward() {
        this.get('videoPlayerService').trigger('player-backward');
      },

      toggleMute: function toggleMute() {
        if (this.get('videoPlayerService.isMuted')) {
          this.get('videoPlayerService').trigger('player-unmute');
        } else {
          this.get('videoPlayerService').trigger('player-mute');
        }
      }
    }
  });
});