define('hoolio-www/components/player-container/component', ['exports', 'ember', 'ember-resize/mixins/resize-aware'], function (exports, _ember, _emberResizeMixinsResizeAware) {
  exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], {
    videoPlayerService: _ember['default'].inject.service('video-player'),
    opentok: _ember['default'].inject.service(),

    classNames: ['player-container'],
    classNameBindings: ["opentok.isPublisherConnected:publisher-connected"],
    attributeBindings: ['style'],
    topOffset: 0,

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        _ember['default'].$('body').bind("keyup", this.onSpacebarPress.bind(this));

        this.get('opentok').on('connections-changed', function () {
          _this.setPosition();
        });
      });
    }),

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      _ember['default'].$('body').unbind("keyup", this.onSpacebarPress.bind(this));
    }),

    onSpacebarPress: function onSpacebarPress(e) {
      if (e.keyCode == 32) {
        if (this.get('videoPlayerService.isPlaying')) {
          this.send('pause');
        } else {
          this.send('play');
        }
      }
    },

    style: _ember['default'].computed('topOffset', function () {
      var _style = 'margin-top:' + this.get('topOffset') + 'px;';
      return new _ember['default'].String.htmlSafe(_style);
    }),

    debouncedDidResize: function debouncedDidResize(width, height, evt) {
      this.setPosition();
    },

    setPosition: function setPosition() {
      _ember['default'].run.next(this, function () {
        if (this.attrs && this.attrs.isFromYoutube) {
          this.$('.youtube-player').css('height', 'auto');
          this.$('.youtube-player').css('max-width', '100%');

          if (this.attrs.viewLayout === 'horizontal-layout') {
            var value = (this.$().parent().height() - this.$().height()) / 2;
            if (value > 0) {
              this.$('.youtube-player').css('max-width', '100%');
            } else {
              this.$('.youtube-player').css('max-width', this.$().parent().height() * 1.7777);
            }
          } else {
            var value = (this.$().parent().height() - this.$().height()) / 2;
            if (value > 0) {
              this.$('.youtube-player').css('max-width', this.$().height() * 1.7777);
            } else {
              this.$('.youtube-player').css('max-width', this.$().parent().height() * 1.7777);
            }
          }
        } else if (this.attrs && !this.attrs.isFromYoutube) {
          this.$('.kaltura-player').css('height', 'auto');
          this.$('.kaltura-player').css('max-width', '100%');

          if (this.attrs.viewLayout === 'horizontal-layout') {
            var windowRatio = this.$().width() / window.innerHeight;
            var value = (window.innerHeight - this.$().height()) / 2;
            if (windowRatio < 1.77777) {
              this.$('.kaltura-player').css('height', 'auto');
              this.$('.kaltura-player').css('max-width', '100%');
            } else {
              this.$('.kaltura-player').css('height', window.innerHeight);
              this.$('.kaltura-player').css('max-width', window.innerHeight * 1.7777);
            }
          } else {
            this.$('.kaltura-player').css('max-width', this.$().height() * 1.7777);
          }
        }
      });
    },

    onLayoutToggle: _ember['default'].observer('attrs.viewLayout', function () {
      this.setPosition();
    }),

    onFullscreenToggle: _ember['default'].observer('fullscreen.isEnabled', function () {
      _ember['default'].run.later(this, this.setPosition, 1000);
    }),

    actions: {
      onPlayerReadyEvent: function onPlayerReadyEvent() {
        if (this.attrs.onPlayerReadyEvent) {
          this.attrs.onPlayerReadyEvent();
        }

        this.setPosition();
      },

      play: function play() {
        this.get('videoPlayerService').trigger('player-play');
      },

      pause: function pause() {
        this.get('videoPlayerService').trigger('player-pause');
      }
    }
  });
});