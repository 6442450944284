define('hoolio-www/components/tile-fitnessplan/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    fitnessplan: _ember['default'].computed.alias('session.currentUser.activeFitnessPlan.fitnessplan'),
    userFitnessplan: _ember['default'].computed.alias('session.currentUser.activeFitnessPlan'),
    incompleteFitnessplan: _ember['default'].computed.alias('session.currentUser.activeFitnessPlan.incompleteWorkouts.firstObject'),

    classNames: ['tile-fitnessplan-component', 'tile', 'animated', 'fadeIn'],
    classNameBindings: ['tile.classNames'],

    style: _ember['default'].computed('', function () {
      var _style = 'background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ),url("https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/1_r1me43yn/width/720");'; //
      return new _ember['default'].String.htmlSafe(_style);
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {}),

    click: function click() {
      if (!this.get('userFitnessplan')) {
        this.get('router').transitionTo('fitnessplans');
      }
    },

    actions: {
      joinWorkout: function joinWorkout(workout) {
        this.get('router').transitionTo('workout', workout);
      },

      gotoFitnessplans: function gotoFitnessplans() {
        this.get('router').transitionTo('fitnessplans');
      },

      gotoActiveFitnessplan: function gotoActiveFitnessplan() {
        this.get('router').transitionTo('fitnessplans', { queryParams: { it: 'workouts' } });
      }
    }
  });
});