define('hoolio-www/helpers/find-in-array', ['exports', 'ember'], function (exports, _ember) {

  var findInArray = function findInArray(params) {
    var result = params[0].find(function (item) {
      if (params[2]) {
        return params[1] === item.get(params[2]);
      } else {
        return params[1] === item;
      }
    });
    return result;
  };
  exports['default'] = _ember['default'].Helper.helper(findInArray);
});