define('hoolio-www/user-preference/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    fitLevel: _emberData['default'].attr('number', { defaultValue: 1 }),
    userDashboard: _emberData['default'].attr('array'),
    userGroups: _emberData['default'].attr('array'),
    userMovesboard: _emberData['default'].attr('object'),
    userScoreboard: _emberData['default'].attr('object'),

    /*
    *  Relationships
    * 
    */
    user: _emberData['default'].belongsTo('user'),
    character: _emberData['default'].belongsTo('character', { inverse: null }),
    onboardingList1: _emberData['default'].hasMany('video'),
    onboardingList2: _emberData['default'].hasMany('video'),
    discoveryList: _emberData['default'].hasMany('video'),

    /*
    *  Computed properties
    * 
    */
    topMovesboard: _ember['default'].computed('userMovesboard', function () {
      var movesboard = this.get('userMovesboard');
      if (movesboard) {
        var topValues = {};
        for (var property in movesboard) {
          if (movesboard.hasOwnProperty(property)) {
            //console.log(property);
            var maxValue = 0;
            for (var value in movesboard[property]) {
              if (movesboard[property].hasOwnProperty(value)) {
                if (movesboard[property][value] > maxValue) {
                  maxValue = movesboard[property][value];
                  topValues[property] = value;
                }
              }
            }
          }
        }
        return topValues;
      }
    })
  });
});