define('hoolio-www/components/workout-feedback/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ["workout-feedback-component"],

    oneIsSelected: _ember['default'].computed.equal('attrs.feedbackValue.value', 1),
    twoIsSelected: _ember['default'].computed.equal('attrs.feedbackValue.value', 2),
    threeIsSelected: _ember['default'].computed.equal('attrs.feedbackValue.value', 3),
    fourIsSelected: _ember['default'].computed.equal('attrs.feedbackValue.value', 4),
    fiveIsSelected: _ember['default'].computed.equal('attrs.feedbackValue.value', 5),

    actions: {
      setValue: function setValue(value) {
        this.attrs.feedbackValue.update(Number(value));

        if (this.attrs.onFeedback) {
          this.attrs.onFeedback(Number(value));
        }
      }
    }
  });
});