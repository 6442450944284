define('hoolio-www/workout/completed/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    opentok: _ember['default'].inject.service(),
    videoPlayer: _ember['default'].inject.service(),
    resizeService: _ember['default'].inject.service('resize'),
    sessionService: _ember['default'].inject.service('session'),
    videoService: _ember['default'].inject.service('video'),
    healthService: _ember['default'].inject.service('health'),
    analytics: _ember['default'].inject.service(),

    dashboardBtnIsLoading: false,

    onInit: _ember['default'].on('init', function () {}),

    backgroundPhotoUrl: _ember['default'].computed('model.video.isFromYoutube', function () {
      var photoUrl = this.get('model.video.isFromYoutube') ? this.get('model.video.youtubeThumbnail') : this.get('model.video.thumbnailUrlWide');
      return photoUrl;
    }),

    actions: {
      onFeedback: function onFeedback() {
        this.get('workoutMetric').save();

        this.get('analytics.intercom').trackEvent('video-rating', { video_name: this.get('model.video.name'), rating: this.get('workoutMetric.userFeedback') });
        this.get('analytics.google').trackEvent('workout', 'video_rating', 'engage');
      },

      joinNextWorkout: function joinNextWorkout(video) {
        var _this = this;

        if (video) {
          this.get('videoService').getOrCreateWorkout(video, function (workout) {
            _this.transitionToRoute('workout', workout);
          });
        }
      },

      toggleFullscreen: function toggleFullscreen() {
        this.fullscreen.toggle();
      },

      gotoDashboard: function gotoDashboard() {
        this.transitionToRoute('dashboard');
      }
    }
  });
});