define('hoolio-www/components/video-thumb/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    browser: _ember['default'].inject.service(),

    classNames: ['video-thumb'],
    classNameBindings: ['isLowResolution:scaled', 'media.classNames'],
    slices: 5, // number of thumbs per video
    showPlayButton: true,
    infoText: "",
    playText: "",
    thumbs: _ember['default'].A([]),
    autostop: false,
    hasStarted: false,
    animated: true,
    withGradient: true,
    scaleLowResolutionOnMobiles: _ember['default'].computed.and('isLowResolution', 'media.isMobileOrTablet'),
    isLoading: false,

    onInitialize: _ember['default'].on('didReceiveAttrs', function () {
      if (this.attrs.url) {
        this._initSlider();
      }
    }),

    _initSlider: function _initSlider() {
      if (this.attrs.showPlayButton === false) {
        this.set('showPlayButton', this.attrs.showPlayButton);
      }

      if (this.attrs.infoText) {
        this.set('infoText', this.attrs.infoText);
      }

      if (this.attrs.playText) {
        this.set('playText', this.attrs.playText);
      }

      if (this.attrs.withGradient) {
        this.set('withGradient', this.attrs.withGradient);
      }

      var isFromKaltura = this.isFromKaltura(this.attrs.url);
      var animated = this.attrs.animated || this.get('animated');
      if (!this.get('browser.isMobile') && isFromKaltura && animated) {
        var _thumbs = [];
        _thumbs.push(this.attrs.url);
        for (var i = 1; i <= this.slices; i++) {
          _thumbs.push(this.thumbSlice(this.attrs.url, i));
        }

        this.set('thumbs', _thumbs);
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          this.set('hasStarted', true);
        });
      } else {
        this.set('thumb', this.attrs.url);
      }
    },

    _interval: null,
    _startAnimation: _ember['default'].observer('hasStarted', function () {
      var _this = this;

      if (this.get('hasStarted')) {
        (function () {
          var active = 0;
          _this._interval = setInterval(function () {
            _this.$("#thumb-" + active).removeClass('hidden');

            if (active === 0) {
              _this.$("#thumb-5").addClass('hidden');
            } else if (active > 0) {
              _this.$("#thumb-" + (active - 1)).addClass('hidden');
            }

            if (active === 5) {
              active = 0;
            } else {
              active++;
            }
          }, 1500);
        })();
      }
    }),

    isFromYoutube: function isFromYoutube(url) {
      if (url) {
        //const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
        var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;
        return url.match(regExp) ? true : false;
      } else {
        return false;
      }
    },

    isFromKaltura: function isFromKaltura(url) {
      if (url) {
        return url.indexOf("https://cfvod.kaltura.com") > -1 ? true : false;
      } else {
        return false;
      }
    },

    isLowResolution: _ember['default'].computed('attrs.url', function () {
      if (this.attrs.url) {
        return this.attrs.url.indexOf("sddefault.jpg") > -1 || this.attrs.url.indexOf("0.jpg") > -1;
      } else {
        return false;
      }
    }),

    thumbBase: function thumbBase(path) {
      // extract the base thumb path by removing the slicing parameters
      var basePath = path;
      var pos = basePath.indexOf("/vid_slice");
      if (pos != -1) {
        basePath = basePath.substring(0, pos);
      }
      return basePath;
    },

    thumbSlice: function thumbSlice(path, i) {
      if (!path || !this.attrs) {
        return;
      }
      path = this.thumbBase(path);
      return path + "vid_slice/" + i + "/vid_slices/" + this.slices;
    },

    /*click() {
      if(this.attrs.onClick) {
        this.attrs.onClick();
      }
    }*/

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      this.set('isLoading', false);
      if (this._interval) {
        clearInterval(this._interval);
      }
    }),

    actions: {
      joinWorkout: function joinWorkout(video) {
        if (this.get('session.isAuthenticated')) {
          if (this.get('isLoading')) {
            return;
          } else {
            this.set('isLoading', true);
          }
        }
        if (this.attrs.joinWorkout) {
          this.attrs.joinWorkout(video);
        }
      }
    }
  });
});