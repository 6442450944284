define('hoolio-www/register-modal/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    emagSocial: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    isLoading: false,
    isFbLoading: false,
    showHoolioRegister: false,

    actions: {
      register: function register() {
        var _this = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        this.set('errorMessage', '');
        this.set('isLoading', true);

        if (this.get('password') !== this.get('passwordConfirmation')) {
          this.set('isLoading', false);
          this.set('errorMessage', 'Password mismatch. Please confirm your password.');
          return;
        }

        if (_ember['default'].$('#register-modal-form').parsley().validate()) {
          var data = this.getProperties('firstName', 'lastName', 'email', 'password', 'rememberMe');
          this.get('emagSocial').register(data, function (response) {
            var credentials = {
              email: response.user_email,
              password: _this.get('password'),
              rememberMe: false
            };

            flashMessages.success('A verification link has been send to your email, in order to activate your account.', { timeout: 10000 });

            _this.get('analytics.google').trackEvent('sign', 'register', 'convert');
            _this.get('analytics.facebook').trackEvent('CompleteRegistration');

            _this.get('session').authenticate('authenticator:hoolio', credentials).then(function () {
              _this.set('isLoading', false); // authentication was successful
              _this.send('dispose_modal');
            }, function (message) {
              _this.set('errorMessage', message);
              _this.set('isLoading', false);
            });
          }, function (error) {
            _this.set('errorMessage', error);
            _this.set('isLoading', false);
          });
        } else {
          this.set('isLoading', false);
        }
      },

      // action to trigger authentication with Facebook
      authenticateWithFacebook: function authenticateWithFacebook() {
        var _this2 = this;

        this.set('isFbLoading', true);
        this.get('session').authenticate('authenticator:facebook').then(function () {

          _this2.get('analytics.google').trackEvent('sign', 'register', 'convert');
          _this2.get('analytics.facebook').trackEvent('CompleteRegistration');

          // authentication was successful
          _this2.send('dispose_modal');
        }, function (message) {
          _this2.set('isFbLoading', false);
          _this2.set('errorMessage', message);
        });
      },

      toggleHoolioForm: function toggleHoolioForm() {
        this.toggleProperty('showHoolioRegister');
      },

      dispose_modal: function dispose_modal() {
        if (this.get('model.closed_cb')) {
          this.get('model.closed_cb')();
        }
        this.set('errorMessage', '');
        this.set('isLoading', false);
        this.set('isFbLoading', false);
        this.send('removeModal');
      }
    }
  });
});