define('hoolio-www/components/tile-user/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),

    defaultAvatar: "assets/img/general/default-image-2.png", //assets/img/general/trainee_male.jpg

    userMoves: 0,
    movesWeeklyGoal: 0,
    loadingUserMetrics: false,
    isOnCurrentWeek: false,

    classNames: ['tile-user-component', 'tile', 'animated', 'fadeIn'],
    classNameBindings: ['tile.classNames'],

    style: _ember['default'].computed('session.currentUser.photoUrl', function () {
      var _transparency = this.get('session.currentUser.hasPhoto') ? 0.6 : 0.8;
      var _style = 'background: linear-gradient( rgba(0, 0, 0, ' + _transparency + '), rgba(0, 0, 0, ' + _transparency + ') ),url("' + this.get('session.currentUser.photoUrl') + '");'; //
      return new _ember['default'].String.htmlSafe(_style);
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {}),

    onUserMetrics: _ember['default'].on('init', _ember['default'].observer('session.currentUser.weeklyGoal', 'session.currentUser.weeklyMetrics', function () {
      if (this.get('session.currentUser')) {
        this.set('userMoves', this.get('session.currentUser.weeklyMetrics.moves') || 0);
        this.set('movesWeeklyGoal', this.get('session.currentUser.weeklyGoal') || 0);
      }
    })),

    actions: {
      onDateChange: function onDateChange(startingWeek, endingWeek) {
        var _this = this;

        _ember['default'].set(this, 'loadingUserMetrics', true);
        if (moment().isAfter(endingWeek)) {
          _ember['default'].set(this, 'isOnCurrentWeek', false);
          this.get('emag').getUserMetrics(startingWeek.unix(), endingWeek.unix(), function (data) {
            //console.log(data);
            if (_ember['default'].get(_this, 'loadingUserMetrics')) {
              _ember['default'].set(_this, 'userMoves', data.weeklyMetric.moves);
              _ember['default'].set(_this, 'loadingUserMetrics', false);
            }
          }, function (error) {
            _this.get('emag').error('[User metrics] ' + error);
            _ember['default'].set(_this, 'loadingUserMetrics', false);
          });
        } else {
          _ember['default'].set(this, 'isOnCurrentWeek', true);
          _ember['default'].set(this, 'userMoves', this.get('session.currentUser.weeklyMetrics.moves') || 0);
          _ember['default'].set(this, 'loadingUserMetrics', false);
        }
      },

      gotoProfile: function gotoProfile() {
        this.get('router').transitionTo('user', this.get('session.currentUser.id'));
      }
    }
  });
});