define('hoolio-www/components/video-preview/component', ['exports', 'ember', 'ember-resize/mixins/resize-aware'], function (exports, _ember, _emberResizeMixinsResizeAware) {
  exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], {
    analytics: _ember['default'].inject.service(),

    classNames: ['video-preview-component'],

    actions: {
      onPlayerStartEvent: function onPlayerStartEvent() {
        this.get('analytics.intercom').trackEvent('premium-video-preview', { video_name: this.attrs.video.get('name') });
        this.get('analytics.google').trackEvent('preview', 'preview_premium', 'interest');
      }
    }
  });
});