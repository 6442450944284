define('hoolio-www/components/add-to-calendar-button/component', ['exports', 'ember'], function (exports, _ember) {
  /* globals moment */

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['add-to-calendar-button'],

    timezone: _ember['default'].computed(function () {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }),

    endTime: _ember['default'].computed('attrs.data.startTime', 'attrs.data.duration', function () {
      if (this.attrs.data.startTime && this.attrs.data.duration) {
        var time = moment();
        time.hour(this.attrs.data.startTime.split(':')[0]);
        time.minute(this.attrs.data.startTime.split(':')[1]);
        time.add(this.attrs.data.duration, 'seconds');
        return time.format("HH:mm");
      }
    }),

    currentUrl: _ember['default'].computed('attrs.data.startTime', 'attrs.data.duration', function () {
      return window.location.href;
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      _ember['default'].run.next(this, function () {
        window.addtocalendar.load();
      });
    }),

    onWillDestroyElement: _ember['default'].on('willDestroyElement', function () {})
  });
});