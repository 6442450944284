define('hoolio-www/components/video-row-info/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    videoService: _ember['default'].inject.service('video'),
    modals: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    classNames: ['video-row-info'],
    resumeWorkout: null,

    showVideoPreview: false,
    showUpgradeInfo: false,

    onActivate: _ember['default'].on('init', function () {
      this.resumeWorkout = null;
    }),

    onAttrs: _ember['default'].on('didReceiveAttrs', function () {
      var _this = this;

      if (this.get('session.currentUser') && this.get('session.currentUser').get('workouts')) {
        this.get('session.currentUser').get('workouts').then(function (workouts) {
          if (workouts.get('length') > 0) {
            var existingWorkout = workouts.findBy('video.id', _this.attrs.item.get('id'));
            if (existingWorkout && !existingWorkout.get('fitnessPlan.id')) {
              _this.set('resumeWorkout', existingWorkout);
            } else {
              _this.set('resumeWorkout', null);
            }
          } else {
            _this.set('resumeWorkout', null);
          }
        });
      }

      // Reset new metadata
      this.get('videoService').set('selectedRowVideo', this.attrs.item);
    }),

    favoredFromUser: false,
    onFavoredFromUser: _ember['default'].on('didReceiveAttrs', _ember['default'].observer('attrs.item.id', 'session.currentUser.favoriteVideos.[]', 'session.currentUser', function () {
      var _this2 = this;

      if (this.get('session.currentUser.id')) {
        this.get('videoService').isFavoredFromUser(this.attrs.item.get('id')).then(function (value) {
          if (value != _this2.get('favoredFromUser')) {
            _this2.set('favoredFromUser', value);
          }
        });
      }
    })),

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      this.get('videoService').set('selectedRowVideo', null);
    }),

    actions: {
      joinWorkout: function joinWorkout() {
        var _this3 = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        this.get('store').findRecord('video', this.attrs.item.get('id')).then(function (video) {
          _this3.get('videoService').getOrCreateWorkout(video, function (workout) {
            _this3.get('router').transitionTo('workout', workout);
          }, function (msg) {
            flashMessages.danger(msg);
            _this3.get('modals').trigger('pricing');
          });
        });
      },

      addToFavoritesVideos: function addToFavoritesVideos(videoId) {
        var _this4 = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        this.get('store').findRecord('video', videoId).then(function (video) {
          _this4.get('videoService').addToUserFavorites(video, function (msg) {
            flashMessages.success(msg);
          }, function (msg) {
            flashMessages.danger(msg);
            _this4.get('modals').trigger('pricing');
          });
        });
      },

      previewVideo: function previewVideo() {
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        if (this.get('session.currentUser')) {
          this.set('showVideoPreview', true);
        } else {
          flashMessages.danger('Please register in order to perform this action.');
          this.get('modals').trigger('pricing');
        }
      },

      showUpgradeInfo: function showUpgradeInfo() {
        this.set('showVideoPreview', false);
        this.set('showUpgradeInfo', true);
      },

      showPricingModal: function showPricingModal() {
        this.get('modals').trigger('pricing');
      }
    }
  });
});