define('hoolio-www/studio/serializer', ['exports', 'hoolio-www/serializers/rest-serializer', 'ember-data'], function (exports, _hoolioWwwSerializersRestSerializer, _emberData) {
  exports['default'] = _hoolioWwwSerializersRestSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      weeklySchedule: { embedded: 'always' }
    },

    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && payload.studios.length) {
        payload.studios.forEach(function (item) {
          var value = item.weeklySchedule;
          item.weeklySchedule = {
            _id: value
          };
        });
      }
      return this.normalizeArrayResponse.apply(this, arguments);
    }
  });
});