define('hoolio-www/components/video-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),

    classNames: ['video-card'],
    classNameBindings: ['attrs.video.isLoaded::loading'],

    isNotExpanded: _ember['default'].computed.not('isExpanded'),
    setFadeEffect: _ember['default'].computed.and('isNotExpanded', 'isSliderExpanded'),

    isLoading: false,

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      this.set('isLoading', false);
    }),

    actions: {
      joinWorkout: function joinWorkout(video) {
        if (this.get('session.currentUser')) {
          if (this.get('isLoading')) {
            return;
          } else {
            this.set('isLoading', true);
          }
        }

        if (this.attrs.joinWorkout) {
          this.attrs.joinWorkout(video);
        }
      }
    }
  });
});