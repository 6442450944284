define('hoolio-www/services/pubnub', ['exports', 'ember', 'hoolio-www/config/environment', 'hoolio-www/utils/pubnub-wrapper'], function (exports, _ember, _hoolioWwwConfigEnvironment, _hoolioWwwUtilsPubnubWrapper) {
  /* global PubNub */

  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
    session: _ember['default'].inject.service('session'),
    emag: _ember['default'].inject.service('emag'),

    pubnubInstances: {},

    getInstance: function getInstance(instanceName) {
      var instances = this.get('pubnubInstances'),
          instance = instances[instanceName];

      if (instance && instance instanceof _hoolioWwwUtilsPubnubWrapper['default']) {
        //this.get('emag').log("[PubNub] Instance " + instanceName + " found and returned.");
        return instance;
      } else if (typeof instanceName === 'string' && instanceName.length > 0) {
        this.get('emag').log("[PubNub] Creating new pubnub instance: " + instanceName);
        instances[instanceName] = _hoolioWwwUtilsPubnubWrapper['default'].create({ label: instanceName });
        return instances[instanceName];
      }

      return instance;
    },

    clearInstance: function clearInstance(instanceName) {
      var instances = this.get('pubnubInstances'),
          instance = instances[instanceName];

      if (instance && instance instanceof _hoolioWwwUtilsPubnubWrapper['default']) {
        instance.unsubscribeAll();
        instance.removeListeners();
        instance.close();
        instance = null;
        delete instances[instanceName];
        this.get('emag').log("[PubNub] " + instanceName + " pubnub instance removed.");
      } else {
        throw "[PubNub] Invalid pubnub instance name.";
      }
    }
  });
});