define('hoolio-www/components/fitnessplan-weeks-slider/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    videoService: _ember['default'].inject.service('video'),

    classNames: ['fitnessplan-weeks-slider', 'row'],
    classNameBindings: ['isSliderExpanded:is-expanded'],
    activeItemId: '',
    weekSliderId: _ember['default'].computed('elementId', function () {
      return "week-slider-" + this.elementId + "-expanded-data-container";
    }),
    weeksSliderOptions: {
      wrapAround: false,
      freeScroll: false,
      pageDots: false,
      prevNextButtons: true,
      cellSelector: '.week-slider-item'
    },

    videosSliderOptions: {
      wrapAround: false
    },

    onInit: _ember['default'].on('didReceiveAttrs', function () {
      var item = this.elementId + "-0";
      this._setActiveItem(item);
    }),

    isSliderExpanded: _ember['default'].computed('activeItemId', 'elementId', function () {
      if (this.get('activeItemId').split('-')[0] === this.get('elementId')) {
        return true;
      } else {
        return false;
      }
    }),

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      this._setActiveItem('');
    }),

    _setActiveItem: function _setActiveItem(item) {
      if (item) {
        this.set('activeItemId', item);
      }
    },

    actions: {
      setActiveItem: function setActiveItem(item) {
        this._setActiveItem(item);
      },

      joinWorkout: function joinWorkout(workout) {
        if (workout) {
          this.get('router').transitionTo('workout', workout);
        }
      },

      onSliderChange: function onSliderChange(id, slickSliderId) {
        var index = this.get('activeItemId');

        if (index) {
          var values = index.split("-"),
              item = values[0] + '-' + id;

          this._setActiveItem(item);
        }
      }
    }
  });
});