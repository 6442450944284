define('hoolio-www/library/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    videoService: _ember['default'].inject.service('video'),
    session: _ember['default'].inject.service('session'),
    browser: _ember['default'].inject.service(),
    tvService: _ember['default'].inject.service('tv'),

    queryParams: {
      fit: {
        replace: true
      }
    },

    beforeModel: function beforeModel(transition) {
      /*if(!transition.queryParams.fit){
        transition.queryParams.fit = "fu-0";
      }*/
    },

    model: function model() {
      if (this.get('session.isAuthenticated')) {
        return this.get('session.currentUser.userPreference');
      } else {
        return "default-dashboard";
      }
    },

    afterModel: function afterModel(model, transition) {
      _ember['default'].$(document).attr('title', 'hoolio - library');

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    headTags: function headTags() {
      // here we are pulling meta data from the model for this route
      var video = this.get("videoService.selectedRowVideo");
      if (video) {
        return [{
          type: "meta",
          tagId: "meta-og-title-tag",
          attrs: {
            property: "og:title",
            content: video.get("name")
          }
        }, {
          type: "meta",
          tagId: "meta-og-url-tag",
          attrs: {
            property: "og:url",
            content: window.location.href
          }
        }, {
          type: "meta",
          tagId: "meta-og-image-tag",
          attrs: {
            property: "og:image",
            content: video.get("isFromYoutube") ? video.get("youtubeThumbnail") : video.get("thumbnailUrlWide")
          }
        }, {
          type: "meta",
          tagId: "meta-og-description-tag",
          attrs: {
            property: "og:description",
            content: video.get("description")
          }
        }, {
          type: "meta",
          tagId: "meta-twitter-title-tag",
          attrs: {
            name: "twitter:title",
            content: video.get("name")
          }
        }, {
          type: "meta",
          tagId: "meta-twitter-description-tag",
          attrs: {
            name: "twitter:description",
            content: video.get("description")
          }
        }, {
          type: "meta",
          tagId: "meta-twitter-image-tag",
          attrs: {
            name: "twitter:image",
            content: video.get("isFromYoutube") ? video.get("youtubeThumbnail") : video.get("thumbnailUrlWide")
          }
        }];
      } else {
        return [{
          type: "meta",
          tagId: "meta-og-title-tag",
          attrs: {
            property: "og:title",
            content: "hoolio - fit just got real"
          }
        }, {
          type: "meta",
          tagId: "meta-og-url-tag",
          attrs: {
            property: "og:url",
            content: window.location.href
          }
        }, {
          type: "meta",
          tagId: "meta-og-image-tag",
          attrs: {
            property: "og:image",
            content: "//app.hoolio.com/assets/img/general/socialmedia.jpg"
          }
        }, {
          type: "meta",
          tagId: "meta-og-description-tag",
          attrs: {
            property: "og:description",
            content: "hoolio is an online platform that puts fun into fitness by making it possible for you to workout with friends, integrate your wearables and track your fitness progress."
          }
        }, {
          type: "meta",
          tagId: "meta-twitter-title-tag",
          attrs: {
            name: "twitter:title",
            content: "hoolio - fit just got real"
          }
        }, {
          type: "meta",
          tagId: "meta-twitter-description-tag",
          attrs: {
            name: "twitter:description",
            content: "hoolio is an online platform that puts fun into fitness by making it possible for you to workout with friends, integrate your wearables and track your fitness progress."
          }
        }, {
          type: "meta",
          tagId: "meta-twitter-image-tag",
          attrs: {
            name: "twitter:image",
            content: "//app.hoolio.com/assets/img/general/socialmedia.jpg"
          }
        }];
      }
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);

      // Main menu structure.
      controller.set('mainMenu', [{
        id: "library",
        title: "Library",
        desc: "You in hoolio",
        primaryComponent: "user-main-primary",
        secondaryComponent: "user-main-secondary"
      }, {
        id: "fitnessplan",
        title: "Fitness plans",
        desc: "Reach your goals.",
        primaryComponent: "fitnessplan-main-primary",
        secondaryComponent: "fitnessplan-main-secondary"
      }, {
        id: "explore",
        title: "Choose your workout",
        desc: "based on your mood, time, fitness level.",
        primaryComponent: "explore-main-primary",
        secondaryComponent: "explore-main-secondary",
        bgImageUrl: "assets/img/general/explore_bg.jpg"
      }, {
        id: "hoolio-studio",
        title: "Community Fitness Studio",
        desc: "24/7 nonstop video workouts",
        primaryComponent: "explore-main-primary",
        secondaryComponent: "studio-main-secondary",
        bgImageUrl: "https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/1_ii59goi2/width/700"
      }, {
        id: "social",
        title: "Social Corner",
        desc: "Fun things you'll like",
        primaryComponent: "social-main-primary",
        secondaryComponent: "social-main-secondary"
      }]);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        if (transition.targetName != 'workout') {
          controller.set('fit', '');
        }
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});