define('hoolio-www/workout/completed/route', ['exports', 'ember', 'hoolio-www/mixins/authenticated-route'], function (exports, _ember, _hoolioWwwMixinsAuthenticatedRoute) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsAuthenticatedRoute['default'], {
    session: _ember['default'].inject.service(),
    opentok: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),

    activate: function activate() {
      var controller = this.controllerFor('application');
      controller.set('hideTopNavbar', true);
      controller.set('hideFooter', true);
    },

    deactivate: function deactivate() {
      var controller = this.controllerFor('application');
      controller.set('hideTopNavbar', false);
      controller.set('hideFooter', false);
    },

    model: function model(params) {
      return this.store.findRecord('workout', params.id);
    },

    afterModel: function afterModel(model, transition) {
      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);

      this.store.queryRecord('workout-metric', { workout: model.get('id') }).then(function (workoutMetric) {
        controller.set('workoutMetric', workoutMetric);
      });
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        // Reload currentUser
        this.get('session.currentUser').reload();
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});