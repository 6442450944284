define('hoolio-www/components/stop-watch/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['stopwatch-component'],
    timeInterval: null,
    time: 0,

    ondidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      if (this.attrs.started) {
        _ember['default'].run.scheduleOnce('afterRender', this, 'afterRenderEvent');
      } else if (this.get('timeInterval')) {
        clearInterval(this.get('timeInterval'));
        this.set('timeInterval', null);
      }
    }),

    afterRenderEvent: function afterRenderEvent() {
      var _this = this;

      // Time interval for event stopwatch
      this.timeInterval = setInterval(function () {
        _this.incrementProperty('time');
        _this.attrs.onTick(_this.get('time'));
      }, 1000);
    },

    onWillDestroyElement: _ember['default'].on('willDestroyElement', function () {
      clearInterval(this.get('timeInterval'));
      this.set('timeInterval', null);
      this.set('time', 0);
    })
  });
});