define('hoolio-www/video/model', ['exports', 'ember-data', 'ember', 'hoolio-www/utils/getFromString'], function (exports, _emberData, _ember, _hoolioWwwUtilsGetFromString) {
  exports['default'] = _emberData['default'].Model.extend({
    _store: _ember['default'].inject.service('store'),
    _videoPlayerService: _ember['default'].inject.service('video-player'),

    /*
    *  Basic attribures
    * 
    */
    youtubeUrl: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    duration: _emberData['default'].attr('number'),
    name: _emberData['default'].attr('string'),
    views: _emberData['default'].attr('number'),
    totalRank: _emberData['default'].attr('number'),
    thumbnailUrl: _emberData['default'].attr('string'),
    rootEntryId: _emberData['default'].attr('string'),
    aim: _emberData['default'].attr('array'),
    muscleOrBodyArea: _emberData['default'].attr('array'),
    chapters: _emberData['default'].attr('array', { defaultValue: function defaultValue() {
        return [];
      } }),
    annotations: _emberData['default'].attr('array', { defaultValue: function defaultValue() {
        return [];
      } }),
    targetGroup: _emberData['default'].attr('array'),
    level: _emberData['default'].attr('string'),
    packages: _emberData['default'].attr('array'),
    mood: _emberData['default'].attr('array'),
    equipment: _emberData['default'].attr('array'),
    specialization: _emberData['default'].attr('string'),
    source: _emberData['default'].attr('string'),
    youtubeDuration: _emberData['default'].attr('number'),
    youtubeThumbnail: _emberData['default'].attr('string'),
    moves: _emberData['default'].attr('number'),
    trainerName: _emberData['default'].attr('string'),
    contentProvider: _emberData['default'].attr('string'),
    workoutTypes: _emberData['default'].attr('string'),
    performance: _emberData['default'].attr('object', { defaultValue: function defaultValue() {
        return {
          stars: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
          }
        };
      } }),

    /*
    *  Relationships
    * 
    */
    relatedVideos: _emberData['default'].hasMany('video', { inverse: null }),
    trainer: _emberData['default'].belongsTo('trainer', { inverse: null }),

    /*
    *  Computed attribures
    * 
    */
    totalRatings: _ember['default'].computed('this.performance', function () {
      return this.get('performance').stars['1'] + this.get('performance').stars['2'] + this.get('performance').stars['3'] + this.get('performance').stars['4'] + this.get('performance').stars['5'];
    }),

    stars: _ember['default'].computed('this.performance', function () {
      return (1 * this.get('performance').stars['1'] + 2 * this.get('performance').stars['2'] + 3 * this.get('performance').stars['3'] + 4 * this.get('performance').stars['4'] + 5 * this.get('performance').stars['5']) / this.get('totalRatings');
    }),

    // Use this for video thumb. It selects the proper thumb based on video type.
    thumb: _ember['default'].computed('this.isFromYoutube', 'thumbnailUrlWide', function () {
      return this.get('isFromYoutube') ? this.get('youtubeThumbnail') : this.get('thumbnailUrlWide');
    }),
    videoDuration: _ember['default'].computed('this.isFromYoutube', function () {
      return this.get('isFromYoutube') ? this.get('youtubeDuration') : this.get('duration');
    }),

    thumbnailUrlWide: _ember['default'].computed('thumbnailUrl', function () {
      var baseUrl = this.get('thumbnailUrl');
      if (baseUrl && this.get('duration') != 6) {
        // hack: if duration is 6 secs that means it's a youtube video, so skeep kaltura's thumb and load default-image-2.png from cache.
        return this.get('thumbnailUrl') + '/width/720/height/540/';
      } else {
        return 'assets/img/general/default-image-2.png';
      }
    }),

    completedPercentage: _ember['default'].computed('_videoPlayerService.currentTimeValue', 'videoDuration', function () {
      var _time = this.get('_videoPlayerService.currentTimeValue');
      var _duration = this.get('videoDuration');

      if (_time && _duration) {
        var _perc = Math.ceil(_time / _duration * 100);
        return _perc > 100 ? 100 : _perc;
      } else {
        return 0;
      }
    }),

    isCompleted: _ember['default'].computed.gte('completedPercentage', 50),

    isFromYoutube: _ember['default'].computed('source', function () {
      return this.get('source') === 'YouTube';
    }),

    isFromKaltura: _ember['default'].computed('source', function () {
      return this.get('source') === 'Kaltura';
    }),

    isThumbHd: _ember['default'].computed('youtubeThumbnail', function () {
      var thumb = this.get('youtubeThumbnail');
      if (thumb) {
        return this.get('youtubeThumbnail').indexOf("maxresdefault") > -1;
      } else {
        return false;
      }
    }),

    youtubeId: _ember['default'].computed('youtubeUrl', function () {
      var url = this.get('youtubeUrl');
      if (url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[7].length == 11) {
          return match[7];
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),

    youtubeThumbnailSmall: _ember['default'].computed('source', 'youtubeUrl', function () {
      if (this.get('source') === 'YouTube') {
        if (typeof this.get('youtubeUrl') !== 'undefined' && this.get('youtubeUrl') !== '') {
          var regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
          var video_id = this.get('youtubeUrl').match(regExp);
          if (video_id !== null) {
            return 'https://i.ytimg.com/vi/' + video_id[2] + '/0.jpg';
          } else {
            return 'https://i.ytimg.com/vi/test/0.jpg';
          }
        } else {
          return 'https://i.ytimg.com/vi/test/1.jpg';
        }
      } else {
        return '';
      }
    })
  });
});