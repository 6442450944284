define('hoolio-www/components/workout-qrcode/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    pubnub: _ember['default'].inject.service(),

    classNames: ['workout-qrcode'],
    showQrcode: false,

    _onDidInsertElement: _ember['default'].on('didReceiveAttrs', function () {
      var workoutPb = this.get('pubnub').getInstance('workoutSyncPb');

      workoutPb.on('presence-' + this.get('session.currentUser.id'), this.onPresence.bind(this));
    }),

    onPresence: function onPresence(e) {
      if (e.action === 'join' && e.uuid.split('-').pop() === 'mobile') {
        if (this.get('showQrcode')) {
          this.send('toggleModal');
        }
      }
    },

    actions: {
      toggleModal: function toggleModal() {
        if (this.attrs.isPlaying) {
          this.attrs.togglePlayerState();
        }
        this.toggleProperty('showQrcode');
      }
    }
  });
});