define('hoolio-www/helpers/utc-parser', ['exports', 'ember'], function (exports, _ember) {
  exports.utcOffset = utcOffset;

  function utcOffset(params) {
    var offset = params[1] ? Number(moment().utcOffset()) / 60 : 0;
    var time = params[0] + offset;
    if (time > 23) {
      return moment().hour(time - 24).format('h a');
    }
    return moment().hour(time).format('h a');
  }

  exports['default'] = _ember['default'].Helper.helper(utcOffset);
});