define('hoolio-www/playlist/serializer', ['exports', 'hoolio-www/serializers/rest-serializer', 'ember-data'], function (exports, _hoolioWwwSerializersRestSerializer, _emberData) {
  exports['default'] = _hoolioWwwSerializersRestSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    // Defaults: BelongsTo: { serialize: 'id', deserialize: 'id' } HasMany: { serialize: false, deserialize: 'ids' }
    attrs: {
      videos: { embedded: 'always' }
    },

    keyForRelationship: function keyForRelationship(key, relationship, method) {
      if (key === 'videos') {
        return 'entries';
      } else {
        return key;
      }
    }
  });
});