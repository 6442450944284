define('hoolio-www/services/bot', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  /* globals navigator, window */

  exports['default'] = _ember['default'].Service.extend({

    botUserData: _ember['default'].Object.create({
      uuid: "68310f72-64d1-40ec-be7c-bc4d2e0e356d",
      name: "hoolio bot",
      bot: true,
      photo: "https://s3-eu-west-1.amazonaws.com/media-hoolio-dev/images/upload/profile/avatar-68310f72-64d1-40ec-be7c-bc4d2e0e356d-1479385636173.png"
    }),

    publish: function publish(pubnubInstance, channel, msg) {
      if (!pubnubInstance || !channel || !msg) {
        throw "[Bot] Invalid parameters.";
      }
      this.set("botUserData.data", msg);

      var publishConfig = {
        channel: channel,
        message: this.get('botUserData')
      };
      pubnubInstance.publish(publishConfig);
      this.set("botUserData.photo", "https://s3-eu-west-1.amazonaws.com/media-hoolio-dev/images/upload/profile/avatar-68310f72-64d1-40ec-be7c-bc4d2e0e356d-1479385636173.png");
    }
  });
});