define('hoolio-www/components/workout-invited-message/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    opentok: _ember['default'].inject.service(),
    videoPlayer: _ember['default'].inject.service(),

    classNames: ['workout-invited-message'],
    show: true,

    onPlayerStatus: _ember['default'].on('didReceiveAttrs', function () {
      if (this.get('show') && this.attrs.isPlaying) {
        this.get('videoPlayer').trigger('player-pause');
        _ember['default'].removeObserver(this, 'attrs.isPlaying');
      }
    }),

    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('show');
      },

      enableCamera: function enableCamera() {
        this.set('show', false);
        this.get('opentok').connectPublisher();
      }
    }
  });
});