define('hoolio-www/components/studio-row-info/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['studio-row-info'],
    attributeBindings: ['style'],
    style: _ember['default'].computed('this.attrs.studio', function () {
      var _style = 'background: url("' + this.attrs.studio.get('images')[1] + '") no-repeat center center;'; //
      return new _ember['default'].String.htmlSafe(_style);
    }),

    usersOnline: _ember['default'].computed('this.attrs.studio.occupancy', function () {
      return this.attrs.studio.get('occupancy') ? this.attrs.studio.get('occupancy') : '-';
    }),

    videos: _ember['default'].computed('this.attrs.studio.playingNow', 'this.attrs.studio.playingNext', function () {
      return [this.attrs.studio.playingNow, this.attrs.studio.playingNext];
    }),

    videosSliderOptions: {
      wrapAround: false,
      freeScroll: false,
      pageDots: false,
      prevNextButtons: true,
      cellSelector: '.video-tile',
      adaptiveHeight: true
    }
  });
});