define('hoolio-www/components/bs-tab-list-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['nav-item'],
    classNameBindings: ['isActive:active'],
    attributeBindings: ['role'],
    role: "presentation",

    isActive: _ember['default'].computed('attrs.selectedValue', function () {
      return this.attrs.value === this.attrs.selectedValue;
    }),

    click: function click() {
      if (this.attrs.onClick) {
        this.attrs.onClick();
      }
    },

    actions: {
      setSelectedValue: function setSelectedValue() {
        this.attrs.setSelectedValue(this.attrs.value);
      }
    }
  });
});