define('hoolio-www/components/mouse-move-detection/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['mouse-move-detection-component'],
    moveClassName: 'mouse-moved',
    autoHideDelay: 2000,
    targetElement: '',
    timer: null,

    onDidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      this.set('targetElement', this.attrs.targetElement);

      if (this.attrs.autoHideDelay) {
        this.set('targetElement', this.attrs.autoHideDelay);
      }

      if (this.attrs.moveClassName) {
        this.set('targetElement', this.attrs.moveClassName);
      }
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      _ember['default'].$(this.get('targetElement')).on('mousemove', this.onMouseMove.bind(this));
    }),

    onMouseMove: function onMouseMove() {
      var _targetElement = this.get('targetElement'),
          _moveClassName = this.get('moveClassName'),
          _delay = this.get('autoHideDelay');

      if (_targetElement) {
        _ember['default'].$(this.get('targetElement')).addClass(_moveClassName);
        this.attrs.isMouseMoved.update(true);

        if (this.timer) {
          _ember['default'].run.cancel(this.timer);
        }

        this.timer = _ember['default'].run.later(this, function () {
          _ember['default'].$(this.get('targetElement')).removeClass(_moveClassName);
          this.attrs.isMouseMoved.update(false);
        }, _delay);
      }
    },

    onDestroyElement: _ember['default'].on('didDestroyElement', function () {
      if (this.timer) {
        _ember['default'].run.cancel(this.timer);
      }
      _ember['default'].$(this.get('targetElement')).off('mousemove', this.onMouseMove);
    })

    /*mouseMove() {
      this.onMouseMove();
    }*/
  });
});