define('hoolio-www/workout-metric/model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    created: _emberData['default'].attr('date'),
    completedPercentage: _emberData['default'].attr('number', { defaultValue: 0 }),
    computedMoves: _emberData['default'].attr('number', { defaultValue: 0 }),
    sensorMoves: _emberData['default'].attr('number', { defaultValue: 0 }),
    computedCalories: _emberData['default'].attr('number', { defaultValue: 0 }),
    sensorCalories: _emberData['default'].attr('number', { defaultValue: 0 }),
    sensorSteps: _emberData['default'].attr('number', { defaultValue: 0 }),
    secondsOnLive: _emberData['default'].attr('number', { defaultValue: 0 }),
    secondsOnVideo: _emberData['default'].attr('number', { defaultValue: 0 }),
    userFeedback: _emberData['default'].attr('number', { defaultValue: 0 }),

    /*
    *  Relationships
    * 
    */
    user: _emberData['default'].belongsTo('user', { inverse: null }),
    workout: _emberData['default'].belongsTo('workout', { inverse: null })

  });
});
/*
*  Computed attribures
* 
*/