define('hoolio-www/router', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    analytics: _ember['default'].inject.service(),

    location: _hoolioWwwConfigEnvironment['default'].locationType,
    rootURL: _hoolioWwwConfigEnvironment['default'].rootURL,

    pageviewToGA: _ember['default'].on('didTransition', function (page, title) {
      page = page || this.get('url');
      this.get('analytics').google.trackPageView(page);
      this.get('analytics').facebook.trackEvent("PageView");
    })
  });

  Router.map(function () {
    this.route('dashboard', { path: '/' });
    this.route('trainer', { path: 'trainers/:id' });
    this.route('library');
    this.route('fitnessplans');
    this.route('user', { path: 'users/:id' });

    this.route('notfound', { path: '/notfound' });
    //this.route('notfound', { path: '/*path' });

    this.route('password');
    this.route('workout', { path: 'workouts/:id' });
    this.route('workout.completed', { path: 'workouts/:id/completed' });
    this.route('onboarding');
    this.route('activate');
    this.route('noaccess', { path: '/noaccess' });
    this.route('search');
    this.route('explore');
    this.route('hooliometry');
    this.route('tv');
    this.route('studio', { path: 'studio/:id' });
    this.route('studios');
    this.route('social-wall');
    this.route('demo');
  });

  exports['default'] = Router;
});