define('hoolio-www/components/star-rating/component', ['exports', 'ember'], function (exports, _ember) {
  /*global $ */

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['star-rating'],
    classNameBindings: ['readonly:readonly'],
    stars: [],

    _onInitialize: _ember['default'].on('didReceiveAttrs', function () {
      var _maxStars = this.attrs.maxStars || 0;
      var _starRating = this.attrs.starRating || 0;
      var _readonly = this.attrs.readonly || false;

      var _stars = [],
          i = 0;
      var _rem = _starRating % 1;
      _starRating = _starRating - _rem;
      for (i = 0; i < _maxStars; i++) {
        _stars.pushObject(_ember['default'].Object.create({
          'class': i >= _starRating ? 'fa-star-o star-empty' : 'fa-star star-full',
          index: i + 1
        }));
      }

      if (_rem) {
        if (_rem > 0.76) {
          _stars[_starRating]['class'] = 'fa-star star-full';
        } else if (_rem >= 0.25 && _rem <= 0.75) {
          _stars[_starRating]['class'] = 'fa-star-half-o star-half';
        }
      }

      this.set('stars', _stars);
      this.set('maxStars', _maxStars);
      this.set('starRating', _starRating);
      this.set('readonly', _readonly);
    }),

    actions: {
      setRating: function setRating(star) {
        if (this.attrs.readonly) {
          return;
        }

        this.attrs.starRating = star.index;
        if (this.attrs.onSetRating) {
          this.attrs.onSetRating(star.index);
        }
      }
    }
  });
});