define('hoolio-www/user/serializer', ['exports', 'hoolio-www/serializers/rest-serializer', 'ember-data'], function (exports, _hoolioWwwSerializersRestSerializer, _emberData) {
  exports['default'] = _hoolioWwwSerializersRestSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    // Defaults: BelongsTo: { serialize: 'id', deserialize: 'id' } HasMany: { serialize: false, deserialize: 'ids' }
    attrs: {
      favoriteVideos: { serialize: 'ids', deserialize: 'ids' },
      recentWorkouts: { embedded: 'always' },
      activeFitnessPlan: { embedded: 'always' },
      activeUserSubscriptions: { embedded: 'always' }
    },

    normalize: function normalize(modelClass, resourceHash, prop) {
      //resourceHash.workouts = ["5641c2db0f00007b023738a5", "5641c2e10f00006f023738a6"];
      //resourceHash.favoriteVideos = [];
      //resourceHash.workouts = [];
      return this._super.apply(this, arguments);
    }
  });
});