define('hoolio-www/services/fitnessplan', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    emagSocial: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),

    registerToFitnessplan: function registerToFitnessplan(fitnessplan, success_cb, error_cb) {
      var _this = this;

      if (this.get('session.currentUser.id')) {
        this.get('emagSocial').getObjectId(function (id) {
          var user = _this.get('store').peekRecord('user', _this.get('session.currentUser.id'));
          var newUserFitnessplan = _this.get('store').createRecord('user-fitnessplan', {
            id: id,
            user: user,
            fitnessplan: fitnessplan
          });

          newUserFitnessplan.save().then(function () {
            user.reload().then(function (model) {
              success_cb(newUserFitnessplan);
            });
          });
        }, function () {
          error_cb('Please register in order to perform this action.');
        });
      } else {
        error_cb('Please register in order to perform this action.');
      }
    }

  });
});