define('hoolio-www/components/bs-tab-container/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    _selectedValue: '',
    onDidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      this.set('_selectedValue', this.attrs.selectedValue);
    }),
    actions: {
      // Set selected tab value
      setSelectedValue: function setSelectedValue(value) {
        this.set('_selectedValue', value);
      }
    }
  });
});