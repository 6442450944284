define('hoolio-www/default-dashboard/model', ['exports', 'ember-data/model', 'ember-data'], function (exports, _emberDataModel, _emberData) {
  exports['default'] = _emberDataModel['default'].extend({
    /*
    *  Basic attribures
    * 
    */
    name: _emberData['default'].attr('string'),
    source: _emberData['default'].attr('string'),
    hero: _emberData['default'].attr('array'),
    herorow: _emberData['default'].attr('array'),

    /*
    *  Relationships
    * 
    */
    dashboard: _emberData['default'].hasMany('playlist', { inverse: null })
  });
});