define('hoolio-www/components/flickity-slider/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    browser: _ember['default'].inject.service(),

    classNames: ['flickity-slider-component'],

    _defaultOptions: {
      freeScroll: true,
      freeScrollFriction: 0.05,
      contain: true,
      cellAlign: "left",
      cellSelector: '.slider-cell',
      pageDots: true,
      initialIndex: 0,
      prevNextButtons: true,
      wrapAround: true,
      dragThreshold: 1,
      arrowShape: {
        x0: 30,
        x1: 55, y1: 50,
        x2: 70, y2: 50,
        x3: 45
      }
    },

    isInitialized: false,

    _onInitialize: _ember['default'].on('init', function () {
      //console.log(this.get('cellSelect));
      this.set('isInitialized', false);
    }),

    _onReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      //this._initializeSlider();
      this.set('_items', this.get('items'));
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initializeSlider();
    },

    _initializeSlider: function _initializeSlider() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this2 = this;

        var _this = this;
        var _defaultOptions = this.get('_defaultOptions');
        _defaultOptions.prevNextButtons = !this.get('browser.isMobile');
        _defaultOptions.dragThreshold = this.get('browser.isMobile') ? 15 : 1;

        var _options = this.get('options') ? _ember['default'].$.extend({}, _defaultOptions, this.get('options')) : _defaultOptions;
        var $flickity = this.$();

        if ($flickity.data('flickity')) {
          $flickity.flickity('destroy');
          this.set('isInitialized', false);
        }

        $flickity.flickity(_options).on('select.flickity', function () {
          if (_this.get('cellSelect')) {
            var flkty = $flickity.data('flickity');
            var _index = flkty ? flkty.selectedElement.dataset.itemId : 0;
            _this2.get('cellSelect')(_index, _this2.get('elementId'));
          }
        }).on('settle.flickity', function () {
          if (_this.get('changedCarousel')) {
            var flkty = $flickity.data('flickity');
            var _index = flkty ? flkty.selectedElement.dataset.itemId : 0;
            _this2.get('changedCarousel')(_index, _this2.get('elementId'));
          }
        }).on('staticClick.flickity', function (event, pointer, cellElement, cellIndex) {
          if (!cellElement) {
            return;
          }
          if (_this.get('staticClick')) {
            var item = _this2.get('items') ? _this2.get('items').objectAt(cellIndex) : null;
            _this2.get('staticClick')(cellElement.dataset.itemId, _this2.get('elementId'), cellElement, item);
          }
        });

        this.$('.flickity-prev-next-button').on('click', function (event) {
          if (_this2.get('changeCarousel')) {
            var flkty = $flickity.data('flickity');
            var _index = flkty ? flkty.selectedElement.dataset.itemId : 0;
            _this2.get('changeCarousel')(_index, _this2.get('elementId'));
          }
        });

        $flickity.keydown(this.keyboardCallback.bind(this));

        this.$().flickity('resize').flickity('reposition');
        this.set('isInitialized', true);
      });
    },

    keyboardCallback: function keyboardCallback(e) {
      var $flickity = this.$();
      switch (e.which) {
        case 37:
          // left
          if (this.get('changeCarousel')) {
            var flkty = $flickity.data('flickity');
            var _index = flkty ? flkty.selectedElement.dataset.itemId : 0;
            this.get('changeCarousel')(_index, this.get('elementId'));
          }
          break;

        case 39:
          // right
          if (this.get('changeCarousel')) {
            var flkty = $flickity.data('flickity');
            var _index = flkty ? flkty.selectedElement.dataset.itemId : 0;
            this.get('changeCarousel')(_index, this.get('elementId'));
          }
          break;

        default:
          return; // exit this handler for other keys
      }
      e.preventDefault(); // prevent the default action (scroll / move caret)
    },

    _onInitialSlidePosition: _ember['default'].observer('initialSlidePosition', function () {
      if (!this.get('initialSlidePosition')) {
        return;
      }

      _ember['default'].run.next(this, function () {
        this.$().flickity('select', this.get('initialSlidePosition'));
      });
    }),

    _onMoreItems: _ember['default'].observer('items.[]', function () {
      if (this.get('items.length') > 0 && this.get('isInitialized')) {
        var $flickity = this.$();
        if ($flickity.data('flickity')) {
          $flickity.flickity('destroy');
          this.set('isInitialized', false);
        }

        _ember['default'].run.next(this, function () {
          this._initializeSlider();
        });
      }
    }),

    _destroySlider: _ember['default'].on('willDestroyElement', function () {
      //console.log('destroying...'+this.elementId);
      //this.$('.flickity-prev-next-button').off();
      var $flickity = this.$();
      $flickity.flickity('destroy');
      $flickity.off();
    })
  });
});