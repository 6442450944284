define('hoolio-www/components/studio-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['studio-card'],
    classNameBindings: ['attrs.studio.isLoaded::loading', 'attrs.isExpanded'],

    isGerman: _ember['default'].computed('attrs.studio.name', function () {
      return this.attrs.studio.get('name').toString().toLowerCase().indexOf("german") >= 0;
    }),

    isForTv: _ember['default'].computed('attrs.studio.name', function () {
      return this.attrs.studio.get('name').toString().toLowerCase().indexOf("tv") >= 0;
    }),

    isPremium: _ember['default'].computed('attrs.studio.name', function () {
      return this.attrs.studio.get('name').toString().toLowerCase().indexOf("premium") >= 0;
    }),

    isOffice: _ember['default'].computed('attrs.studio.name', function () {
      return this.attrs.studio.get('name').toString().toLowerCase().indexOf("office") >= 0;
    })

  });
});