define('hoolio-www/services/opentok', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  /* globals OT */

  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
    auth: _ember['default'].inject.service('session'),
    emagNet: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),

    session: null,
    apiKey: Number(_hoolioWwwConfigEnvironment['default'].opentok_api_key),
    opentokSession: '',

    isSessionConnected: false, // Connected to session (user joined room/workout)
    isPublisherConnected: false, // User publish stream to other user's
    subscribedToSession: false, // User subscribed to other user's streams
    connectionsCount: 0, // connected users
    subscribersStreams: _ember['default'].A([]), // Cache subscribers streams
    subscribers: _ember['default'].A([]), // Cache subscribers
    subscriberOptions: { // Subscriber options
      insertMode: 'append',
      width: '640',
      height: '480',
      style: {
        nameDisplayMode: 'on',
        buttonDisplayMode: 'on'
      }
    },

    // Check if the client can publish
    isSupported: function isSupported() {
      if (OT.checkSystemRequirements() == 1) {
        return true;
      } else {
        // The client does not support WebRTC.
        return false;
      }
    },

    // Observe current connections
    onConnection: _ember['default'].observer('connectionsCount', function () {
      this.get('emag').log('[OpenTok Service] Total connections:' + this.get('connectionsCount'));
      this.trigger('connections-changed');
    }),

    // Connect to session. This happens while user joins a workout.
    connectSession: function connectSession(openTokSession) {
      var _this = this;

      if (openTokSession) {
        this.set('opentokSession', openTokSession);
        this.getOpenTokToken(openTokSession, function (opentokToken) {
          _this.set('session', OT.initSession(_this.get('apiKey'), openTokSession));
          _this._addSessionListeners();
          _this._addSubscriberListeners();
          _this.get('session').connect(opentokToken, function (error) {
            if (error) {
              _this.get('emag').log("[OpenTok Service] Error connecting to session: ", error.code, error.message);
              _this.set('isSessionConnected', false);
              _this.trigger('session-disconnected');
            } else {
              //this.connectPublisher();
              //this.addSignalingListener('player');
            }
          });
        }, function (error) {
          _this.get('emag').log('[OpenTok Service] ' + error.message);
        });
      } else {
        this.get('emag').log('[OpenTok Service] Invalid workout opentok session.');
      }
    },

    _addSessionListeners: function _addSessionListeners() {
      var _this2 = this;

      var _session = this.get('session');
      _session.on({
        connectionCreated: function connectionCreated(event) {
          if (event.connection.connectionId != _session.connection.connectionId) {
            _this2.get('emag').log('[OpenTok Service] Another client connected with id ' + event.connection.connectionId);
          } else {
            _this2.get('emag').log("[OpenTok Service] Connected to the session with id: " + event.connection.connectionId);
            _this2.set('isSessionConnected', true);
            _this2.trigger('session-connected');
          }
        },

        connectionDestroyed: function connectionDestroyed(event) {
          _this2.get('emag').log('[OpenTok Service] A client disconnected. ' + _this2.connectionsCount + ' total.');
          //this.decrementProperty('connectionsCount');
          _this2.trigger('session-disconnected');
        },

        sessionDisconnected: function sessionDisconnected(event) {
          // The event is defined by the SessionDisconnectEvent class
          _this2.get('emag').log('[OpenTok Service] Disconnected from the session.');
          _this2.set('opentokSession', '');
          _this2.set('connectionsCount', 0);
          _this2.set('isSessionConnected', false);
          _this2.set('subscribedToSession', false);
          _this2.set('isPublisherConnected', false);
          _this2.get('subscribersStreams').clear();
          _this2.get('subscribers').clear();
          _session.off();
          _this2.set('publisher', null);
          _this2.set('session', null);
          if (event.reason == 'networkDisconnected') {
            _this2.get('emag').log('[OpenTok Service] Your network connection terminated.');
            _this2.trigger('session-network-disconnected');
          } else {
            _this2.trigger('session-disconnected');
          }
        }
      });
    },

    _addSubscriberListeners: function _addSubscriberListeners() {
      var _this3 = this;

      var _session = this.get('session');
      _session.on({
        /*When a stream, other than your own, leaves a session the Session object dispatches a streamDestroyed event*/
        streamCreated: function streamCreated(event) {
          if (_this3.get('isPublisherConnected')) {
            _this3.set('subscribedToSession', true);
            _this3._addSubscriberStream(event.stream);
            _this3.trigger('subscriber-connected');
          }
          _this3.get('subscribersStreams').pushObject(event.stream);
        },

        streamDestroyed: function streamDestroyed(event) {
          if (_this3.get('isPublisherConnected')) {
            _this3.get('emag').log("[OpenTok Service] Subscriber's stream stopped. Reason: " + event.reason);
            _session.unsubscribe(event.stream);
            _this3.decrementProperty('connectionsCount');
            _this3.trigger('subscriber-disconnected');
          }
          _this3.get('subscribersStreams').removeObject(event.stream);
        }
      });
    },

    _addSubscriberStream: function _addSubscriberStream(stream) {
      var _this4 = this;

      this.get('emag').log("[OpenTok Service] New subscriber stream in the session: " + stream.name);
      var _session = this.get('session');
      var _subscriber = _session.subscribe(stream, 'users-videos', this.subscriberOptions, function (error) {
        if (error) {
          _this4.get('emag').log(error);
          _this4.set('subscribedToSession', false);
        } else {
          if (_this4.get('connectionsCount') < 3) {
            _this4.incrementProperty('connectionsCount');
            _this4.get('emag').log('[OpenTok Service] Subscriber added.');
          } else {
            _this4.get('emag').log('[OpenTok Service] Max users limit(3) reached!');
            _session.unsubscribe(stream);
          }
        }
      });
      this.get('subscribers').pushObject(_subscriber);
    },

    // Disconnect from session. This happens while user leaves a workout.
    disconnectSession: function disconnectSession() {
      this.get('session').disconnect();
    },

    connectPublisher: function connectPublisher() {
      var _this5 = this;

      var publisherOptions = {
        insertMode: 'append',
        width: '640',
        height: '480',
        name: this.get('auth.currentUser.firstName'),
        style: {
          nameDisplayMode: 'on',
          buttonDisplayMode: 'on'
        }
      };

      var publisher = OT.initPublisher('users-videos', publisherOptions);
      var _session = this.get('session');
      //publisher.setStyle({nameDisplayMode: "on"});
      publisher.on({
        streamCreated: function streamCreated(event) {
          //this.get('emag').log(this.get('subscribersStreams'));
          if (!_this5.set('subscribedToSession') && _this5.get('subscribersStreams.length')) {
            _this5.get('subscribersStreams').forEach(function (stream) {
              _this5._addSubscriberStream(stream);
            });
            _this5.set('subscribedToSession', true);
          }

          /* Track intercom event */
          _this5.get('analytics.intercom').trackEvent('camera-enabled');
          _this5.get('analytics.google').trackEvent('social', 'camera_on', 'feature_upscale');

          _this5.get('emag').log("[OpenTok Service] Publisher started streaming.");
        },
        streamDestroyed: function streamDestroyed(event) {
          if (_this5.get('subscribers.length')) {
            _this5.get('subscribers').forEach(function (stream) {
              _session.unsubscribe(stream);
            });
          }
          _this5.get('subscribers').clear();

          _this5.get('emag').log("[OpenTok Service] Publisher stopped streaming. Reason: " + event.reason);
          _this5.set('isPublisherConnected', false);
          _this5.set('subscribedToSession', false);
          _this5.set('connectionsCount', 0);
          if (_this5.get('publisher')) {
            _this5.get('publisher').off();
            _this5.set('publisher', null);
          }

          _this5.trigger('publisher-disconnected');
        },
        accessAllowed: function accessAllowed(event) {
          _this5.get('emag').log('[OpenTok Service] Publisher has granted access to the camera and mic.');
          _this5.incrementProperty('connectionsCount');
          _this5.set('isPublisherConnected', true);
          _this5.trigger('publisher-connected');
        },
        accessDenied: function accessDenied(event) {
          _this5.get('emag').log('[OpenTok Service] Publisher has denied access to the camera and mic.');
          //this.disconnectSession();
        },
        accessDialogOpened: function accessDialogOpened(event) {
          _this5.get('emag').log('[OpenTok Service] The Allow/Deny dialog box is opened.');
        },
        accessDialogClosed: function accessDialogClosed(event) {
          _this5.get('emag').log('[OpenTok Service] The Allow/Deny dialog box is closed.');
        }
      });
      this.set('publisher', publisher);
      _session.publish(publisher);
    },

    disconnectPublisher: function disconnectPublisher() {
      var _session = this.get('session');
      if (_session) {
        _session.unpublish(this.get('publisher'));
      }
      this.get('publisher').destroy();
    },

    // Setup a custom type signal. example: this.addSignalingListener('player'); Signals for players
    addSignalingListener: function addSignalingListener(type) {
      var _this6 = this;

      if (type) {
        (function () {
          var _session = _this6.get('session');
          _session.on('signal:' + type, function (event) {
            if (_session.connection.connectionId != event.from.id) {
              _this6.get('emag').log("[OpenTok Service] Received " + type + " signal from connection " + event.from.id);
              _this6.trigger(type + '-signal', event.data);
            }
          });
        })();
      }
    },

    removeSignalingListener: function removeSignalingListener(type) {
      if (type) {
        this.get('session').off('signal:' + type);
      }
    },

    // Example: this.get('opentokService').sendSignal('player', {action: "play", time: 100});
    sendSignal: function sendSignal(type, data) {
      var _this7 = this;

      this.get('session').signal({
        type: type,
        data: data
      }, function (error) {
        if (error) {
          _this7.get('emag').log("[OpenTok Service] Signal error: " + error.message);
        } else {
          _this7.get('emag').log("[OpenTok Service] Signal sent");
        }
      });
    },

    /*
      Get session token
    */
    getOpenTokToken: function getOpenTokToken(workoutId, cb_success, cb_error) {
      var url = '/getOpenTokToken2/' + workoutId;
      this.get('emagNet').getUrl(url, function (response) {
        cb_success(response.message);
      }, function (response) {
        cb_error(response.responseJSON.message);
      });
    }
  });
});