define('hoolio-www/user/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    firstName: _emberData['default'].attr('string', { defaultValue: '' }),
    lastName: _emberData['default'].attr('string', { defaultValue: '' }),
    email: _emberData['default'].attr('string'),
    emailStatus: _emberData['default'].attr('string'),
    lastLogin: _emberData['default'].attr('date'),
    joined: _emberData['default'].attr('date'),
    lastGoalUpdate: _emberData['default'].attr('date'),
    newsletter: _emberData['default'].attr('boolean', { defaultValue: false }),
    fitschoolCompleted: _emberData['default'].attr('boolean', { defaultValue: false }),
    sms: _emberData['default'].attr('boolean', { defaultValue: false }),
    healthData: _emberData['default'].attr('object', { defaultValue: function defaultValue() {
        return {
          gender: 'male',
          age: 18,
          fitLevel: 1,
          height: 0
        };
      }
    }),
    datetimezone: _emberData['default'].attr('string', { defaultValue: '' }),
    photoUrl: _emberData['default'].attr('string', { defaultValue: "../assets/img/general/default-image-2.png" }),
    motto: _emberData['default'].attr('string', { defaultValue: '' }),
    weeklyGoal: _emberData['default'].attr('number', { defaultValue: 0 }),
    weeklyMetrics: _emberData['default'].attr('object'),

    /*
    *  Relationships
    * 
    */
    favoriteVideos: _emberData['default'].hasMany('video'),
    recentWorkouts: _emberData['default'].hasMany('workout', { inverse: null }),
    activeUserSubscriptions: _emberData['default'].hasMany('user-subscription'),
    activeFitnessPlan: _emberData['default'].belongsTo('user-fitnessplan'),
    userPreference: _emberData['default'].belongsTo('user-preference'),

    /*
    *  Computed attribures
    * 
    */
    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    reachedWeeklyGoal: _ember['default'].computed('weeklyGoal', 'weeklyMetrics.moves', function () {
      return this.get('weeklyMetrics.moves') >= this.get('weeklyGoal');
    }),

    hasPhoto: _ember['default'].computed('photoUrl', function () {
      return this.get('photoUrl') != "../assets/img/general/default-image-2.png";
    }),

    hasPremiumPlan: _ember['default'].computed('activeUserSubscriptions', function () {
      var plans = this.get('activeUserSubscriptions').filter(function (plan, index, enumerable) {
        return plan.get('premium') === true && plan.get('status') != 'canceled';
      });
      return plans.length > 0;
    }),

    trialDaysLeft: _ember['default'].computed('activeUserSubscriptions', function () {
      var plans = this.get('activeUserSubscriptions').filter(function (plan, index, enumerable) {
        return plan.get('premium') === true;
      });

      if (plans[0]) {
        return plans[0].get('trialDaysLeft');
      } else {
        return 0;
      }
    }),

    activePremiumPlan: _ember['default'].computed('activeUserSubscriptions', function () {
      var plans = this.get('activeUserSubscriptions').filter(function (plan, index, enumerable) {
        return plan.get('premium') === true;
      });

      if (plans[0]) {
        return plans[0];
      } else {
        return null;
      }
    }),

    canCancelPremiumPlan: _ember['default'].computed('activePremiumPlan', function () {
      var plan = this.get('activePremiumPlan');
      if (plan && plan.get('application') === 'web') {
        return true;
      } else {
        return false;
      }
    })

  });
});