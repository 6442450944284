define('hoolio-www/components/retina-image/component', ['exports', 'ember'], function (exports, _ember) {
  /* globals retinajs */

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'img',
    classNames: ['retina-image'],
    attributeBindings: ['attrs.path:src', 'attrs.width:width', 'attrs.height:height', 'value:data-rjs'],
    value: 3,

    onInit: _ember['default'].on('didInsertElement', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, 'afterRenderEvent');
    }),

    afterRenderEvent: function afterRenderEvent() {
      if (this.attrs.path) {
        _ember['default'].run.next(this, function () {
          retinajs();
          _ember['default'].$("[data-rjs]").removeAttr("data-rjs");
        });
      }
    }
  });
});