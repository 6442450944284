define('hoolio-www/trainer/route', ['exports', 'ember', 'hoolio-www/mixins/reset-scroll'], function (exports, _ember, _hoolioWwwMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsResetScroll['default'], {
    session: _ember['default'].inject.service(),

    /*  beforeModel(transition) {
        if(!this.get('session.isAuthenticated')) {
          this.get('session').set('attemptedTransition', transition);
        }
      },*/

    model: function model(params) {
      return this.store.findRecord('trainer', params.id);
    },

    afterModel: function afterModel(model, transition) {
      _ember['default'].$(document).attr('title', 'hoolio - Trainer');

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
        this.controller.set('wo', 'wo-0');
        this.controller.set('playPromoVideo', false);
      }
    }
  });
});