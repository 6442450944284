define('hoolio-www/components/bs-modal/component', ['exports', 'ember'], function (exports, _ember) {
  /*global $ */

  exports['default'] = _ember['default'].Component.extend({
    _defaultOptions: {
      closeOnConfirm: true,
      backdrop: true
    },
    disableOk: false,
    hideOk: _ember['default'].computed.empty('attrs.okText'),
    hideCancel: _ember['default'].computed.empty('attrs.closeText'),
    hideFooter: _ember['default'].computed.and('hideOk', 'hideCancel'),

    okBtnStatusCheck: (function () {
      if (this.get('disableOk')) {
        this.$('#ok-btn').attr('disabled', 'disabled');
      } else {
        this.$('#ok-btn').removeAttr('disabled');
      }
    }).observes('disableOk'),

    onAttrs: _ember['default'].on('didReceiveAttrs', function () {
      if (this.attrs.disableOk) {
        this.set('disableOk', this.attrs.disableOk);
      }
    }),

    onInitialize: _ember['default'].on('didInsertElement', function () {
      if (this.get('disableOk')) {
        this.$('#ok-btn').attr('disabled', 'disabled');
      } else {
        this.$('#ok-btn').removeAttr('disabled');
      }

      // Assign modal listeners
      this.$('#hoolio-modal').on('show.bs.modal', (function () {
        this.sendAction('onShow');
      }).bind(this));

      this.$('#hoolio-modal').on('shown.bs.modal', (function () {
        this.sendAction('onShown');
      }).bind(this));

      this.$('#hoolio-modal').on('hide.bs.modal', (function () {
        this.sendAction('onHide');
      }).bind(this));

      this.$('#hoolio-modal').on('hidden.bs.modal', (function () {
        this.sendAction('onHidden');
      }).bind(this));

      //Initialize modal
      var _defaultOptions = this.get('_defaultOptions');
      var _options = this.attrs.options ? _ember['default'].$.extend({}, _defaultOptions, this.attrs.options) : _defaultOptions;
      this.$('#hoolio-modal').modal(_options);
    }),

    onWillDestroyElement: (function () {
      this.sendAction('onDestroy');
      _ember['default'].$('body').css('padding-right', '0');
      _ember['default'].$('body').removeClass('modal-open'); // Make sure that modal removed
      _ember['default'].$('.modal-backdrop.in').remove();
    }).on('willDestroyElement'),

    actions: {
      ok: function ok() {
        if (this.attrs.onOk) {
          this.attrs.onOk();
        }
        if (this.get('options.closeOnConfirm')) {
          this.$('#hoolio-modal').modal('hide');
        }
      },
      cancel: function cancel() {
        if (this.attrs.onCancel) {
          this.attrs.onCancel();
        }
        this.$('#hoolio-modal').modal('hide');
      }
    }
  });
});