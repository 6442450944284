define('hoolio-www/components/bs-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bs-dropdown-component'],

    actions: {
      setValue: function setValue(option) {
        if (this.attrs.onSelect) {
          this.attrs.onSelect(option);
        } else {
          console.error('No onSelect action provided.');
        }
      },

      onDropdownOpen: function onDropdownOpen() {
        this.$('.dropdown-menu li.selected a').focus();
      }
    }
  });
});