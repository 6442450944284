define('hoolio-www/services/emag-social', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    emagNet: _ember['default'].inject.service(),

    credentials: _ember['default'].Object.create({
      user_id: null,
      user_email: null,
      session_key: null,
      provider: null
    }),

    // Clear localStorage depending on whether "remember me" is checked or not
    clearCredentialsOnWindowClose: function clearCredentialsOnWindowClose(value) {
      if (value) {
        window.addEventListener('unload', function (event) {
          window.localStorage.removeItem('ember_simple_auth:session');
        });
      } else {
        window.addEventListener('unload', function (event) {});
      }
    },

    setCredentials: function setCredentials(_ref) {
      var user_id = _ref.user_id;
      var user_email = _ref.user_email;
      var session_key = _ref.session_key;
      var _ref$provider = _ref.provider;
      var provider = _ref$provider === undefined ? 'hoolio' : _ref$provider;

      this.get('credentials').setProperties({
        user_id: user_id,
        user_email: user_email,
        session_key: session_key,
        provider: provider
      });
    },

    /*
      Validate user session
    */
    validate: function validate(cb_success, cb_error) {
      var url = '/validate';
      this.get('emagNet').getUrl(url, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error(response);
      });
    },

    /*
      Get user
    */
    getUser: function getUser(id, cb_success, cb_error) {
      var url = '/users/' + id;
      this.get('emagNet').getUrl(url, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error(response);
      });
    },

    /*
      Login user
    */
    login: function login(data, cb_success, cb_error) {
      var _this = this;

      var url = '/login';
      this.get('emagNet').postUrl(url, data, function (response) {
        _this.setCredentials({ user_id: response.user._id, user_email: response.user.email, session_key: response.token });
        cb_success(_this.get('credentials'));
      }, function (response) {
        cb_error(response);
      });
    },

    /*
      Register new user
    */
    register: function register(data, cb_success, cb_error) {
      var _this2 = this;

      var url = '/signup';
      this.get('emagNet').postUrl(url, data, function (response) {
        _this2.setCredentials({ user_id: response.user.id, user_email: response.user.email, session_key: response.token });
        cb_success(_this2.get('credentials'));
      }, function (response) {
        cb_error("Registration failed. Please try again.");
      });
    },

    /*
      Activate user's account
    */
    activateUser: function activateUser(data, cb_success, cb_error) {
      var url = '/email/verification';
      this.get('emagNet').postUrl(url, data, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error("Activation failed. Please try again.");
      });
    },

    /*
      Activate user's account
    */
    resendActivationEmail: function resendActivationEmail(cb_success, cb_error) {
      var url = '/email/verification/reset';
      this.get('emagNet').postUrl(url, {}, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error("Resend activation email failed. Please try again.");
      });
    },

    /*
      Log out user
    */
    logout: function logout(cb_success, cb_error) {
      var _this3 = this;

      var url = '/signout';
      this.get('emagNet').getUrl(url, function (response) {
        _this3.setCredentials({ user_id: null, session_key: null, provider: null });
        cb_success(response);
      }, function (response) {
        cb_error(response);
      });
    },

    /*
      Request password reset
    */
    requestPasswordReset: function requestPasswordReset(email, cb_success, cb_error) {
      var url = '/reset-password';
      this.get('emagNet').postUrl(url, { email: email }, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error(response);
      });
    },

    /*
      Request password reset
    */
    resetPassword: function resetPassword(token, password, cb_success, cb_error) {
      var url = '/reset-password/' + token;
      this.get('emagNet').postUrl(url, { password: password }, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error(response);
      });
    },

    /*
      Request password reset
    */
    changePassword: function changePassword(oldPassword, newPassword, cb_success, cb_error) {
      var url = '/change-password';
      this.get('emagNet').postUrl(url, { oldPassword: oldPassword, newPassword: newPassword }, function (response) {
        cb_success(response);
      }, function (response) {
        cb_error(response);
      });
    },

    /*
      User's social login
    */
    socialLogin: function socialLogin(data, cb_success, cb_error) {
      var _this4 = this;

      var url = '/login/facebook';
      this.get('emagNet').postUrl(url, data, function (response) {
        _this4.setCredentials({ user_id: response.user._id, user_email: response.user.email, session_key: response.token });
        cb_success(_this4.get('credentials'));
      }, function (response) {
        cb_error("Login failed. Please try again.");
      });
    },

    /*
      Get mongo objectId
    */
    getObjectId: function getObjectId(cb_success, cb_error) {
      var url = '/utils/mongoIdString';
      this.get('emagNet').getUrl(url, function (response) {
        cb_success(response.message);
      }, function (response) {
        cb_error(response);
      });
    }
  });
});