define('hoolio-www/studio/route', ['exports', 'ember', 'hoolio-www/mixins/authenticated-route'], function (exports, _ember, _hoolioWwwMixinsAuthenticatedRoute) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsAuthenticatedRoute['default'], {
    pubnubService: _ember['default'].inject.service('pubnub'),
    tvService: _ember['default'].inject.service('tv'),
    opentok: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),

    activate: function activate() {
      var controller = this.controllerFor('application');
      controller.set('hideTopNavbar', true);
      controller.set('hideFooter', true);
      this.set('tvService.utcMode', true);
      _ember['default'].$('body').addClass('studio-page');
    },

    deactivate: function deactivate() {
      var appController = this.controllerFor('application');
      appController.set('hideTopNavbar', false);
      appController.set('hideFooter', false);
      this.set('tvService.utcMode', false);
      _ember['default'].$('body').removeClass('studio-page');
    },

    afterModel: function afterModel(model, transition) {
      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    model: function model(params) {
      return this.store.findRecord('studio', params.id);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      this.set('tvService.coreInfo', model);

      var studioSyncPb = this.get('pubnubService').getInstance('studioSyncPb').initialize({
        subscribeKey: this.get('config.studioPubnub.subscribeKey'),
        publishKey: this.get('config.studioPubnub.publishKey'),
        uuid: this.get('session.currentUser.id'),
        ssl: true
      });

      var chatPb = this.get('pubnubService').getInstance('chatPb').initialize({
        subscribeKey: this.get('config.chatPubnub.subscribeKey'),
        publishKey: this.get('config.chatPubnub.publishKey'),
        uuid: this.get('session.currentUser.id'),
        ssl: true
      });

      controller.set('studioSyncPb', studioSyncPb);
      controller.set('chatPb', chatPb);

      controller.set('viewLayout', 'horizontal-layout');
      controller.set('isChatOpen', false);
      controller.set('isGuideOpen', false);

      if (!this.get('opentok.isSessionConnected') && model.get('tokboxSession')) {
        this.get('opentok').connectSession(model.get('tokboxSession'));
      } else {
        throw "[Studio route] Invalid tokbox session id for this channel.";
      }

      controller.set('channelLoaded', true);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        var msg = this.get('session.currentUser.firstName') + " left the studio";
        var chatPb = this.get('pubnubService').getInstance('chatPb');
        if (chatPb.isConnectedToChannel("chat-" + this.get('tvService.coreInfo.id'))) {
          controller.set('bot.botUserData.photo', this.get('session.currentUser.photoUrl'));
          controller.get('bot').publish(chatPb, "chat-" + this.get('tvService.coreInfo.id'), msg);
        }

        this.get('pubnubService').clearInstance('studioSyncPb');
        this.get('pubnubService').clearInstance('chatPb');

        controller.set('studioSyncPb', null);
        controller.set('chatPb', null);

        controller.set('isChatOpen', false);
        controller.set('channelLoaded', false);

        //this.get('tvService').fetchChannel();
        this.get('tvService').clearAll();

        if (this.get('opentok.isSessionConnected') && this.get('opentok').isSupported()) {
          this.get('opentok').disconnectSession();
        }
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});