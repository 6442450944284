define('hoolio-www/trainer/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    browser: _ember['default'].inject.service(),

    queryParams: ['wo'],
    wo: "wo-0",
    playPromoVideo: false,

    defaultFitSliderOptions: {
      wrapAround: false
    },

    showPlayButton: _ember['default'].computed("model.promoVideos.[]", function () {
      return this.get('model.promoVideos.length') > 0;
    }),

    actions: {
      setActiveItem: function setActiveItem(item) {
        if (item) {
          this.set('wo', item);
        }
      },

      playPromoVideo: function playPromoVideo() {
        this.set('playPromoVideo', true);
      },

      resetValue: function resetValue() {
        this.set('playPromoVideo', false);
      }
    }
  });
});