define('hoolio-www/hooliometry/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    browser: _ember['default'].inject.service(),

    queryParams: ['name'],
    name: '',

    res: "",

    backgroundStyle: _ember['default'].computed("model.firstObject.backgroundImage", function () {
      return new _ember['default'].String.htmlSafe("background: url(" + this.get('model.firstObject.backgroundImage') + ") no-repeat center center;");
    }),

    resultsSliderOptions: {
      wrapAround: false
    },

    resultsRowTitle: _ember['default'].computed("searchResults", "selectedGroup", function () {
      var _selectedGroup = this.get('selectedGroup');
      if (_selectedGroup && _selectedGroup.groups) {
        return this.get('searchResults.length') + " workouts matching your '" + _selectedGroup.label + "' map";
      } else {
        return this.get('searchResults.length') + " workouts to level up your '" + _selectedGroup.label + "' MOVEs";
      }
    }),

    searchResults: _ember['default'].computed('selectedGroup', function () {
      var _selectedGroup = this.get('selectedGroup');
      if (_selectedGroup) {
        this.set('res', '');
        var pararms = {
          scoreboard: true,
          hooliometry: this.get('model.firstObject.id')
        };
        if (_selectedGroup.groups) {
          pararms[_selectedGroup.id] = '*';
        } else {
          pararms[_selectedGroup.parentId] = _selectedGroup.id;
        }
        return this.store.query('video', pararms);
      } else {
        return [];
      }
    }),

    actions: {
      // This is the top action for setting the active item for all dashboards fit-sliders (DDAU FTW).
      setActiveItem: function setActiveItem(item) {
        this.set('res', item);
      },

      onGroupSelection: function onGroupSelection(data) {
        this.set('selectedGroup', data);
      }
    }
  });
});