define('hoolio-www/services/video-chapters', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
    videoPlayerService: _ember['default'].inject.service('video-player'),
    emag: _ember['default'].inject.service(),

    chapters: [],

    activeChapter: null,
    activeChapterIndex: -1,

    prevChapter: _ember['default'].computed('activeChapterIndex', function () {
      var _chapters = this.get('chapters'),
          _activeChapterIndex = this.get('activeChapterIndex');

      if (_activeChapterIndex > 0) {
        return _chapters[_activeChapterIndex - 1];
      } else {
        return null;
      }
    }),

    nextChapter: _ember['default'].computed('activeChapterIndex', function () {
      var _chapters = this.get('chapters'),
          _activeChapterIndex = this.get('activeChapterIndex');

      if (_activeChapterIndex < _chapters.length - 1) {
        return _chapters[_activeChapterIndex + 1];
      } else {
        return null;
      }
    }),

    currentTimeValue: _ember['default'].computed.alias('videoPlayerService.currentTimeValue'),

    addEvents: function addEvents() {
      this.get('emag').log('[Chapter Service] Initializing events... Found ' + this.get('chapters').length + ' in total.');
      this.get('videoPlayerService').on('player-forward', this, this.triggerForwardAction).on('player-backward', this, this.triggerBackwardAction).on('player-seek', this, this.triggerSeekAction).on('player-ended', this, this.triggerEndedAction);

      this.addObserver('videoPlayerService.currentTimeValue', this, this.onTimeTick);
    },

    removeEvents: function removeEvents() {
      this.get('emag').log('[Chapter Service] Clearing events...');
      this.get('videoPlayerService').off('player-forward', this, this.triggerForwardAction).off('player-backward', this, this.triggerBackwardAction).off('player-seek', this, this.triggerSeekAction).off('player-ended', this, this.triggerEndedAction);

      this.removeObserver('videoPlayerService.currentTimeValue', this, this.onTimeTick);
    },

    lastMobileDataTime: 0,
    triggerSeekAction: function triggerSeekAction(data) {
      var _activeChapter = this.get('activeChapter'),
          _lastMobileDataTime = this.get('lastMobileDataTime'),
          _threshold = Math.abs(data.time - _lastMobileDataTime);
      if (_activeChapter && _threshold != 0) {
        var _currentTimeValue = this.get('videoPlayerService.currentTimeValue');
        if (data.time >= _currentTimeValue) {
          this.triggerForwardAction();
        } else if (data.time < _currentTimeValue) {
          this.triggerBackwardAction();
        }
        this.set('lastMobileDataTime', data.time);
      } else if (!_activeChapter && this.get('nextChapter')) {
        this.triggerForwardAction();
      }
    },

    triggerForwardAction: function triggerForwardAction() {
      var _nextChapter = this.get('nextChapter');
      if (_nextChapter) {
        this.get('videoPlayerService').trigger('player-chapter-seek', { time: _nextChapter.timeStamp });
        this.set('activeChapter', _nextChapter);
      } /* else {
         this.set('activeChapter', null);
         this.set('activeChapterIndex', -1);
        }*/
    },

    triggerBackwardAction: function triggerBackwardAction() {
      var _prevChapter = this.get('prevChapter');
      if (_prevChapter) {
        this.get('videoPlayerService').trigger('player-chapter-seek', { time: _prevChapter.timeStamp });
        this.set('activeChapter', _prevChapter);
        this.decrementProperty('activeChapterIndex');
      } /* else {
         this.set('activeChapter', null);
         this.set('activeChapterIndex', -1);
        }*/
    },

    triggerEndedAction: function triggerEndedAction() {
      this.set('activeChapter', null);
      this.set('activeChapterIndex', -1);
    },

    gotoChapter: function gotoChapter(chapterIndex) {
      var _chapters = this.get('chapters'),
          _chapter = _chapters[chapterIndex];

      if (_chapter) {
        this.get('videoPlayerService').trigger('player-chapter-seek', { time: _chapter.timeStamp });
        this.set('activeChapter', _chapter);
        this.set('activeChapterIndex', chapterIndex);
      }
    },

    onTimeTick: function onTimeTick() {
      var _chapters = this.get('chapters'),
          _activeChapter = this.get('activeChapter'),
          _time = this.get('currentTimeValue'),
          _activeChapterIndex = this.get('activeChapterIndex'),
          _chapterIndex = 0,
          _chapter = null;

      if (!_activeChapter && _chapters.length) {
        if (_time >= _chapters[0].timeStamp) {
          this.set('activeChapter', _chapters[0]);
          this.set('activeChapterIndex', 0);
        } else {
          this.set('activeChapterIndex', -1);
        }
      } else {
        _chapter = _chapters.find(function (item, index, enumerable) {
          _chapterIndex = index;
          if (index > _activeChapterIndex && item != _activeChapter) {
            return _time >= item.timeStamp;
          }
        });

        if (_chapter) {
          this.set('activeChapter', _chapter);
          this.set('activeChapterIndex', _chapterIndex);
        }
      }
    },

    clear: function clear() {
      this.set('chapters', []);
      this.set('activeChapter', null);
      this.set('activeChapterIndex', -1);
      this.set('lastMobileDataTime', 0);
      this.removeEvents();
    }
  });
});