define('hoolio-www/components/browser-tab-status/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    ondidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, 'afterRenderEvent');
    }),

    afterRenderEvent: function afterRenderEvent() {
      var _this = this;

      window.onbeforeunload = function () {
        return _this.attrs.message;
      };
    },

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      window.onbeforeunload = null;
    })
  });
});