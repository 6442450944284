define('hoolio-www/helpers/full-date', ['exports', 'ember'], function (exports, _ember) {
  exports.fullDate = fullDate;

  /*globals moment*/

  function fullDate(date) {
    return moment(date[0]).format('Do MMMM YYYY, h:mm:ss a');
  }

  exports['default'] = _ember['default'].Helper.helper(fullDate);
});