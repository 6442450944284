define('hoolio-www/components/webrtc-container/component', ['exports', 'ember', 'ember-resize/mixins/resize-aware'], function (exports, _ember, _emberResizeMixinsResizeAware) {
  exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], {
    opentok: _ember['default'].inject.service(),

    classNames: ['webrtc-container'],
    classNameBindings: ['oneConnectedUser:one-user', 'twoConnectedUsers:two-users', 'threeConnectedUser:three-users', 'opentok.isPublisherConnected:publisher-connected'],

    keepVideosRatio: false,
    header: { isHeader: true },

    oneConnectedUser: _ember['default'].computed.equal('opentok.connectionsCount', 1),
    twoConnectedUsers: _ember['default'].computed.equal('opentok.connectionsCount', 2),
    threeConnectedUser: _ember['default'].computed.equal('opentok.connectionsCount', 3),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      if (this.get('opentok').isSupported()) {
        this.get('opentok').on('connections-changed', function () {
          _this.setPosition();
        });
      }
    }),

    debouncedDidResize: function debouncedDidResize(width, height, evt) {
      this.setPosition();
    },

    setPosition: function setPosition() {
      if (this.$()) {
        this.$().hide();
      }
      _ember['default'].run.next(this, function () {
        var _publisher = this.get('opentok.publisher');
        var _playerContainerHeight = _ember['default'].$('.player-container').height();
        var _maxPlayerWidth = undefined;
        if (this.attrs && this.attrs.isFromYoutube) {
          _maxPlayerWidth = _ember['default'].$('.youtube-player').width();
        } else {
          _maxPlayerWidth = _ember['default'].$('.kaltura-player').width();
        }

        _maxPlayerWidth = _maxPlayerWidth ? _maxPlayerWidth : window.innerWidth * 85 / 100;

        if (_publisher) {
          if (this.attrs.viewLayout === 'horizontal-layout') {
            var userVideoWidth = window.innerWidth - _maxPlayerWidth;
            if (this.get('opentok.connectionsCount') === 1) {
              _publisher.element.style.width = userVideoWidth + 'px';

              if (this.get('keepVideosRatio')) {
                _publisher.element.style.height = userVideoWidth / 1.33 + 'px';
              } else {
                _publisher.element.style.height = _playerContainerHeight + 'px';
              }
            } else if (this.get('opentok.connectionsCount') > 1) {
              var connections = this.get('opentok.connectionsCount');
              _publisher.element.style.height = window.innerHeight / connections + 'px';
              this.$(".OT_subscriber").height(window.innerHeight / connections);

              if (this.get('keepVideosRatio')) {
                _publisher.element.style.width = userVideoWidth + 'px';
                this.$(".OT_subscriber").width(userVideoWidth);
                this.$(".OT_subscriber").css('max-width', '100%');
                this.$(".OT_subscriber").css('max-height', userVideoWidth / 1.33 + 'px');
              }
            }

            if (this.get('keepVideosRatio')) {
              _publisher.element.style['max-height'] = userVideoWidth / 1.33 + 'px';
              _publisher.element.style['max-width'] = '100%';
            }
          } else {
            var userVideoHeight = window.innerHeight - _playerContainerHeight;
            if (this.get('opentok.connectionsCount') === 1) {
              _publisher.element.style.height = userVideoHeight + 'px';
              if (this.get('keepVideosRatio')) {
                _publisher.element.style.width = userVideoHeight * 1.33 + 'px';
              } else {
                _publisher.element.style.width = _maxPlayerWidth + 'px';
              }
            } else if (this.get('opentok.connectionsCount') > 1) {
              var connections = this.get('opentok.connectionsCount');
              _publisher.element.style.height = userVideoHeight + 'px';
              _publisher.element.style.width = Math.floor(window.innerWidth / connections) + 'px';
              this.$(".OT_subscriber").width(Math.floor(window.innerWidth / connections));
              this.$(".OT_subscriber").height(userVideoHeight);

              if (this.get('keepVideosRatio')) {
                this.$(".OT_subscriber").css('max-width', userVideoHeight * 1.33 + 'px');
                this.$(".OT_subscriber").css('max-height', '100%');
              }
            }

            if (this.get('keepVideosRatio')) {
              _publisher.element.style['max-width'] = userVideoHeight * 1.33 + 'px';
              _publisher.element.style['max-height'] = '100%';
            }
          }
        }
        this.$().show();
      });
    },

    onLayoutToggle: _ember['default'].observer('attrs.viewLayout', function () {
      this.setPosition();
    }),

    onFullscreenToggle: _ember['default'].observer('fullscreen.isEnabled', function () {
      _ember['default'].run.later(this, this.setPosition, 1000);
    })
  });
});