define('hoolio-www/password-modal/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    emagSocial: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    isLoading: false,

    actions: {
      changePassword: function changePassword() {
        var _this = this;

        this.set('errorMessage', '');
        this.set('isLoading', true);

        if (_ember['default'].$('#password-modal-form').parsley().validate()) {
          (function () {
            var flashMessages = _ember['default'].get(_this, 'flashMessages');
            _this.get('emagSocial').changePassword(_this.get('oldPassword'), _this.get('newPassword'), function (response) {
              flashMessages.success('Your password has been changed successfully.');
              _this.setProperties({ oldPassword: '', newPassword: '', confNewPassword: '' });
              _this.set('isLoading', false);
              _this.send('dispose_modal');
            }, function (msg) {
              _this.set('isLoading', false);
              _this.set('errorMessage', msg);
            });
          })();
        } else {
          this.set('isLoading', false);
        }
      },

      dispose_modal: function dispose_modal() {
        this.setProperties({ oldPassword: '', newPassword: '', confNewPassword: '' });

        if (this.get('model.closed_cb')) {
          this.get('model.closed_cb')();
        }
        this.set('errorMessage', '');
        this.set('isLoading', false);
        this.send('removeModal');
      }
    }
  });
});