define('hoolio-www/user-subscription/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic attribures
    * 
    */
    cancelAtPeriodEnd: _emberData['default'].attr('boolean'),
    currentPeriodEnd: _emberData['default'].attr('date'),
    currentPeriodStart: _emberData['default'].attr('date'),
    customerId: _emberData['default'].attr('string'),
    planId: _emberData['default'].attr('string'),
    stripeId: _emberData['default'].attr('string'),
    premium: _emberData['default'].attr('boolean'),
    status: _emberData['default'].attr('string'),
    quantity: _emberData['default'].attr('number'),
    trialEnd: _emberData['default'].attr('date'),
    trialStart: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    syncedAt: _emberData['default'].attr('date'),
    startedAt: _emberData['default'].attr('date'),
    source: _emberData['default'].attr('string'),
    metadata: _emberData['default'].attr('object'),
    application: _emberData['default'].attr('string'),

    /*
    *  Relationships
    * 
    */
    user: _emberData['default'].belongsTo('user'),
    subscriptionPlan: _emberData['default'].belongsTo('subscription-plan'),

    /*
    *  Computed attribures
    * 
    */
    trialDaysLeft: _ember['default'].computed('trialEnd', function () {
      var days = moment(this.get('trialEnd')).diff(moment(), 'days');
      if (days > 0) {
        return moment(this.get('trialEnd')).diff(moment(), 'days');
      } else {
        return 0;
      }
    })
  });
});