define('hoolio-www/components/user-main-secondary/component', ['exports', 'ember'], function (exports, _ember) {
  /* globals jQuery*/
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    modals: _ember['default'].inject.service(),
    config: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),
    videoService: _ember['default'].inject.service('video'),
    flashMessages: _ember['default'].inject.service(),

    recentWorkoutsSorting: ['scheduled:desc'],
    sortedRecentWorkoutsDesc: _ember['default'].computed.sort('session.currentUser.recentWorkouts', 'recentWorkoutsSorting'),

    userMoves: 0,
    movesWeeklyGoal: 0,
    loadingUserMetrics: false,
    isOnCurrentWeek: false,

    showVideoPreview: false,
    showUpgradeInfo: false,

    videosSliderOptions: {
      wrapAround: false,
      pageDots: false
    },

    introSliderOptions: {
      wrapAround: true,
      setGallerySize: false,
      freeScroll: false,
      pageDots: false,
      prevNextButtons: true,
      autoPlay: 7000,
      cellSelector: '.tip'
    },

    tipSlides: _ember['default'].A([{
      title: "Did you know?",
      body: "You can workout live with a friend with any video. Just start a workout, invite a friend and you're ready to go.",
      img: "/assets/img/dashboard/ic_add_buddy.png"
    }, {
      title: "Tired of looking what to do next?",
      body: "Hey! We've been there... <br>That's why we created fitness plans.<br>Just pick one based on what you want to achieve and we'll get you there!",
      img: "/assets/img/dashboard/fitness_plans_maze.png"
    }, {
      title: "Did you know?",
      body: "Ever wanted to have a mirror to check if you are doing it right? We hear you!<br> Just enable the Posture Camera when in a workout.",
      img: "/assets/img/dashboard/posture.png"
    }, {
      title: "Did you know?",
      body: "You can monitor your heart rate in hoolio through our iOS companion app!",
      img: "/assets/img/dashboard/heart.png"
    }]),

    shuffledTipSlides: _ember['default'].computed('tipSlides.[]', function () {
      return this.shuffle(this.get('tipSlides'));
    }),

    shuffle: function shuffle(array) {
      var i = 0,
          j = 0,
          temp = null;

      for (i = array.length - 1; i > 0; i -= 1) {
        j = Math.floor(Math.random() * (i + 1));
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    },

    selectedWorkout: null,
    promoWorkout: null,
    showIntro: true,

    onRecentWorkouts: _ember['default'].on('didInsertElement', _ember['default'].observer('session.currentUser.recentWorkouts.[]', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        var _firstRecentWorkout = this.get('session.currentUser.recentWorkouts.firstObject');
        if (_firstRecentWorkout) {
          this.send('skipIntro');
          this.set('selectedWorkout', this.get('session.currentUser.recentWorkouts.firstObject'));
        } else {
          this.set('showIntro', typeof window.localStorage['showUserIntro-' + this.get('session.currentUser.id')] != 'undefined' ? window.localStorage['showUserIntro-' + this.get('session.currentUser.id')] === 'true' : true);
          // 1_1p1tt03d = Trending Now playlist
          this.get('store').findRecord("playlist", "1_1p1tt03d").then(function (data) {
            data.get('videos').then(function (videos) {
              _this.set('promoWorkout', videos.get('lastObject'));
            });
          });
        }
      });
    })),

    onUserMetrics: _ember['default'].on('didInsertElement', _ember['default'].observer('session.currentUser.weeklyGoal', 'session.currentUser.weeklyMetrics', function () {
      this.set('userMoves', this.get('session.currentUser.weeklyMetrics.moves') || 0);
      this.set('movesWeeklyGoal', this.get('session.currentUser.weeklyGoal') || 0);
    })),

    actions: {
      joinWorkout: function joinWorkout() {
        var _this2 = this;

        if (this.get('selectedWorkout')) {
          this.get('videoService').getOrCreateWorkout(this.get('selectedWorkout.video'), function (workout) {
            _this2.get('router').transitionTo('workout', workout);
          });
        } else if (this.get('promoWorkout')) {
          this.get('videoService').getOrCreateWorkout(this.get('promoWorkout'), function (workout) {
            _this2.get('router').transitionTo('workout', workout);
          });
        }
      },

      gotoFitnessplans: function gotoFitnessplans() {
        this.get('router').transitionTo('fitnessplans');
      },

      skipIntro: function skipIntro() {
        this.set('showIntro', false);
        if (typeof window.localStorage != 'undefined') {
          window.localStorage['showUserIntro-' + this.get('session.currentUser.id')] = false;
        }
      },

      setSelectedWorkout: function setSelectedWorkout(videoIndex, listId, videoElement, item) {
        this.set('showVideoPreview', false);
        this.set('showUpgradeInfo', false);
        if (item) {
          this.set('selectedWorkout', item);
        }
      },

      onDateChange: function onDateChange(startingWeek, endingWeek) {
        var _this3 = this;

        _ember['default'].set(this, 'loadingUserMetrics', true);
        if (moment().isAfter(endingWeek)) {
          _ember['default'].set(this, 'isOnCurrentWeek', false);
          this.get('emag').getUserMetrics(startingWeek.unix(), endingWeek.unix(), function (data) {
            //console.log(data);
            if (_ember['default'].get(_this3, 'loadingUserMetrics')) {
              _ember['default'].set(_this3, 'userMoves', data.weeklyMetric.moves);
              _ember['default'].set(_this3, 'loadingUserMetrics', false);
            }
          }, function (error) {
            _this3.get('emag').error('[User metrics] ' + error);
            _ember['default'].set(_this3, 'loadingUserMetrics', false);
          });
        } else {
          _ember['default'].set(this, 'isOnCurrentWeek', true);
          _ember['default'].set(this, 'userMoves', this.get('session.currentUser.weeklyMetrics.moves') || 0);
          _ember['default'].set(this, 'loadingUserMetrics', false);
        }
      },

      previewVideo: function previewVideo() {
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        if (this.get('session.currentUser')) {
          this.set('showVideoPreview', true);
        } else {
          flashMessages.danger('Please register in order to perform this action.');
          this.get('modals').trigger('pricing');
        }
      },

      showUpgradeInfo: function showUpgradeInfo() {
        this.set('showVideoPreview', false);
        this.set('showUpgradeInfo', true);
      },

      showPricingModal: function showPricingModal() {
        this.get('modals').trigger('pricing');
      }
    }
  });
});