define('hoolio-www/components/tile-wall/component', ['exports', 'ember', 'ember-resize/mixins/resize-aware'], function (exports, _ember, _emberResizeMixinsResizeAware) {
  exports['default'] = _ember['default'].Component.extend(_emberResizeMixinsResizeAware['default'], {
    classNames: ['tile-wall-component', 'clearfix'],
    classNameBindings: ['isPortrait'],
    isPortrait: false,
    resizeWidthSensitive: true,
    resizeHeightSensitive: false,

    init: function init() {
      var _this = this;

      this._super();
      this.get('resizeService').on('didResize', function (event) {
        _this.checkPortrait(window.innerWidth, window.innerHeight);
      });

      this.checkPortrait(window.innerWidth, window.innerHeight);
    },

    /*debouncedDidResize(width, height, evt) {
      this.checkPortrait(width, height);
    },*/

    checkPortrait: function checkPortrait(width, height) {
      if (width - height < 100) {
        this.set('isPortrait', true);
      } else {
        this.set('isPortrait', false);
      }
    }
  });
});