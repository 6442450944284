define('hoolio-www/components/progress-bar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['progress-bar-component'],

    onInit: _ember['default'].on('didReceiveAttrs', function () {
      var _color = this.attrs.color || "blue";
      var _minValue = this.attrs.minValue || 0;
      var _maxValue = this.attrs.maxValue || 100;
      var _value = Math.floor(this.attrs.value * 100 / _maxValue) || 0;

      this.set('color', _color);
      this.set('minValue', _minValue);
      this.set('maxValue', _maxValue);
      this.set('value', _value);
      this.set('progessWidth', _ember['default'].String.htmlSafe('width:0%'));

      _ember['default'].run.next(this, function () {
        this.set('progessWidth', _ember['default'].String.htmlSafe('width:' + _value + '%'));
      });
    })
  });
});