define('hoolio-www/components/scroll-to/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',
    attributeBindings: ['href'],

    href: null, // Required

    scroll: _ember['default'].on('click', function (evt) {
      evt.stopPropagation();
      evt.preventDefault();
      var $el = _ember['default'].$(this.get('href'));
      _ember['default'].$('html, body').animate({
        scrollTop: $el.offset().top - 50
      });
    })
  });
});