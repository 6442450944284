define('hoolio-www/app', ['exports', 'ember', 'hoolio-www/resolver', 'ember-load-initializers', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwResolver, _emberLoadInitializers, _hoolioWwwConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    rootElement: '#hoolio-www',
    modulePrefix: _hoolioWwwConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _hoolioWwwConfigEnvironment['default'].podModulePrefix,
    Resolver: _hoolioWwwResolver['default'],
    babel: {
      compact: false
    },
    customEvents: {
      doubleTap: 'doubleClick',
      // remove support for default ember events
      //touchStart: null,
      //touchMove: null,
      //touchEnd: null,
      //touchCancel: null,
      //keyDown: null,
      //keyUp: null,
      //keyPress: null,
      //mouseDown: null,
      //mouseUp: null,
      //contextMenu: null,
      //click: null,
      //doubleClick: null,
      //mouseMove: null,
      //focusIn: null,
      //focusOut: null,
      //mouseEnter: null,
      //mouseLeave: null,
      //submit: null,
      //change: null,
      //input: null,
      dragStart: null,
      drag: null,
      dragEnter: null,
      dragLeave: null,
      dragOver: null,
      dragEnd: null,
      drop: null
    }
  });

  (0, _emberLoadInitializers['default'])(App, _hoolioWwwConfigEnvironment['default'].modulePrefix);

  _ember['default'].LinkComponent.reopen({
    attributeBindings: ['data-toggle', 'data-target']
  });

  _ember['default'].TextField.reopen({
    attributeBindings: ['data-stripe'],
    init: function init() {
      this._super();
      // Allow parsley data attributes
      return Object.keys(this).forEach((function (_this) {
        return function (key) {
          if (key.substr(0, 12) === "data-parsley") {
            return _this.get("attributeBindings").pushObject(key);
          }
        };
      })(this));
    }
  });

  // Automatically removes jQuery events on destruction of the component, to avoid leaks.
  /*Ember.Object.reopen({
    destroyEvents: Ember.on('willDestroyElement', function () {
      if (this.get('tagName') !== '') {
        this.$().off();
      }
    })
  });*/

  /*Ember.onerror = function(error) {  
    console.error(error);
    return true;
  };*/

  // Ember.Debug.registerDeprecationHandler((message, options, next) => {
  //   mute 'ember-application.injected-container' warnings
  //   console.log(options.id);
  //   if(options.id != 'ember-application.injected-container') {
  //     next(message, options);
  //   }
  // });

  // next is not called, so no warnings get the default behavior
  //Ember.Debug.registerWarnHandler(() => {});

  /*Ember.RSVP.on('error', function (error) {  
    console.error(error);
  });*/

  exports['default'] = App;
});