define('hoolio-www/password/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    emagSocial: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),

    queryParams: ['token'],
    token: "",
    requestedReset: false,
    isLoading: false,

    actions: {
      request_reset_password: function request_reset_password() {
        var _this = this;

        var isValid = _ember['default'].$('#main-form').parsley().validate();

        this.set('errorMessage', '');
        this.set('requestedReset', false);
        this.set('isLoading', true);

        if (isValid) {
          var email = this.get('email');
          email = email.toLowerCase();
          email = email.replace(/\s+/g, '');

          this.get('emagSocial').requestPasswordReset(email, function (response) {
            _this.set('email', '');
            _this.set('requestedReset', true);
            _this.set('isLoading', false);
          }, function (msg) {
            _this.set('isLoading', false);
            _this.set('errorMessage', msg);
          });
        } else {
          this.set('isLoading', false);
        }
      },

      reset_password: function reset_password() {
        var _this2 = this;

        var isValid = _ember['default'].$('#main-form').parsley().validate();

        this.set('errorMessage', '');
        this.set('isLoading', true);

        if (isValid && this.get('token')) {
          (function () {
            var flashMessages = _ember['default'].get(_this2, 'flashMessages');
            _this2.get('emagSocial').resetPassword(_this2.get('token'), _this2.get('newPassword'), function (response) {
              flashMessages.success('Your password has been changed successfully.');
              _this2.setProperties({ newPassword: '', confNewPassword: '' });
              _this2.set('isLoading', false);
              _this2.transitionToRoute('library', { queryParams: { login: true } });
            }, function (msg) {
              _this2.set('isLoading', false);
              _this2.set('errorMessage', msg);
            });
          })();
        } else {
          this.set('isLoading', false);
        }
      }
    }
  });
});