define('hoolio-www/user/route', ['exports', 'ember', 'hoolio-www/mixins/authenticated-route'], function (exports, _ember, _hoolioWwwMixinsAuthenticatedRoute) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsAuthenticatedRoute['default'], {
    session: _ember['default'].inject.service(),

    model: function model(params) {
      return this.get('session').loadCurrentUser(); //this.store.findRecord('user', params.id);
    },

    afterModel: function afterModel(model, transition) {
      _ember['default'].$(document).attr('title', 'hoolio - profile');

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);

      this.store.query('subscription-plan', { application: 'web' });

      /*controller.age = parseInt(model.get('healthData.age'));
      if(model.get('healthData.gender').toString() === "undefined") {
        model.set('healthData.gender', '');
        controller.gender = "";
      } else {
        controller.gender = model.get('healthData.gender').toString();
      }*/
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
        //this.controller.set('model.healthData', {age:this.controller.age, gender:this.controller.gender, height:0});
      }
    }
  });
});