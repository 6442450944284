define('hoolio-www/helpers/parse-time', ['exports', 'ember'], function (exports, _ember) {
  exports.parseUnixTime = parseUnixTime;

  function parseUnixTime(params) {
    var date = params[0];
    if (isNaN(new Date(date).getTime())) {
      date = moment().format('YYYY-MM-DD') + ' ' + date;
    }
    if (params[1]) {
      // format
      return moment(date).format(params[1]);
    } else {
      return moment(date).format("HH:mm");
    }
  }

  exports['default'] = _ember['default'].Helper.helper(parseUnixTime);
});