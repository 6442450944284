define('hoolio-www/components/bs-tab-panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['tab-pane', 'bs-tab-panel', 'fade in'],
    classNameBindings: ['isActive:active'],
    attributeBindings: ['role'],
    role: "tabpanel",

    isActive: _ember['default'].computed('attrs.selectedValue', function () {
      return this.attrs.value === this.attrs.selectedValue;
    })
  });
});