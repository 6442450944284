define('hoolio-www/components/studio-user-tooltip/component', ['exports', 'ember', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, _ember, _emberCliTooltipsterComponentsToolTipster) {
  exports['default'] = _emberCliTooltipsterComponentsToolTipster['default'].extend({
    classNames: ['studio-user-tooltip'],

    onReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {}),

    // define properties
    content: _ember['default'].computed('attrs.user', function () {
      if (this.attrs.user.state && this.attrs.user.state.userInfo) {
        return this.attrs.user.state.userInfo;
      } else {
        return 'No user data';
      }
    }),
    contentAsHTML: true,
    side: 'right',
    animation: 'grow',
    delay: 0
  });
});