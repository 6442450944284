define('hoolio-www/utils/pubnub-wrapper', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {

  /***********************
  Example usage:
  
  /route.js
  const chatPb = this.get('pubnubService').getInstance('chatPb').initialize({
    subscribeKey: 'sub-c-b335fe16-a1c3-11e6-9fcd-0619f8945a4f',
    publishKey: 'pub-c-a3bc2bfc-0444-474b-bb3a-0cf0c1f16687',
    uuid: this.get('session.currentUser.id'),
    ssl: true
  });
  controller.set('chatPb', chatPb);
  
  /component.js
  const studioPb = _pubnubService.getInstance('studioSyncPb');
  if(!studioPb.get('initialized')) {
    throw "[Pubnub] No studioSyncPb instance found. Initialize first one.";
  }
  
  if( !studioPb.isConnectedToChannel(channel[0]) ) {
    this.registerGlobalPubnubEvents();
  
    // Subscribe to the global studio channel
    studioPb.subscribe(channel, true);
  }
  
  registerGlobalPubnubEvents() {
    const studioPb = this.get('pubnubService').getInstance('studioSyncPb');
    studioPb.on('status:studio-', this.onStatusEvent.bind(this));
    studioPb.on('message:studio-', this.onMessageEvent.bind(this));
    studioPb.on('presence:studio-', this.onPresenceEvent.bind(this));
  }
  
  
  ************************/

  /*globals PubNub*/
  var pubnubInstance = _ember['default'].Object.extend(_ember['default'].Evented, {
    emag: _ember['default'].inject.service('emag'),

    pubnub: null,
    _channels: _ember['default'].A([]),
    userState: {},

    init: function init() {
      //console.log('init');
      //this.set('userState', {});
      this.set('_channels', _ember['default'].A([]));
      this.set('initialized', false);
    },

    initialize: function initialize(pubnubConfig) {
      if (this.get('initialized')) {
        if (_hoolioWwwConfigEnvironment['default'].emaginal.debug) {
          console.log('[Pubnub] ' + this.get('label') + ' pubnub instance already exists, so need to create a new one.');
        }
        return;
      }

      var _this = this;
      if (!pubnubConfig) {
        throw 'no configuration `pubnubConfig` provided!';
      }
      var pubnub = new PubNub(pubnubConfig);
      this.set('pubnub', pubnub);
      this.set('initialized', true);

      pubnub.addListener({
        status: function status(e) {
          if (e.affectedChannels.length) {
            e.affectedChannels.forEach(function (channel) {
              _this.trigger('status:' + channel, e);
            });
          }
        },
        message: function message(e) {
          _this.trigger('message:' + e.channel, e);
        },
        presence: function presence(e) {
          _this.trigger('presence:' + e.channel, e);
        }
      });

      return this;
    },

    isConnectedToChannel: function isConnectedToChannel(channel) {
      return this.get('_channels').indexOf(channel) > -1;
    },

    // Returns a promise
    publish: function publish(data, meta) {
      if (!data.channel) {
        throw "[Pubnub] Invalid channel";
      }

      //console.log( '[Pubnub] Publish to channel: ', data.channel);
      var pubnub = this.get('pubnub');
      return pubnub.publish(data);
    },

    subscribe: function subscribe(channels, withPresence) {
      var _this2 = this;

      if (!channels.length) {
        throw "[Pubnub] Invalid channels";
      }

      var pubnub = this.get('pubnub');
      pubnub.subscribe({
        channels: channels,
        withPresence: withPresence || false // also subscribe to presence instances.
      });

      channels.forEach(function (channel) {
        var index = _this2.get('_channels').indexOf(channel);
        if (index === -1) {
          _this2.get('_channels').pushObject(channel);
        }
      });

      if (_hoolioWwwConfigEnvironment['default'].emaginal.debug) {
        console.log('[Pubnub] ' + this.get('label') + ' pubnub instance subscribed to channels: ', this.get('_channels'));
      }
    },

    unsubscribe: function unsubscribe(channels) {
      var _this3 = this;

      if (!channels.length) {
        throw "[Pubnub] Invalid channels";
      }

      var pubnub = this.get('pubnub');
      pubnub.unsubscribe({
        channels: channels
      });

      channels.forEach(function (channel) {
        var index = _this3.get('_channels').indexOf(channel);
        if (index !== -1) {
          _this3.get('_channels').splice(index, 1);
        }
      });

      if (_hoolioWwwConfigEnvironment['default'].emaginal.debug) {
        console.log('[Pubnub] Unubscribed from channels: ', channels);
      }
    },

    unsubscribeAll: function unsubscribeAll() {
      var pubnub = this.get('pubnub');
      pubnub.unsubscribeAll();
      this.set('_channels', _ember['default'].A([]));
    },

    // Returns a promise
    getState: function getState(uuid, channels) {
      var pubnub = this.get('pubnub');
      return pubnub.getState({
        uuid: uuid,
        channels: channels
      });
    },

    // Returns a promise
    setState: function setState(channels) {
      var pubnub = this.get('pubnub'),
          state = this.get('userState'); // set userState before calling pubnub's setState
      return pubnub.setState({
        state: state,
        channels: channels
      });
    },

    // Returns a promise
    hereNow: function hereNow(channels) {
      var pubnub = this.get('pubnub');
      return pubnub.hereNow({
        channels: channels,
        includeUUIDs: true,
        includeState: true
      });
    },

    // Returns a promise
    whereNow: function whereNow(uuid) {
      var pubnub = this.get('pubnub');
      return pubnub.whereNow({
        uuid: uuid
      });
    },

    history: function history(channel) {
      var pubnub = this.get('pubnub');
      return pubnub.history({
        channel: channel,
        reverse: false,
        count: 100,
        stringifiedTimeToken: false
      });
    },

    close: function close() {
      var pubnub = this.get('pubnub');
      pubnub.stop();
      this.set('initialized', false);
    },

    removeListeners: function removeListeners() {
      var _this = this,
          pubnub = this.get('pubnub');

      pubnub.removeListener({
        status: function status(e) {
          e.affectedChannels.forEach(function (channel) {
            _this.trigger('status-' + channel, e);
          });
        },
        message: function message(e) {
          _this.trigger('message-' + e.channel, e);
        },
        presence: function presence(e) {
          _this.trigger('presence-' + e.channel, e);
        }
      });
      this.set('hasEvents', false);
    }
  });

  exports['default'] = pubnubInstance;
});