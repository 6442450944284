define('hoolio-www/components/youtube-player/component', ['exports', 'ember'], function (exports, _ember) {
  /* global YT, window */

  var computed = _ember['default'].computed;
  var debug = _ember['default'].debug;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var RSVP = _ember['default'].RSVP;
  var $ = _ember['default'].$;
  exports['default'] = _ember['default'].Component.extend({
    videoPlayerService: _ember['default'].inject.service('video-player'),
    browser: _ember['default'].inject.service(),

    classNames: ['youtube-player'],
    video: null,
    width: 560,
    height: 315,

    // These options are used to load a video.
    startSeconds: undefined,
    endSeconds: undefined,
    suggestedQuality: undefined,

    lazyload: false,
    showDebug: false,
    showChapters: false,

    player: null,
    playerState: 'loading',
    // YouTube's embedded player can take a number of optional parameters.
    // https://developers.google.com/youtube/player_parameters#Parameters
    // https://developers.google.com/youtube/youtube_player_demo
    playerVars: {
      autoplay: 1,
      controls: 0,
      enablejsapi: 1,
      rel: 0, // disable related videos
      showinfo: 0,
      autohide: 1,
      fs: 0, // Disable fullscreen button
      playsinline: 1 // Allow inline playback on iOS.
    },

    // from YT.PlayerState
    stateNames: {
      '-1': 'ready', // READY
      0: 'ended', // YT.Player.ENDED
      1: 'playing', // YT.PlayerState.PLAYING
      2: 'paused', // YT.PlayerState.PAUSED
      3: 'buffering', // YT.PlayerState.BUFFERING
      5: 'queued' // YT.PlayerState.CUED
    },

    onReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      this.attrs.autoplay = this.attrs.autoplay && !this.get('browser.isMobile') ? 1 : 0;
      this.set('playerVars.autoplay', this.attrs.autoplay);

      this.attrs.controls = this.attrs.controls ? 1 : 0;
      this.set('playerVars.controls', this.attrs.controls);

      this.set('lazyload', this.attrs.lazyload || false);

      this.set('showDebug', this.attrs.showDebug || false);
      this.set('showChapters', this.attrs.showChapters || false);

      this.set('responsive', this.attrs.responsive === false ? false : true);

      this.set('startSeconds', this.attrs.startSeconds || undefined);
      this.set('endSeconds', this.attrs.endSeconds || undefined);
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get('lazyload') && this.get('video')) {
        // If "lazyload" is not enabled and we have an ID, we can start immediately.
        // Otherwise the `loadVideo` observer will take care of things.
        this.loadAndCreatePlayer().then(function () {
          _this.loadVideo();
        });
      }
    },

    loadAndCreatePlayerIsRunning: false,
    loadAndCreatePlayer: function loadAndCreatePlayer() {
      var _this2 = this;

      var isRunning = this.get('loadAndCreatePlayerIsRunning');
      if (isRunning) {
        // some ember-concurrency would be nice here
        return;
      }
      this.set('loadAndCreatePlayerIsRunning', true);
      var promise = new RSVP.Promise(function (resolve, reject) {
        _this2.loadYouTubeApi().then(function () {
          _this2.createPlayer().then(function (player) {
            var $iframe = _this2.$('#EmberYoutube-player');
            $iframe.removeClass('hidden');

            _this2.set('videoPlayerService.currentTimeValue', 0);

            _this2.set('videoPlayerService.player', player);
            _this2.set('videoPlayerService.mediaIsReady', true);
            //Set event listeners
            _this2.get('videoPlayerService').on('player-play', _this2, _this2.triggerPlayAction).on('player-pause', _this2, _this2.triggerPauseAction).on('player-seek', _this2, _this2.triggerSeekAction).on('player-forward', _this2, _this2.triggerForwardAction).on('player-backward', _this2, _this2.triggerBackwardAction).on('player-unmute', _this2, _this2.triggerUnmuteAction).on('player-mute', _this2, _this2.triggerMuteAction).on('player-chapter-seek', _this2, _this2.triggerChapterSeekAction);

            if (_this2.attrs.onPlayerReadyEvent) {
              _this2.attrs.onPlayerReadyEvent();
            }

            _this2.setProperties({
              player: player,
              playerState: 'ready'
            });
            _this2.set('loadAndCreatePlayerIsRunning', false);
            resolve();
          })['catch'](function (err) {
            if (_this2.get('showDebug')) {
              _ember['default'].debug(err);
            }
            reject(err);
          });
        });
      });
      // The `wait` helper waits for this run loop,
      // but not the above promise, which is what i want.
      if (_ember['default'].testing) {
        run.later(function () {}, 5000);
      }
      return promise;
    },

    // A promise that is resolved when window.onYouTubeIframeAPIReady is called.
    // The promise is resolved with a reference to window.YT object.
    loadYouTubeApi: function loadYouTubeApi() {
      return new RSVP.Promise(function (resolve) {
        var previous = undefined;
        previous = window.onYouTubeIframeAPIReady;

        // The API will call this function when page has finished downloading
        // the JavaScript for the player API.
        window.onYouTubeIframeAPIReady = function () {
          if (previous) {
            previous();
          }
          resolve(window.YT);
        };

        if (window.YT && window.YT.loaded) {
          // If already loaded, make sure not to load the script again.
          resolve(window.YT);
        } else {
          $.getScript('https://www.youtube.com/iframe_api');
        }
      });
    },

    // A promise that is immediately resolved with a YouTube player object.
    createPlayer: function createPlayer() {
      var _this3 = this;

      var playerVars = this.get('playerVars');
      var width = this.get('width');
      var height = this.get('height');
      // const iframe = this.element.querySelector('#EmberYoutube-player');
      var iframe = this.$('#EmberYoutube-player');
      var player = undefined;
      return new RSVP.Promise(function (resolve, reject) {
        if (!iframe) {
          reject('Couldn\'t find the iframe element to create a YouTube player');
        }

        player = new YT.Player(iframe.get(0), {
          width: width,
          height: height,
          playerVars: playerVars,
          events: {
            onReady: function onReady() {
              resolve(player);
            },
            onStateChange: _this3.onPlayerStateChange.bind(_this3),
            onError: _this3.onPlayerError.bind(_this3)
          }
        });
      });
    },

    // Gets called by the YouTube player.
    onPlayerStateChange: function onPlayerStateChange(event) {
      // Set a readable state name
      var state = this.get('stateNames.' + event.data.toString());
      this.set('playerState', state);
      if (this.get('showDebug')) {
        debug(state);
      }
      // send actions outside
      this.sendAction(state);
      // send actions inside
      this.send(state);
    },

    // Gets called by the YouTube player.
    onPlayerError: function onPlayerError(event) {
      var errorCode = event.data;
      this.set('playerState', 'error');
      // Send the event to the controller
      this.sendAction('error', errorCode);
      if (this.get('showDebug')) {
        debug('error' + errorCode);
      }
      // switch(errorCode) {
      //  case 2:
      //    debug('Invalid parameter');
      //    break;
      //  case 100:
      //    debug('Not found/private');
      //    this.send('playNext');
      //    break;
      //  case 101:
      //  case 150:
      //    debug('Embed not allowed');
      //    this.send('playNext');
      //    break;
      //  default:
      //    break;
      // }
    },

    // Returns a boolean that indicates playback status by looking at the player state.
    isPlaying: computed('playerState', {
      get: function get() {
        var player = this.get('player');
        if (!player) {
          return false;
        }
        return player.getPlayerState() === 1;
      }
    }),

    // Load (and plays) a video every time video changes.
    videoDidChange: observer('video', function () {
      var _this4 = this;

      var player = this.get('player');
      var video = this.get('video');

      if (!video) {
        return;
      }

      if (!player) {
        this.loadAndCreatePlayer().then(function () {
          _this4.loadVideo();
        });
        return;
      }
      this.loadVideo();
    }),

    loadVideo: function loadVideo() {
      var player = this.get('player');
      var video = this.get('video');

      // Set parameters for the video to be played.
      var options = _ember['default'].getProperties(this, ['startSeconds', 'endSeconds', 'suggestedQuality']);
      options.videoId = video;
      // Either load or cue depending on `autoplay`.
      if (this.playerVars.autoplay) {
        player.loadVideoById(options);
      } else {
        player.cueVideoById(options);
      }
    },

    startTimer: function startTimer() {
      var _this5 = this;

      var player = this.get('player');
      var interval = 1000;
      // set initial times
      this.setProperties({
        currentTime: player.getCurrentTime(),
        duration: player.getDuration()
      });
      // stop any previously started timer we forgot to clear
      this.stopTimer();

      if (this.get('videoPlayerService.isEnded')) {
        this.set('videoPlayerService.currentTimeValue', 0);
        this.set('videoPlayerService.isEnded', false);
      }

      // every second update current time
      var timer = window.setInterval(function () {
        var _time = player.getCurrentTime();
        _this5.set('currentTime', parseInt(_time));
        _this5.set('videoPlayerService.currentTimeValue', parseInt(_time));
      }, interval);
      // save the timer so we can stop it later
      this.set('timer', timer);
    },

    stopTimer: function stopTimer() {
      window.clearInterval(this.get('timer'));
    },

    // A wrapper around the YouTube method to get current time.
    currentTime: computed({
      get: function get() {
        var player = this.get('player');
        var value = player ? player.getCurrentTime() : 0;
        return value;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    // A wrapper around the YouTube method to get the duration.
    duration: computed({
      get: function get() {
        var player = this.get('player');
        var value = player ? player.getDuration() : 0;
        return value;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    // A wrapper around the YouTube method to get and set volume.
    volume: computed({
      get: function get() {
        var player = this.get('player');
        var value = player ? player.getVolume() : 0;
        return value;
      },
      set: function set(name, vol) {
        var player = this.get('player');
        // Clamp between 0 and 100
        if (vol > 100) {
          vol = 100;
        } else if (vol < 0) {
          vol = 0;
        }
        if (player) {
          player.setVolume(vol);
        }
        return vol;
      }
    }),

    // OK, this is stupid but couldn't access the "event" inside
    // an ember action so here's a manual click handler instead.
    progressBarClick: on('didInsertElement', function () {
      var self = this;
      this.$().on('click', 'progress', function (event) {
        // get the x position of the click inside our progress el
        var x = event.pageX - _ember['default'].$(this).position().left;
        // convert it to a value relative to the duration (max)
        var clickedValue = x * this.max / this.offsetWidth;
        // 250 = 0.25 seconds into player
        self.send('seekTo', clickedValue);
      });
    }),

    // clean up when element will be destroyed.
    willDestroyElement: function willDestroyElement() {
      // clear the timer
      this.stopTimer();
      // destroy video player
      var player = this.get('player');
      if (player) {
        player.destroy();
        this.set('player', null);
      }

      this.set('videoPlayerService.currentTimeValue', 0);
      //this.set('videoPlayerService.isPlaying', false);
      this.set('videoPlayerService.player', null);
      this.set('videoPlayerService.mediaIsReady', false);

      this.get('videoPlayerService').off('player-play', this, this.triggerPlayAction).off('player-pause', this, this.triggerPauseAction).off('player-seek', this, this.triggerSeekAction).off('player-forward', this, this.triggerForwardAction).off('player-backward', this, this.triggerBackwardAction).off('player-unmute', this, this.triggerUnmuteAction).off('player-mute', this, this.triggerMuteAction).off('player-chapter-seek', this, this.triggerChapterSeekAction);
    },

    triggerPlayAction: function triggerPlayAction(data) {
      if (data && data.time) {
        this.triggerSeekAction(data);
        _ember['default'].run.next(this, function () {
          this.send('play');
        });
      } else {
        this.send('play');
      }
    },

    triggerPauseAction: function triggerPauseAction(data) {
      if (data && data.time) {
        this.triggerSeekAction(data);
        _ember['default'].run.next(this, function () {
          this.send('pause');
        });
      } else {
        this.send('pause');
      }
    },

    triggerChapterSeekAction: function triggerChapterSeekAction(data) {
      if (this.get('showChapters')) {
        this.set('videoPlayerService.currentTimeValue', parseInt(data.time));
        this.send('seekTo', parseInt(data.time), true);
      }
    },

    triggerSeekAction: function triggerSeekAction(data) {
      if (!this.get('showChapters')) {
        this.set('videoPlayerService.currentTimeValue', parseInt(data.time));
        this.send('seekTo', parseInt(data.time), true);
      }
    },

    triggerForwardAction: function triggerForwardAction() {
      if (!this.get('showChapters')) {
        var time = this.get('videoPlayerService.currentTimeValue') + 20;
        this.set('videoPlayerService.currentTimeValue', time);
        this.send('seekTo', time, true);
      }
    },

    triggerBackwardAction: function triggerBackwardAction() {
      if (!this.get('showChapters')) {
        var time = this.get('videoPlayerService.currentTimeValue') - 20;
        if (time < 0) {
          time = 0;
        }
        this.set('videoPlayerService.currentTimeValue', time);
        this.send('seekTo', time, true);
      }
    },

    triggerUnmuteAction: function triggerUnmuteAction() {
      this.send('unMute');
    },

    triggerMuteAction: function triggerMuteAction() {
      this.send('mute');
    },

    actions: {
      play: function play() {
        if (this.get('player')) {
          this.get('player').playVideo();
        }
      },
      pause: function pause() {
        if (this.get('player')) {
          this.get('player').pauseVideo();
        }
      },
      togglePlay: function togglePlay() {
        if (this.get('player') && this.get('isPlaying')) {
          this.send('pause');
        } else {
          this.send('play');
        }
      },
      mute: function mute() {
        if (this.get('player')) {
          this.get('player').mute();
          this.set('videoPlayerService.isMuted', true);
        }
      },
      unMute: function unMute() {
        if (this.get('player')) {
          this.get('player').unMute();
          this.set('videoPlayerService.isMuted', false);
        }
      },
      toggleVolume: function toggleVolume() {
        if (this.get('player').isMuted()) {
          this.send('unMute');
        } else {
          this.send('mute');
        }
      },
      seekTo: function seekTo(seconds, allowSeekAhead) {
        if (this.get('player')) {
          this.get('player').seekTo(seconds, allowSeekAhead);
        }
      },
      // YouTube events.
      ready: function ready() {},
      ended: function ended() {
        this.set('videoPlayerService.currentTimeValue', parseInt(this.get('player').getDuration()));
        this.set('videoPlayerService.isPlaying', false);
        this.set('videoPlayerService.isEnded', true);
        this.get('videoPlayerService').trigger('player-ended');
      },
      playing: function playing() {
        this.startTimer();
        this.set('videoPlayerService.isPlaying', true);
      },
      paused: function paused() {
        this.stopTimer();
        this.set('videoPlayerService.isPlaying', false);
      },
      buffering: function buffering() {},
      queued: function queued() {}
    }
  });
});