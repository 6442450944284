define('hoolio-www/components/juicer-social-feed/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['juicer-social-feed'],

    feedId: '',
    per: '',
    pages: '',
    truncate: '',
    gutter: '',
    columns: '',

    onReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      this.set('feedId', this.attrs.feedId || "hoolio");
      this.set('per', this.attrs.per || "100");
      this.set('pages', this.attrs.pages || "infinity");
      this.set('truncate', this.attrs.truncate || "100");
      this.set('gutter', this.attrs.gutter || "10");
      this.set('columns', this.attrs.columns || "6");
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "//assets.juicer.io/embed.js";
      s.id = this.elementId;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }),

    onWillDestroyElement: _ember['default'].on('willDestroyElement', function () {
      _ember['default'].$('head script#' + this.elementId).remove();
    })
  });
});