define('hoolio-www/components/workout-syncing/component', ['exports', 'ember', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    pubnubService: _ember['default'].inject.service('pubnub'),
    opentokService: _ember['default'].inject.service('opentok'),
    healthService: _ember['default'].inject.service('health'),
    videoPlayerService: _ember['default'].inject.service('video-player'),
    analyticsService: _ember['default'].inject.service('analytics'),

    classNames: ['workout-syncing-component'],

    _onDidInsertElement: _ember['default'].on('didReceiveAttrs', function () {
      var _this = this;

      if (this.attrs.workout.get('openTokSession') && this.attrs.userId) {
        (function () {
          var _pubnub = _this.get('pubnubService');
          var channels = [_this.attrs.workout.get('openTokSession'), _this.attrs.userId];
          _this.set('channels', channels);

          var workoutPb = _pubnub.getInstance('workoutSyncPb');
          if (!workoutPb.get('initialized')) {
            throw "[Pubnub] No workoutSyncPb instance found. Initialize first one.";
          }

          if (!workoutPb.isConnectedToChannel(channels[0]) && !workoutPb.isConnectedToChannel(channels[1])) {
            _this.registerGlobalPubnubEvents();
            _this.registerPrivatePubnubEvents();

            // Subscribe to the global studio channel
            workoutPb.subscribe(channels, true);

            _ember['default'].run.later(_this, function () {
              var _this2 = this;

              workoutPb.hereNow([this.attrs.userId]).then(function (data) {
                data.channels[_this2.attrs.userId].occupants.forEach(function (user) {
                  if (user.uuid.split('-').pop() === 'mobile') {
                    _this2.set("healthService.sensorIsConnected", true);
                  }
                });
              });
            }, 2000);
          }
        })();
      }
    }),

    registerGlobalPubnubEvents: function registerGlobalPubnubEvents() {
      var workoutPb = this.get('pubnubService').getInstance('workoutSyncPb');
      var channel = this.attrs.workout.get('openTokSession');
      workoutPb.on('message:' + channel, this.onMessageGlobal.bind(this));
    },

    registerPrivatePubnubEvents: function registerPrivatePubnubEvents() {
      var workoutPb = this.get('pubnubService').getInstance('workoutSyncPb');
      var channel = this.attrs.userId;
      workoutPb.on('message:' + channel, this.onMessagePrivate.bind(this));
      workoutPb.on('presence:' + channel, this.onPresencePrivate.bind(this));
    },

    onMessageGlobal: function onMessageGlobal(e) {
      // global channel
      if (e.channel === this.attrs.workout.get('openTokSession')) {
        if (!this.attrs.belongsToCurrentUser) {
          if (e.message.mode === "player") {
            this.get('videoPlayerService').trigger(e.message.mode + '-' + e.message.action, e.message);
          }
        }
      }
    },

    onMessagePrivate: function onMessagePrivate(e) {
      // private channel
      var workoutPb = this.get('pubnubService').getInstance('workoutSyncPb');
      if (e.message.mode === "player") {
        if (e.message.action === "sync") {
          var _player = this.get('videoPlayerService.player');
          var _status = this.get('videoPlayerService.isPlaying') ? 'playing' : 'paused';
          var _time = 0;
          if (_player) {
            _time = this.attrs.isFromYoutube ? _player.getCurrentTime() : _player.evaluate('{video.player.currentTime}');
          }
          var _video_duration = this.attrs.workout.get('video.isFromYoutube') ? this.attrs.workout.get('video.youtubeDuration') : this.attrs.workout.get('video.duration');
          var _workout_thumb = this.attrs.workout.get('video.isFromYoutube') ? this.attrs.workout.get('video.youtubeThumbnail') : this.attrs.workout.get('video.thumbnailUrlWide');
          var _workout_info = { calories: 0, workout_id: this.attrs.workout.get('id'), workout_username: this.attrs.workout.get('user.firstName'), workout_name: this.attrs.workout.get('video.name'), workout_thumb: _workout_thumb, duration: _video_duration };
          var msg = { mode: "player", time: _time, status: _status, workout_info: _workout_info };

          workoutPb.publish({
            channel: this.attrs.userId,
            message: msg
          });
        } else {
          if (!e.message.uuid) {
            this.get('videoPlayerService').trigger(e.message.mode + '-' + e.message.action, e.message);
          }

          // Broadcast seek action. Play and pause action are droadcasting below on onPlayerStateToggle observer
          if (e.message.action === "seek") {
            if (this.attrs.belongsToCurrentUser) {
              workoutPb.publish({
                channel: this.attrs.workout.get('openTokSession'),
                message: e.message
              });
            }
          }
        }
      } else if (e.message.mode === "health") {
        if (e.message.action === "heartrate") {
          this.set("healthService.heartrate", e.message.data.value);
          this.set("healthService.avg_heartrate", Math.round(e.message.data.avg_value));
        } else if (e.message.action === "steps") {
          this.set("healthService.steps", e.message.data.value);
        }
      }
    },

    onPresencePrivate: function onPresencePrivate(e) {
      // private channel
      if (e.uuid.split('-').pop() === 'mobile') {
        if (e.action === 'join') {
          var metadata = {
            title: this.attrs.workout.get('video.name')
          };

          this.set("healthService.sensorIsConnected", true);

          this.get('analyticsService.intercom').trackEvent('mobile-app-connected', metadata);
          this.get('analyticsService.google').trackEvent('mobile', 'connect_mobile', 'feature_upscale');
        } else if (e.action === 'leave') {
          this.set("healthService.sensorIsConnected", false);
        }
      }
    },

    // Broadcast video player actions
    onPlayerStateToggle: _ember['default'].on('init', _ember['default'].observer('videoPlayerService.isPlaying', function () {
      var _pubnub = this.get('pubnubService').getInstance('workoutSyncPb');
      var _player = this.get('videoPlayerService.player');
      if (!_player) {
        return;
      }
      var _action = this.get('videoPlayerService.isPlaying') ? 'play' : 'pause';
      var _time = _player && this.attrs.isFromYoutube ? _player.getCurrentTime() : _player.evaluate('{video.player.currentTime}');
      var msg = { time: _time, log: "true", mode: "player", action: _action, uuid: _pubnub.get('pubnubConfig.uuid') };

      if (this.attrs.belongsToCurrentUser) {
        if (_pubnub && this.get('opentokService.opentokSession')) {
          _pubnub.publish({
            channel: this.get('opentokService.opentokSession'),
            message: msg
          });
        }
      }

      if (_pubnub && this.attrs.userId) {
        _pubnub.publish({
          channel: this.attrs.userId,
          message: msg
        });
      }
    })),

    _onDestroyElement: _ember['default'].on('willDestroyElement', function () {
      this.set("healthService.sensorIsConnected", false);
    })
  });
});