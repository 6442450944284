define('hoolio-www/components/video-annotations/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    videoPlayerService: _ember['default'].inject.service('video-player'),

    classNames: ['video-annotations-component'],
    classNameBindings: ['activeAnnotation.style'],

    annotationDuration: 7000, // 10000 milliseconds = 10 secs
    isAnnotationActive: false,
    timerRunning: false,
    currentTimeValue: _ember['default'].computed.alias('videoPlayerService.currentTimeValue'),

    annotInAnimClass: _ember['default'].computed('activeAnnotation', function () {
      if (this.get('activeAnnotation')) {
        var value = this.get('activeAnnotation.style') === 'Motivational' ? 'slideInUp' : 'slideInRight';
        return value;
      }
    }),
    annotOutAnimClass: _ember['default'].computed('activeAnnotation', function () {
      if (this.get('activeAnnotation')) {
        var value = this.get('activeAnnotation.style') === 'Motivational' ? 'slideOutDown' : 'slideOutRight';
        return value;
      }
    }),

    onDidReceiveAttrs: _ember['default'].on('didReceiveAttrs', function () {
      this.set('annotations', _ember['default'].ArrayProxy.create({ content: _ember['default'].A(this.attrs.annotations) }));
    }),

    /*activeAnnotation: {
      text:'Hey ${firstName}! Do Not Arch Your Back, Keep Your Core In!',
      background: 'Light',
      style: 'Instructional'
    },*/
    activeAnnotation: null,

    onTimeTick: _ember['default'].on('init', _ember['default'].observer('videoPlayerService.currentTimeValue', function () {
      var _annotations = this.get('annotations'),
          _isAnnotationActive = this.get('isAnnotationActive'),
          _activeAnnotation = this.get('activeAnnotation'),
          _timerRunning = this.get('timerRunning'),
          _time = this.get('currentTimeValue'),
          _timeMin = _time - 1,
          _timeMax = _time + 1;
      if (!_isAnnotationActive && !_activeAnnotation) {
        var _annot = _annotations.find(function (item, index, enumerable) {
          //console.log(_timeMin <= item.timeStamp && item.timeStamp <= _timeMax);
          return _timeMin <= item.timeStamp && item.timeStamp <= _timeMax;
        });
        if (_annot) {
          this.set('activeAnnotation', _annot);
          this.set('isAnnotationActive', true);
        }
      } else if (!_timerRunning && _isAnnotationActive && _activeAnnotation) {
        this.set('timerRunning', true);
        _ember['default'].run.later(this, function () {
          if (this) {
            this.set('isAnnotationActive', false);
            _ember['default'].run.later(this, function () {
              this.set('timerRunning', false);
              this.set('activeAnnotation', null);
            }, 1000);
          }
        }, this.annotationDuration);
      }
    }))
  });
});