define("hoolio-www/explore/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    browser: _ember["default"].inject.service(),

    res: "",

    title: _ember["default"].computed("", function () {
      return "Find the video workout that’s right for you.";
    }),

    subtext: _ember["default"].computed("", function () {
      return "Use the map below to find the perfect video workout based on YOUR mood, available time or even fitness level!";
    }),

    backgroundStyle: _ember["default"].computed("", function () {
      return "background: url(https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/1_u1zp2ciy/width/1920/height/1024/) no-repeat right bottom;";
    }),

    resultsSliderOptions: {
      wrapAround: false
    },

    resultsRowTitle: _ember["default"].computed("searchResults", "selectedGroup", function () {
      var _selectedGroup = this.get('selectedGroup');
      if (_selectedGroup && _selectedGroup.groups) {
        return this.get('searchResults.length') + " workouts matching your '" + _selectedGroup.label + "' map";
      } else {
        return this.get('searchResults.length') + " workouts to level up your '" + _selectedGroup.label + "' MOVEs";
      }
    }),

    searchResults: _ember["default"].computed('selectedGroup', function () {
      var _selectedGroup = this.get('selectedGroup');
      if (_selectedGroup) {
        this.set('res', '');
        var pararms = { scoreboard: true };
        if (_selectedGroup.groups) {
          pararms[_selectedGroup.id] = '*';
        } else {
          pararms[_selectedGroup.parentId] = _selectedGroup.id;
        }
        return this.store.query('video', pararms);
      } else {
        return [];
      }
    }),

    actions: {
      // This is the top action for setting the active item for all dashboards fit-sliders (DDAU FTW).
      setActiveItem: function setActiveItem(item) {
        this.set('res', item);
      },

      onGroupSelection: function onGroupSelection(data) {
        this.set('selectedGroup', data);
      }
    }
  });
});