define('hoolio-www/authenticators/hoolio', ['exports', 'ember-simple-auth/authenticators/base', 'ember'], function (exports, _emberSimpleAuthAuthenticatorsBase, _ember) {
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    emagSocial: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),

    restore: function restore(data) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!_ember['default'].isEmpty(data) && !_this.get('emagSocial.credentials.user_id')) {
          _this.get('emagSocial').setCredentials({ user_id: data.user_id, user_email: data.email, session_key: data.session_key });
          _this.get('emagSocial').validate(function (response) {
            _this.get('emag').log('[Hoolio] Successful Auto login.');
            resolve(data);
          }, function (error) {
            reject('[Hoolio] Auto login failed.');
          });
        } else {
          //reject('[Hoolio] Auto login failed.');
        }
      });
    },

    authenticate: function authenticate(credentials) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!credentials) {
          reject("Invalid credentials...");
        }

        _this2.get('emagSocial').login(credentials, function (response) {
          resolve(response);
        }, function (error) {
          reject("Please check your email or password.");
        });
      });
    },

    invalidate: function invalidate(data) {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this3.get('emagSocial').logout(resolve, reject);
      });
    }
  });
});