define('hoolio-www/user-preference/serializer', ['exports', 'hoolio-www/serializers/rest-serializer', 'ember-data'], function (exports, _hoolioWwwSerializersRestSerializer, _emberData) {
  exports['default'] = _hoolioWwwSerializersRestSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      user: { serialize: 'ids', deserialize: 'ids' },
      character: { serialize: 'ids', deserialize: 'ids' },
      onboardingList1: { serialize: 'ids', deserialize: 'ids' },
      onboardingList2: { serialize: 'ids', deserialize: 'ids' },
      discoveryList: { serialize: 'ids', deserialize: 'ids' },
      userDashboard: { serialize: false }
    }
  });
});