define('hoolio-www/components/block-body-scroll/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    onDidInsert: _ember['default'].on('didInsertElement', function () {
      _ember['default'].$('body').addClass('modal-open');
    }),

    onWillDestroy: _ember['default'].on('willDestroyElement', function () {
      _ember['default'].$('body').removeClass('modal-open');
      if (this.attrs.onDestroy) {
        this.attrs.onDestroy();
      }
    })
  });
});