define('hoolio-www/video-metadata/serializer', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].JSONSerializer.extend({
    normalize: function normalize(typeClass, hash) {
      var json = {};
      var parsedXml = _ember['default'].$.xml2json(hash['xml']);

      json['id'] = hash['id'];
      json['level'] = parsedXml.Level;
      json['moves'] = parsedXml.Moves;
      json['specialization'] = parsedXml.Specialization;
      json['source'] = parsedXml.Source;
      json['youtubeUrl'] = parsedXml.YouTubeURL;
      json['youtubeThumbnail'] = parsedXml.YouTubeThumbnail;
      json['youtubeDuration'] = parsedXml.YouTubeVideoDuration;
      json['views'] = parsedXml.VideoViews;

      if (typeof parsedXml.Aim === 'string') {
        json['aim'] = [parsedXml.Aim];
      } else {
        json['aim'] = parsedXml.Aim;
      }

      if (typeof parsedXml.MuscleOrBodyArea === 'string') {
        json['muscleOrBodyArea'] = [parsedXml.MuscleOrBodyArea];
      } else {
        json['muscleOrBodyArea'] = parsedXml.MuscleOrBodyArea;
      }

      if (typeof parsedXml.TargetGroup === 'string') {
        json['targetGroup'] = [parsedXml.TargetGroup];
      } else {
        json['targetGroup'] = parsedXml.TargetGroup;
      }

      if (typeof parsedXml.Mood === 'string') {
        json['mood'] = [parsedXml.Mood];
      } else {
        json['mood'] = parsedXml.Mood;
      }

      if (typeof parsedXml.Equipment === 'string') {
        json['equipment'] = [parsedXml.Equipment];
      } else {
        json['equipment'] = parsedXml.Equipment;
      }

      if (typeof parsedXml.RelatedVideos === 'string') {
        json['relatedVideos'] = [parsedXml.RelatedVideos];
      } else {
        json['relatedVideos'] = parsedXml.RelatedVideos;
      }

      return this._super(typeClass, json);
    }
  });
});