define('hoolio-www/components/fit-slider/component', ['exports', 'ember', 'velocity'], function (exports, _ember, _velocity) {

  /*
     Attrs:
       @items (required): An array of items. Each item is a slide
       @elementId (optional)(unique)(default value: emberxxx)(note: no dashes allowed): provide it if you want to keep in sync with a query param
       @activeItemId (readonly): provide in order to keep track the selected slide and mutate it
       @setActiveItem: Callback function for slide select action,
       @lockCollapse (optional)(default value: false): Block slider collapse
       @scrollToSlider (optional)(default value: true): Scroll to selected slider
       @backResettable (optional)(default value: true): Reset page scroll if user goes back to slider's page and this slider was expanded.
       @sliderOptions (optional): Slider options
  */

  exports['default'] = _ember['default'].Component.extend({
    emag: _ember['default'].inject.service(),

    classNames: ['fit-slider'],
    classNameBindings: ['isSliderExpanded:is-expanded'],
    backResettable: true,
    lockCollapse: false,
    scrollToSlider: true,
    expandedHeights: {
      mobile: 700,
      tablet: 480
    },
    initialSlidePosition: 0,
    defaultSliderOptions: {
      cellSelector: '.fit-slider-item'
    },
    _sliderOptions: _ember['default'].computed('attrs.sliderOptions', function () {
      return this.attrs.sliderOptions ? _ember['default'].$.extend({}, this.defaultSliderOptions, this.attrs.sliderOptions) : this.defaultSliderOptions;
    }),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var item = this.attrs.activeItemId;
        if (this.get('isSliderExpanded') && item != '') {
          this.set('initialSlidePosition', Number(item.split('-')[1]));
          this.send('setActiveItem', item);
          if (this.get('backResettable') && this.get('scrollToSlider')) {
            _ember['default'].run.scheduleOnce('afterRender', this, '_setSliderPosition', item);
          }
        }
      });
    }),

    /*didRender() {
      console.log('did render '+this.elementId);
    },*/

    isSliderExpanded: _ember['default'].computed('attrs.activeItemId', 'elementId', function () {
      if (this.attrs.activeItemId.split('-')[0] === this.get('elementId')) {
        return true;
      } else {
        this._prevTargetOffset = -1;
        return false;
      }
    }),

    onDestroy: _ember['default'].on('willDestroyElement', function () {
      this.set('initialSlidePosition', 0);
      this._page.off("scroll.slider mousedown.slider wheel.slider DOMMouseScroll.slider mousewheel.slider keyup.slider touchmove.slider");
    }),

    _prevTargetOffset: -1,
    _page: _ember['default'].$("html, body"),
    _setSliderPosition: function _setSliderPosition(item) {
      var _this = this;

      if (item != '' && item.split('-')[0] === this.attrs.elementId) {
        (function () {
          var $prev = _this.$().prev(),
              $prevHeight = $prev.height(),
              isAnyPrevExpanded = _this.$().prevAll().hasClass('is-expanded'),
              page = _this._page;

          var targetOffset = -70;

          if ($prev.length > 0) {
            if ($prev.hasClass('is-expanded')) {
              targetOffset = $prev.offset().top + $prevHeight;
            } else {
              if (isAnyPrevExpanded) {
                targetOffset = $prev.offset().top + $prevHeight - (_this.get('media.isMobile') ? _this.expandedHeights.mobile : _this.expandedHeights.tablet);
              } else {
                targetOffset = $prev.offset().top + $prevHeight;
              }
            }
          } else {
            targetOffset = _this.$().offset().top - 70;
          }

          if (_this._prevTargetOffset != targetOffset) {
            _this._prevTargetOffset = targetOffset;

            // Cancel animation one user's below actions
            page.one("scroll.slider mousedown.slider wheel.slider DOMMouseScroll.slider mousewheel.slider keyup.slider touchmove.slider", function () {
              (0, _velocity['default'])(page, "stop");
              // page.stop();
            });

            _ember['default'].run.scheduleOnce('afterRender', _this, function () {
              (0, _velocity['default'])(page, "scroll", {
                offset: targetOffset - 30,
                duration: 500,
                easing: "ease",
                complete: function complete(elements) {
                  page.off("scroll.slider mousedown.slider wheel.slider DOMMouseScroll.slider mousewheel.slider keyup.slider touchmove.slider");
                }
              });

              /*page.animate({ scrollTop: targetOffset - 30 }, 500, 'swing', function(){
                page.off("scroll.slider mousedown.slider wheel.slider DOMMouseScroll.slider mousewheel.slider keyup.slider touchmove.slider");
                return false;
              });*/
            });
          }
        })();
      }
    },

    actions: {
      setActiveItem: function setActiveItem(item) {
        if (this.get('scrollToSlider') && !this.get('isSliderExpanded')) {
          //Ember.run.scheduleOnce('afterRender', this, '_setSliderPosition', item);
          this._setSliderPosition(item);
        }

        if (this.attrs.setActiveItem) {
          this.attrs.setActiveItem(item);
        } else {
          this.get('emag').error("[" + this.attrs.elementId + " slider] 'setActiveItem' action is not provided.");
        }
      },

      onSliderChange: function onSliderChange(index, sliderId) {
        if (isNaN(index) || !this.get('isSliderExpanded')) {
          return;
        }
        var item = this.attrs.elementId + '-' + index;
        this.send('setActiveItem', item);
      },

      staticSliderClick: function staticSliderClick(index, sliderId) {
        _ember['default'].run.next(this, function () {
          var activeItem = this.attrs.activeItemId;
          var item = this.attrs.elementId + '-' + index;
          if (!this.get('lockCollapse') && activeItem != '' && this.get('isSliderExpanded') && activeItem === item) {
            //console.log('empty!!');
            this.send('setActiveItem', '');
          } else if (activeItem != item) {
            if (isNaN(index)) {
              return;
            }
            //console.log(item);
            this.send('setActiveItem', item);
          }
        });
      }
    }
  });
});