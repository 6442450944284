define('hoolio-www/helpers/parse-price', ['exports', 'ember'], function (exports, _ember) {

  var parsePrice = function parsePrice(params) {
    var symbol = undefined;
    if (params[1] === 'usd') {
      symbol = '$';
    } else if (params[1] === 'eur') {
      symbol = '€';
    } else if (params[1] === 'gbp') {
      symbol = '£';
    }

    if (params[2]) {
      params[0] = parseInt(params[0] - params[0] * params[2].percent_off / 100);
    }

    return symbol + params[0] / 100;
  };
  exports['default'] = _ember['default'].Helper.helper(parsePrice);
});