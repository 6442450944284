define('hoolio-www/initializers/fullscreen', ['exports', 'ember-fullscreen/initializers/fullscreen'], function (exports, _emberFullscreenInitializersFullscreen) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFullscreenInitializersFullscreen['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberFullscreenInitializersFullscreen.initialize;
    }
  });
});