define('hoolio-www/components/compatibility-notification/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['compatibility-notification', 'animated', 'fadeIn'],
    msg: '',
    link: '',

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      this._super();
      _ember['default'].run.scheduleOnce('afterRender', this, 'afterRenderEvent');
    }),

    afterRenderEvent: function afterRenderEvent() {
      // Set various warning depending on user's browser and device
      if (!this.get('msg')) {
        if (_ember['default'].$.browser.desktop) {
          if (!_ember['default'].$.browser.chrome && !_ember['default'].$.browser.opera && !_ember['default'].$.browser.mozilla) {
            this.setProperties({ msg: 'For the full experience, hoolio requires Chrome, Firefox or Opera.', link: '' });
          } else if (_ember['default'].$.browser.chrome && parseInt(_ember['default'].$.browser.versionNumber) < 47) {
            this.setProperties({ msg: 'Your Chrome is outdated. Please make sure you have the latest Chrome version.', link: 'https://www.google.com/intl/en/chrome/browser/' });
          } else if (_ember['default'].$.browser.opera && parseInt(_ember['default'].$.browser.versionNumber) < 34) {
            this.setProperties({ msg: 'Your Opera is outdated. Please make sure you have the latest Opera version.', link: 'http://www.opera.com/download' });
          } else if (_ember['default'].$.browser.mozilla && parseInt(_ember['default'].$.browser.versionNumber) < 43) {
            this.setProperties({ msg: 'Your Firefox is outdated. Please make sure you have the latest Firefox version.', link: 'https://www.mozilla.org/en-US/firefox/new/' });
          }
        } else {
          this.setProperties({ msg: 'For the full experience, hoolio requires Chrome, Firefox or Opera.', link: '' });
        }
      }
    },

    click: function click() {
      var _link = this.get('link');
      if (_link) {
        window.open(_link);
      } else {
        this.setProperties({ msg: '', link: '' });
      }
    },

    actions: {
      hideMsg: function hideMsg() {
        this.setProperties({ msg: '', link: '' });
        return false;
      }
    }
  });
});