define('hoolio-www/components/image-uploader/component', ['exports', 'ember'], function (exports, _ember) {
  /*global $ */

  exports['default'] = _ember['default'].Component.extend({
    flashMessages: _ember['default'].inject.service(),

    elementId: 'image-uploader',
    classNames: ['image-uploader-component'],

    onInitialize: _ember['default'].on('didInsertElement', function () {
      var flash = _ember['default'].get(this, 'flashMessages');

      _ember['default'].run.next(this, function () {
        var _this = this;

        this.$().uploadFile({
          url: this.attrs.url || '',
          uploadButtonClass: this.attrs.uploadButtonClass || 'btn no-shadow',
          multiple: this.attrs.multiple || false,
          autoSubmit: this.attrs.autoSubmit || false,
          allowedTypes: this.attrs.allowedTypes || 'jpg,jpeg,png',
          maxFileCount: this.attrs.maxFileCount || 10,
          showFileCounter: this.attrs.showFileCounter || true,
          maxFileSize: this.attrs.maxFileSize || 1024 * 10000,
          showDone: this.attrs.showDone || false,
          formData: this.attrs.formData || {},
          headers: this.attrs.headers || {},
          dragDrop: this.attrs.dragDrop || false,
          action_text: this.attrs.action_text || 'Select',
          onSelect: function onSelect(files) {
            if (_this.attrs.onSelect) {
              _this.attrs.onSelect(files);
            }
            return true; //to allow file submission.
          },
          onSuccess: function onSuccess(files, data, xhr) {
            if (_this.attrs.onSuccess) {
              _this.attrs.onSuccess(files, data, xhr);
            }
          },
          afterUploadAll: function afterUploadAll(obj) {
            if (_this.attrs.afterUploadAll) {
              _this.attrs.afterUploadAll(obj);
            }
          },
          onError: function onError(files, status, errMsg) {
            flash.danger('Oops! Something went wrong.');
            if (_this.attrs.onError) {
              _this.attrs.onError(files, status, errMsg);
            }
          }
        });
      });
    })
  });
});