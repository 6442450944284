define('hoolio-www/workout/route', ['exports', 'ember', 'hoolio-www/mixins/authenticated-route', 'hoolio-www/config/environment'], function (exports, _ember, _hoolioWwwMixinsAuthenticatedRoute, _hoolioWwwConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_hoolioWwwMixinsAuthenticatedRoute['default'], {
    session: _ember['default'].inject.service(),
    opentok: _ember['default'].inject.service(),
    emag: _ember['default'].inject.service(),
    health: _ember['default'].inject.service(),
    videoPlayer: _ember['default'].inject.service(),
    analytics: _ember['default'].inject.service(),
    videoChapters: _ember['default'].inject.service(),
    pubnub: _ember['default'].inject.service(),

    activate: function activate() {
      var controller = this.controllerFor('application');
      controller.set('hideTopNavbar', true);
      controller.set('hideFooter', true);
    },

    deactivate: function deactivate() {
      var appController = this.controllerFor('application');
      appController.set('hideTopNavbar', false);
      appController.set('hideFooter', false);
    },

    model: function model(params) {
      //return this.get('session.currentUser').get('workouts').findBy('id', params.id);
      return this.store.findRecord('workout', params.id);
    },

    afterModel: function afterModel(model, transition) {
      if (!this.get('session.currentUser.hasPremiumPlan') && model.get('premium')) {
        this.transitionTo('noaccess');
      }

      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);

      return model.get('video').then(function (video) {
        _ember['default'].$(document).attr('title', 'hoolio - ' + video.get('name'));
      });
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      controller.set('model', model);

      // Init video chapters
      if (model.get('video.chapters.length')) {
        this.set('videoChapters.chapters', model.get('video.chapters'));
        this.get('videoChapters').addEvents();
      }

      model.set('secondsOnLive', 0);

      this.set('health.heartrate', 0); // Reset heartrate
      this.set('health.avg_heartrate', 0); // Reset heartrate
      this.set('health.steps', 0); // Reset steps

      model.get('user').then(function (user) {
        var metadata = {
          title: model.get('video.name'),
          owner: user.get('fullName')
        };

        if (model.get('belongsToCurrentUser') === false) {
          _this.get('emag').log('[Hoolio] Workout belongs to another user.');
          //model.get('participants').pushObject(this.get('session.currentUser'));

          /* Track intercom event */
          _this.get('analytics.intercom').trackEvent('joined-workout-as-participant', metadata);
          _this.get('analytics.google').trackEvent('workout', 'participate', 'social_workout');
        } else {
          _this.get('emag').log('[Hoolio] Workout belongs to current user.');
          _this.get('analytics.intercom').trackEvent('workout-started', metadata);
          _this.get('analytics.google').trackEvent('workout', 'start_workout', 'engage');
        }
      });

      this.store.queryRecord('workout-metric', { workout: model.get('id') }).then(function (workoutMetric) {
        controller.set('workoutMetric', workoutMetric);
      });

      if (!this.get('opentok.isSessionConnected') && this.get('opentok').isSupported()) {
        this.get('opentok').connectSession(model.get('openTokSession'));
      }

      var workoutSyncPb = this.get('pubnub').getInstance('workoutSyncPb').initialize({
        subscribeKey: _hoolioWwwConfigEnvironment['default'].pubnub_subscribe_key,
        publishKey: _hoolioWwwConfigEnvironment['default'].pubnub_publish_key,
        uuid: this.get('session.currentUser.id') + '-webapp',
        ssl: true
      });
      controller.set('workoutSyncPb', workoutSyncPb);

      controller.set('stopwatchStarted', true);
    },

    resetController: function resetController(controller, isExiting, transition) {
      var _this2 = this;

      if (isExiting) {

        if (!controller.get('model.isCompleted') && !controller.get('autosaved')) {
          controller.set('workoutMetric.sensorSteps', controller.get('health.steps'));
          controller.set('workoutMetric.computedMoves', controller.get('model.video.moves'));
          controller.set('workoutMetric.completedPercentage', controller.get('model.video.completedPercentage'));
          controller.set('workoutMetric.secondsOnLive', Math.floor(controller.get('model.secondsOnLive')));
          controller.set('workoutMetric.secondsOnVideo', Math.floor(controller.get('videoPlayer.currentTimeValue')));
          controller.get('workoutMetric').save().then(function (workoutMetric) {
            _this2.get('emag').log('[Hoolio] Autosaved metrics.');
          }, function () {
            _this2.get('emag').error('[Hoolio] Could not save workout-metrics.');
          });
        }

        controller.set('stopwatchStarted', false);
        controller.set('autosaved', false);

        /* Track intercom event */
        var metadata = {
          title: controller.get('model.video.name'),
          level: controller.get('model.video.level'),
          duration: controller.get('model.video.videoDuration'),
          video_complete_percentage: controller.get('model.video.completedPercentage')
        };

        if (controller.get('model.status') === 'incomplete') {
          this.get('analytics.intercom').trackEvent('workout-uncompleted', metadata);
          this.get('analytics.google').trackEvent('workout', 'incomplete_workout', 'engage');
        } else if (controller.get('model.status') === 'complete') {
          this.get('analytics.intercom').trackEvent('workout-completed', metadata);
          this.get('analytics.google').trackEvent('workout', 'complete_workout', 'engage');
        }

        if (this.get('opentok.isSessionConnected') && this.get('opentok').isSupported()) {
          this.get('opentok').disconnectSession();
        }

        this.get('pubnub').clearInstance('workoutSyncPb');
        controller.set('workoutSyncPb', null);

        // Clear video chapters
        if (controller.get('model.video.chapters.length')) {
          this.get('videoChapters').clear();
        }

        // Reload currentUser
        if (transition && (transition.targetName === 'dashboard.index' || transition.targetName === 'library.index')) {
          this.get('session.currentUser').reload();
        }
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        var _this3 = this;

        this.send('nestedRouteTransitionOut', transition);

        var model = this.get("controller.model");
        if (model.get('isNew')) {
          //model.deleteRecord();
        }

        if (this.get('kaltura.sessions.video')) {
          transition.abort();
          this.set('kaltura.sessions.video', '');
          this.get('kaltura').getListKS(function (credentials) {
            transition.retry();
          }, function (error) {
            _this3.get('emag').log(error);
          });
        }
      }
    }
  });
});