define('hoolio-www/adapters/rest-adapter', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  /* globals window*/

  exports['default'] = _emberData['default'].RESTAdapter.extend({
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service(),
    emagNet: _ember['default'].inject.service(),

    host: _ember['default'].computed.alias('config.emaginal.apiServer'),
    headers: _ember['default'].computed('session.data.authenticated.session_key', function () {
      var current_time_utc = new Date().getTime(),
          utm_source = this.get('emagNet.utmParams.utm_source'),
          utm_medium = this.get('emagNet.utmParams.utm_medium'),
          utm_campaign = this.get('emagNet.utmParams.utm_campaign'),
          utm_term = this.get('emagNet.utmParams.utm_term'),
          utm_content = this.get('emagNet.utmParams.utm_content');

      return {
        'X-Auth-Token': this.get('session.data.authenticated.session_key'),
        'Content-Type': 'application/json',
        'X-User-Id': this.get('session.data.authenticated.user_id'),
        'X-Current-Time-Utc': current_time_utc,
        'X-Utm-Source': utm_source,
        'X-Utm-Medium': utm_medium,
        'X-Utm-Campaign': utm_campaign,
        'X-Utm-Term': utm_term,
        'X-Utm-Content': utm_content
      };
    }),

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 401) {
        console.error('Authentication required. Error 401 (Unauthorized)');
        window.location.replace(this.get('config.baseURL'));
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }

    /*isSuccess: function(status, headers, payload) {
      if(payload.success === false) {
        return false;
      } else {
        return status >= 200 && status < 300 || status === 304 ;
      }
    },*/
  });
});