define('hoolio-www/activate/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    afterModel: function afterModel(model, transition) {
      // Bubble up to application route, in order to update intercom data
      transition.send('nestedRouteTransitionIn', transition);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('isLoading', true);
      controller.set('isSuccessful', false);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set('isLoading', true);
        controller.set('isSuccessful', false);
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.send('nestedRouteTransitionOut', transition);
      }
    }
  });
});