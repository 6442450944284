define('hoolio-www/image/adapter', ['exports', 'hoolio-www/application/adapter', 'ember'], function (exports, _hoolioWwwApplicationAdapter, _ember) {
  exports['default'] = _hoolioWwwApplicationAdapter['default'].extend({
    findRecord: function findRecord(store, type, id, snapshot) {
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        resolve({
          id: id,
          thumbnailUrl: 'https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/' + id
        });
      });
    }
  });
});