define('hoolio-www/fitnessplan/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    /*
    *  Basic properties
    * 
    */
    name: _emberData['default'].attr('string'),
    heroImage: _emberData['default'].attr('string'),
    images: _emberData['default'].attr('array'),
    aims: _emberData['default'].attr('array'),
    durationInWeeks: _emberData['default'].attr('number'),
    description: _emberData['default'].attr('string'),

    /*
    *  Relationships
    * 
    */
    weeks: _emberData['default'].hasMany('week', { inverse: null }),

    /*
    *  Computed attribures
    * 
    */
    totalWeeksVideos: _ember['default'].computed('weeks.[]', function () {
      var results = [];
      if (this.get('weeks.length')) {
        this.get('weeks').forEach(function (week) {
          results.push(week.get('videos.content'));
        });
      }

      return results;
    }),

    totalVideos: _ember['default'].computed('weeks.[]', function () {
      var array = [];
      if (this.get('weeks.length')) {
        this.get('weeks').forEach(function (week) {
          array.push(week.get('videos.content').toArray());
        });
      }
      return array;
    }),

    moves: _ember['default'].computed('weeks.[]', function () {
      var sum = 0;
      if (this.get('weeks.length')) {
        this.get('weeks').forEach(function (week) {
          week.get('videos').forEach(function (video) {
            sum = sum + video.get('moves');
          });
        });
      }
      return sum;
    }),

    totalWorkouts: _ember['default'].computed('weeks.[]', function () {
      var sum = 0;
      if (this.get('weeks.length')) {
        this.get('weeks').forEach(function (week) {
          sum = sum + week.get('videos.length');
        });
      }
      return sum;
    })

  });
});