define('hoolio-www/services/tv', ['exports', 'ember'], function (exports, _ember) {
  /* globals moment */

  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
    emag: _ember['default'].inject.service('emag'),

    channel: null,
    guideChannel: null,
    url: 'https://media-hoolio.s3.amazonaws.com/channel.json',

    activeVideoIndex: -1,
    lastActiveVideoIndex: -2,
    activeVideo: null,
    activeAd: null,
    changeVideoTimer: null,
    utcMode: false,

    coreInfo: null,

    weeklySchedule: _ember['default'].A([]),
    todayIndex: _ember['default'].computed(function () {
      return moment().weekday() - 1;
    }),

    currentTitle: _ember['default'].computed.or('activeVideo.name', 'activeAd.name'),

    onInit: _ember['default'].on('init', function () {}),

    fetchingChannel: false,
    fetchChannel: function fetchChannel(url) {
      var _this2 = this;

      if (url || !this.get('fetchingChannel')) {
        url = url || this.get('url');
        this.set('fetchingChannel', true);
        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].$.getJSON(url).then(function (data) {
            _this2.get('emag').log('[TV service] Fetched new channel: ' + data.name);
            _this2.set('fetchingChannel', false);
            _this2.set('channel', data);
            _this2.set('guideChannel', data);
            resolve(data);
          }, function (err) {
            _this2.get('emag').log(err);
            _this2.set('fetchingChannel', false);
            reject();
          });
        });
      }
    },
    setChannel: function setChannel(channel_data) {
      if (channel_data) {
        this.set('channel', channel_data);
      }
    },
    setGuideChannel: function setGuideChannel(channel_data) {
      if (channel_data) {
        this.set('guideChannel', channel_data);
      }
    },
    resetGuideChannelToToday: function resetGuideChannelToToday() {
      this.set('guideChannel', this.get('channel'));
    },

    onChannel: _ember['default'].on('init', _ember['default'].observer('channel', 'utcMode', function () {
      if (this.get('channel') && (this.get('activeVideo') === null || this.get('activeAd') === null)) {
        this.get('emag').log('[TV service] UTC mode: ' + this.get('utcMode'));
        _ember['default'].run.scheduleOnce('afterRender', this, this.findActiveVideo);
      } else {
        this.set('activeVideoIndex', -1);
        this.set('activeVideo', null);
        this.set('activeAd', null);
      }
    })),

    videos: _ember['default'].computed('channel.zones.[]', function () {
      var videos = [];
      var _this = this;
      var playableVideoId = undefined;

      if (this.get('channel')) {
        this.get('channel').zones.forEach(function (zone, index1) {
          zone.subZones.forEach(function (subZone, index2) {
            var _prevDuration = 0;
            var _adsDuration = 0;
            playableVideoId = 0;
            subZone.videos.forEach(function (video, index3) {
              video.zoneId = index1;
              video.subZoneId = index1 + "-" + index2;
              video.indexId = index1 + "-" + index2 + "-" + playableVideoId;

              if (video.guide) {
                playableVideoId++;
              }

              if (!video.guide) {
                _adsDuration = _adsDuration + video.duration;
              } else {
                var videoStartTime = _prevDuration - _adsDuration;

                var _startTime = _this.get('utcMode') ? _this.resetDateToNow(subZone.startTime).add(Number(moment().utcOffset()), 'm') : _this.resetDateToNow(subZone.startTime);
                _ember['default'].set(video, 'startTime', _startTime.add(videoStartTime, 'seconds').format("HH:mm"));
                _ember['default'].set(video, 'minDuration', Math.round(video.duration / 60));
                _adsDuration = 0;
              }
              _prevDuration = _prevDuration + video.duration;

              videos.push(video);
            });
          });
        });
        return videos;
      } else {
        return [];
      }
    }),

    findActiveVideo: function findActiveVideo() {
      var _this3 = this;

      this.get('emag').log('[TV service] Searching current video...');
      this.set('activeVideoIndex', -1);
      this.set('activeVideo', null);
      this.set('activeAd', null);

      var now = moment();
      var zone = this.get('channel').zones.find(function (zone) {
        return now.isBetween(_this3.resetDateToNow(zone.startTime), _this3.resetDateToNow(zone.endTime));
      });

      if (zone) {
        (function () {
          var subZone = zone.subZones.find(function (subZone) {
            return now.isBetween(_this3.resetDateToNow(subZone.startTime), _this3.resetDateToNow(subZone.endTime));
          });

          if (subZone) {
            (function () {
              var duration = 0,
                  videoDuration = 0,
                  endTime = null;

              var currentVideo = subZone.videos.find(function (video) {
                videoDuration = video.duration;
                duration = duration + video.duration;
                endTime = _this3.resetDateToNow(subZone.startTime).add(duration, 's');
                //this.get('emag').log(now.format("D M YYYY, HH:mm:ss") +' / '+endTime.format("D M YYYY, HH:mm:ss"));
                if (now.isBetween(_this3.resetDateToNow(subZone.startTime), endTime)) {
                  var type = video.guide ? 'video' : 'Ad';
                  _this3.get('emag').log('[TV service] Now playing ' + type + ': ' + video.name);
                  _this3.set('activeVideoStartTime', 0);
                  return video;
                }
              });

              if (currentVideo) {
                _this3.set('activeVideoStartTime', parseInt(moment.duration(now.diff(endTime.subtract(videoDuration, 'seconds'))).asSeconds()));
                if (currentVideo.guide) {
                  _this3.set('activeVideo', currentVideo);
                  _this3.set('activeAd', null);
                } else {
                  _this3.set('activeVideo', null);
                  _this3.set('activeAd', currentVideo);
                }
              } else {
                _this3.get('emag').log('[TV service] No video feed.');
                _this3.set('activeVideoIndex', -1);
                _this3.set('activeVideo', null);
                _this3.set('activeAd', null);
              }
            })();
          } else {
            _this3.get('emag').log('[TV service] No video feed.');
            _this3.set('activeVideoIndex', -1);
            _this3.set('activeVideo', null);
            _this3.set('activeAd', null);
          }
        })();
      } else {
        throw '[TV service] Now is not between channel zones.';
      }
    },

    onCurrentVideo: _ember['default'].on('init', _ember['default'].observer('activeVideo', 'activeAd', function () {
      if (this.get('activeVideo')) {
        this.set('activeVideoIndex', this.get('videos').indexOf(this.get('activeVideo')));
        this.setVideoEndTimer(this.get('activeVideo.duration'));
      } else if (this.get('activeAd')) {
        this.setVideoEndTimer(this.get('activeAd.duration'));
      }
    })),

    nextWorkoutVideo: _ember['default'].computed('activeVideoIndex', function () {
      if (this.get('activeVideoIndex') && this.get('videos').length) {
        for (var i = this.get('activeVideoIndex') + 1; i < this.get('videos').length; i++) {
          if (this.get('videos')[i].type === 'workout') {
            return this.get('videos')[i];
          }
        }
        return null;
      }
    }),

    setVideoEndTimer: function setVideoEndTimer(activeVideoDuaration) {
      var adRemainDuration = (activeVideoDuaration - this.get('activeVideoStartTime')) * 1000;
      this.changeVideoTimer = _ember['default'].run.later(this, function () {
        this.get('emag').log('[TV service] Active video ended...');
        this.currentVideoEnded();
      }, adRemainDuration);
    },

    resetDateToNow: function resetDateToNow(date) {
      if (isNaN(new Date(date).getTime())) {
        date = moment().format('YYYY-MM-DD') + ' ' + date;
      }
      var utcMode = this.get('utcMode');
      var now = utcMode ? moment.utc() : moment();
      if (utcMode) {
        return moment.utc(date).set({ 'year': now.get('year'), 'month': now.get('month'), 'date': now.get('date') });
      } else {
        return moment(date).set({ 'year': now.get('year'), 'month': now.get('month'), 'date': now.get('date') });
      }
    },

    currentVideoEnded: function currentVideoEnded() {
      _ember['default'].run.later(this, this.findActiveVideo, 3000);
      this.trigger('videoEnded');
    },

    isActiveVideoFromYoutube: _ember['default'].computed('activeVideo', function () {
      if (this.get('activeVideo')) {
        return this.get('activeVideo.source').toLowerCase() === "youtube";
      }
    }),

    clearAll: function clearAll() {
      this.set('fetchingChannel', false);
      this.set('channel', null);
      this.set('guideChannel', null);
      //this.set('coreInfo', null);
    }
  });
});